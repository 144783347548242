import { gql } from '@apollo/client';

export const LOB_FRAGMENT = gql`
  fragment lobFragment on LobApplication {
    lobId
    lobName
    lobDescription
    lobAddedOn
    lobLastUpdated
  }
`;

export const LOB_APPLICATION_FRAGMENT = gql`
  fragment lobApplicationFragment on LobApplication {
    lobApplicationId
    applicationName
    description
    createdAt
    lastUpdated
    lobMembers {
      ...memberFragment
      emailAddress
      lastUpdated
      userType
      lastLogin
    }
  }
`;

export const FEATURE_FRAGMENT = gql`
  fragment featureFragment on Feature {
    featureId
    featureName
    featureStatus
  }
`;

export const FEATURE_ACTION_FRAGMENT = gql`
  fragment featureActionFragment on Action {
    actionId
    actionName
    actionType
    permission
  }
`;

export const ENVIRONMENT_FRAGMENT = gql`
  fragment environmentFragment on Environment {
    environmentId
    name
    status
    createdAt
    lastUpdated
  }
`;

export const ROLE_FRAGMENT = gql`
  fragment roleFragment on Role {
    roleId
    name
    description
    roleType
  }
`;

export const GROUP_FRAGMENT = gql`
  fragment groupFragment on Group {
    groupId
    groupName
    description
    createdAt
    lastUpdated
    groupType
    createdBy {
      userId
      firstName
      lastName
    }
  }
`;

export const GROUP_RULE_FRAGMENT = gql`
  fragment groupRuleFragment on GroupRule {
    ruleId
    groupId
    lobApplicationId
    partnerId
    accountId
    solutionId
    solutionEnvironmentId
    customersImpacted
    ticketSeverity
    ticketType
    isDefaultRule
    createdAt
    lastUpdated
    createdBy {
      userId
      firstName
      lastName
    }
  }
`;

export const MEMBER_FRAGMENT = gql`
  fragment memberFragment on User {
    userId
    firstName
    lastName
    roleName
    createdAt
  }
`;

export const PARTNER_FRAGMENT = gql`
  fragment partnerFragment on Partner {
    partnerId
    name
    shortName
    description
    createdAt
    lastUpdated
    status
  }
`;

export const ACCOUNT_FRAGMENT = gql`
  fragment accountFragment on Account {
    accountId
    partnerId
    lobApplicationId
    description
    name
    partnerName
    shortName
    status
    createdAt
    lastUpdated
  }
`;

export const SOLUTION_FRAGMENT = gql`
  fragment solutionFragment on Solution {
    solutionId
    accountId
    name
    shortName
    description
    status
    createdAt
    lastUpdated
    memberRole
  }
`;

export const SOLUTION_ENVIRONMENT_FRAGMENT = gql`
  fragment solutionEnvironmentFragment on SolutionEnvironment {
    solutionEnvironmentId
    environmentId
    name
    status
    createdAt
    lastUpdated
  }
`;

export const API_FRAGMENT = gql`
  fragment apiFragment on EnvironmentApi {
    apiId
    name
    status
    publishedAt
    version
  }
`;
