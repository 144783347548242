import { MdsIcon } from '@mds/react-es';
import './apix-success-banner-stylesheet.scss';
import { ReactNode } from 'react';

const ApixSuccessBanner = ({
  headerText,
  text,
  isOpen,
}: {
  isOpen: boolean;
  text: ReactNode | string;
  headerText: string;
}) => {
  /* this component is split into it's own component to be reused for following reasons
        - Mds does not have a success banner component
        - This component is likely to be used in multiple places in the application
   */

  return (
    <>
      {isOpen && (
        <div className="apix-success-banner">
          <MdsIcon
            type="ico_status_indicator_dot"
            color="success"
            size={24}
            removeHorizontalMargin
          />
          <div>
            <h2>{headerText}</h2>
            <p>{text}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ApixSuccessBanner;
