import { ArrayToTagGroupCellRenderer } from '../ManageLobMembersTableDesktop/ManageMembersTableDesktop';
import {
  parseDate,
  toLocaleTimeStringWithSeconds,
} from '../../../../../../../../../utils/time/dateConversion';
import DescriptionPair from '../../../../../../../../global-components/DescriptionPair';
import './manage-lob-members-mobile.scss';
import { searchArrayForText } from '../../../../../../../../../utils/searchArray';
import { useMemo } from 'react';
import { IMember } from '../../../../../../Solutions';

const ManageLobMembersMobile = ({
  members,
  searchText,
}: {
  members: IMember[];
  searchText: string;
}) => {
  const filteredMembers = useMemo(
    () => searchArrayForText(members, searchText),
    [members, searchText],
  );

  return (
    <div className="mobile-manage-lob-members">
      {filteredMembers.map((member) => (
        <ManageLobMembersCard key={member.userId} member={member} />
      ))}
    </div>
  );
};
export default ManageLobMembersMobile;

const ManageLobMembersCard = ({ member }: { member: any }) => {
  return (
    <div className="member-card">
      <div className="top span-columns">
        <strong>{member.userId}</strong>
        {/* <InteractionCellRenderer
          key={`mobile-${member.userId}`}
          value={member.userId}
        /> */}
      </div>
      <DescriptionPair
        term="Member name"
        value={`${member.firstName} ${member.lastName}`}
        boldedValue
      />
      <DescriptionPair
        term="Date added"
        value={parseDate(member.createdAt)}
        boldedValue
      />
      <DescriptionPair
        term="LOB"
        className="tag-list span-columns"
        value={<ArrayToTagGroupCellRenderer value={member.lobs} />}
      />
      <DescriptionPair
        term="SubLOB"
        className="tag-list span-columns"
        value={<ArrayToTagGroupCellRenderer value={member.lobApplications} />}
      />
      {/* <DescriptionPair
        term="Last logged in as"
        value={member.lastLogin || 'N/A'}
        boldedValue
      /> */}
      <DescriptionPair
        term="Roles"
        value={
          member.roles.length === 0 ? 'NA' : `${member.roles.length.toString()}`
        }
      />
      {/* <DescriptionPair
        term="Status"
        value={<EntityStatusCellRenderer value={member.status} />}
      /> */}
      <DescriptionPair
        term="Last login"
        value={
          member.lastLogin
            ? `${parseDate(member.lastLogin)} ${toLocaleTimeStringWithSeconds(member.lastLogin)}`
            : 'N/A'
        }
        boldedValue
      />
    </div>
  );
};
