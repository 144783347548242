import { MdsSelect, MdsSelectOption, MdsTabBar } from '@mds/react-es';
import {
  GlobalStoreContext,
  WelcomeMessage,
} from '../../Dashboard/scenes/PartnerAdminLandingScreen';
import { FullPageContainer } from '../AdminPage';
import ManageProfilesTab from './ViewAllProfilesPage/ManageProfilesTab/ManageProfilesTab';
import './iam-left-side-nav-page-stylesheet.scss';
import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

const IAMLeftSideNavPage = () => {
  // we need to give a defined height for the tab bar so that shadow children can be rendered at percents
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [computedHeight, setComputedHeight] = useState(500);
  const { isMobile } = useContext(GlobalStoreContext);
  const { width, height } = useWindowSize();
  const tabRef = useRef();

  useLayoutEffect(() => {
    if (tabRef.current) {
      const htmlRef = (tabRef?.current as any)._ref?.current;
      const { height } = htmlRef.getBoundingClientRect();
      setComputedHeight(height - 32);
    }
  }, [width, height]);

  return (
    <div id="iam-lsn-page">
      <WelcomeMessage />
      <FullPageContainer>
        {isMobile ? (
          <>
            <MdsSelect
              selectedIndex={selectedTabIndex}
              onValidate={(e) => setSelectedTabIndex(e.target.selectedIndex)}
              variant="box"
            >
              <MdsSelectOption
                label="Manage profiles"
                value="manage_profiles"
              />
            </MdsSelect>

            <ManageProfilesTab />
          </>
        ) : (
          <MdsTabBar
            id="manage-profiles-and-roles-tab-bar"
            tabs={[{ text: 'Manage profiles' }]}
            selectedTab={selectedTabIndex} //auto selects "Manage profiles tab for now"
            onClickTab={(e) => setSelectedTabIndex(e.target.selectedIndex)}
            ref={tabRef}
          >
            <div
              style={{ height: computedHeight }}
              slot="panel-0"
              className="panel"
            >
              <ManageProfilesTab />
            </div>
          </MdsTabBar>
        )}
      </FullPageContainer>
    </div>
  );
};

export default IAMLeftSideNavPage;
