import { MdsButton } from '@mds/react-es';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className="breakout-back-button">
      <MdsButton
        variant="tertiary"
        small
        text="Back"
        iconType="ico_chevron_left"
        iconPosition="leading"
        id="back-button"
        onClick={() => navigate(-1)}
      />
    </div>
  );
};

export default BackButton;
