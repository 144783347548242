import { convertToTicketActivityDateFormat } from '../../../../../../global-components/Tickets/TicketActivity';
import './profile-audit-history-tab-stylesheet.scss';
const allHistory = [
  // temporary data. Can be deleted once API is ready
  {
    id: '1',
    date: '2024-01-22T18:32:19Z',
    action: 'ACTIVATED',
    actionItem: 'create ticket',
    user: { firstName: 'Bill', lastName: 'Summers', userId: '1' },
  },
  {
    id: '2',
    date: '2024-01-23T18:32:19Z',
    action: 'DEACTIVATED',
    actionItem: 'View ticket',
    user: { firstName: 'Bill', lastName: 'Summers', userId: '1' },
  },
  {
    id: '3',
    date: '2024-01-23T12:32:19Z',
    action: 'EDITED',
    actionItem: 'title',
    user: { firstName: 'Andrew', lastName: 'Kihs', userId: '2' },
  },
  {
    id: '4',
    date: '2024-01-23T12:32:19Z',
    action: 'EDITED',
    actionItem: 'description',
    user: { firstName: 'Andrew', lastName: 'Kihs', userId: '2' },
  },
];

const getTextBlurb = (
  action: 'EDITED' | 'ACTIVATED' | 'DEACTIVATED',
  actionItem: string,
) => {
  switch (action) {
    case 'EDITED':
      return <>edited the {actionItem.toLowerCase()}</>;
    case 'ACTIVATED':
      return <>activated the {actionItem.toLowerCase()}</>;
    default:
      return <>edited the profile</>;
  }
};

const ProfileAuditHistoryTab = () => {
  return (
    <ul id="audit-history-ul">
      {allHistory.map(({ id, date, action, actionItem, user }) => (
        <li key={id}>
          <span>{convertToTicketActivityDateFormat(date)}</span>
          <span> | </span>
          <span className="name">{`${user.firstName} ${user.lastName} `}</span>
          <span>{getTextBlurb(action as any, actionItem)}</span>
        </li>
      ))}
    </ul>
  );
};

export default ProfileAuditHistoryTab;
