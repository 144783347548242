import {
  MdsAccordionItem,
  MdsButton,
  MdsDialog,
  MdsMultiselect,
  MdsMultiselectOption,
  MdsSelect,
  MdsSelectOption,
  MdsTabBar,
} from '@mds/react-es';
import { convertToTicketActivityDateFormat } from '../../../../../../../../../../../utils/time/dateConversion';
import { GlobalStoreContext } from '../../../../../../../../../Tickets/ViewAllTicketsPage';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useGetLobApplicationById } from '../../../../../../../../../../../services/graphql-utils/useGraphQLMaps';
import { BackButton } from '../../../../../../../../../ApiDocumentation';
import { useContext, useMemo, useState } from 'react';
import EmptyTableMessage from '../../../../../../../../../../global-components/EmptyTableMessage/EmptyTableMessage';
import SuccessDialog from '../../../../../../../../../../global-components/SuccessDialog';
import './sub-lob-item-view.scss';
import DescriptionPair from '../../../../../../../../../../global-components/DescriptionPair';
import LinkedPartnersDesktop from './LinkedPartnersDesktop/LinkedPartnersDesktop';
import EditTextItem from './EditTextItem';
import LinkedPartnersMobile from './LinkedPartnersMobile';
import { useForm, useWatch } from 'react-hook-form';
import { getPartnersAndAccounts } from '../../../../../../../../../../../services/webClientAxiosCalls';
import { PartnerAccountType } from '../../../../../../../../../../../utils/types/dashboardTypes';

const SubLobItemView = () => {
  /**
   * Currently commented out items for MVP
   *  - Deactivate sub-LOB switch
   *  - Admin dashboard button
   *  - Edit sub-LOB name + edit description buttons
   *  - Settings cog button
   *  - Audit history tab
   *  - Relink switch
   *  - Chase members count
   *  - Partner members count
   */
  const [isLinkPartnersDialogOpen, setIsLinkPartnersDialogOpen] =
    useState(false);
  const [isEditingLinkPartners, setIsEditingLinkPartners] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const { isMobile } = useContext(GlobalStoreContext);
  const { lobApplicationId } = useParams();
  const lobApplication = useGetLobApplicationById(lobApplicationId);
  const {
    data: allPartners,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['allPartners'],
    queryFn: () => getPartnersAndAccounts(),
  });

  const {
    formState: { isValid },
    control,
    register,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: { linkedPartners: [] as string[] },
  });
  const formData = useWatch({ control });

  const handleIsSuccessDialogOpen = (isOpen: boolean) => {
    setIsSuccessDialogOpen(isOpen);
  };

  const availablePartners = useMemo(() => {
    if (!allPartners?.data || !lobApplication?.partners) return [];
    return _.reject(allPartners?.data, (partner) =>
      lobApplication.partners.some(
        (addedPartner) => addedPartner.partnerId === partner.partnerId,
      ),
    ) as PartnerAccountType[];
  }, [allPartners, lobApplication?.partners]);

  const onSubmit = () => {
    // try update
    // if success
    // refetch()
    setIsLinkPartnersDialogOpen(false);
    setIsSuccessDialogOpen(true);
    reset();
    // end success block
  };
  if (!lobApplication || isLoading || isError) return null;
  const {
    partners: addedPartners,
    applicationName,
    description,
    lastUpdated,
    createdAt,
  } = lobApplication;
  return (
    <>
      <div id="sub-lob-view-page">
        <BackButton />
        <div className="top-section">
          <div id="title">
            <EditTextItem
              initTextValue={applicationName}
              textElement={<h1>{applicationName}</h1>}
              isEditable={false}
            />
          </div>
          <div className="button-group">
            {/* <MdsSwitch
              stateLabelOn="Deactivate sub-LOB"
              stateLabelOff="Activate sub-LOB"
            />
            <MdsButton
              text="Admin dashboard"
              iconType="ico_new_window"
              iconPosition="leading"
              widthType={isMobile ? 'layout' : 'content'}
              small={isMobile ? false : true}
            /> */}
          </div>
        </div>
        <div className="accordion-container">
          <MdsAccordionItem
            id="overview-accordion"
            label="Overview"
            variant="borderless"
            size="18px"
            expanded
          >
            <div id="overview-section" className="overviews">
              <DescriptionPair
                term="Description"
                value={
                  <EditTextItem
                    initTextValue={description}
                    textElement={<p>{description}</p>}
                    small
                    isEditable={false}
                  />
                }
                className="overviews-description"
                itemLayout="horizontal"
                boldedTerm
              />

              <div id="overviews-grid">
                {/*
                <DescriptionPair
                  term="Added by"
                  value="R711133 - Andrew Kihs"
                  itemLayout="horizontal"
                  boldedTerm
                />
                */}
                <DescriptionPair
                  term="Added on"
                  value={convertToTicketActivityDateFormat(createdAt)}
                  itemLayout="horizontal"
                  boldedTerm
                />
                <DescriptionPair
                  term="Updated on"
                  value={convertToTicketActivityDateFormat(lastUpdated)}
                  itemLayout="horizontal"
                  boldedTerm
                />
              </div>
            </div>
          </MdsAccordionItem>
        </div>
        <div className="divider"></div>
        <div className="accordion-container bottom-container">
          <h2>Details</h2>
          {isMobile ? (
            <>
              <MdsSelect variant="box" selectedIndex={0}>
                <MdsSelectOption
                  label={`Partners (${addedPartners.length})`}
                  value="linked_partners"
                />
                {/* <MdsSelectOption label="Audit history" value="audit_history" /> */}
              </MdsSelect>
              <>
                <div id="link-partners-tab-mobile">
                  <div className="top-button-group">
                    {/* <MdsButton
                      variant="tertiary"
                      iconType="ico_link"
                      text="Link Partners"
                      onClick={() => setIsLinkPartnersDialogOpen(true)}
                      small
                    /> */}
                    {/* <MdsButton
                        variant="tertiary"
                        iconPosition="icon_only"
                        iconType="ico_settings"
                        small
                      /> */}
                  </div>
                  <LinkedPartnersMobile partners={addedPartners} />
                </div>
              </>
            </>
          ) : (
            <MdsTabBar
              tabs={[
                { text: `Partners (${addedPartners.length})` },
                // { text: 'Audit history' },
              ]}
            >
              <div slot="panel-0" id="partners-panel" className="tab-panel">
                <div className="top-button-group">
                  {/* <MdsButton
                    variant="tertiary"
                    iconType="ico_link"
                    text="Link Partners"
                    onClick={() => setIsLinkPartnersDialogOpen(true)}
                    small
                  /> */}
                  {/* <MdsButton
                    variant="tertiary"
                    iconPosition="icon_only"
                    iconType="ico_settings"
                    small
                  /> */}
                </div>
                {addedPartners.length === 0 && (
                  <EmptyTableMessage
                    message="You haven't linked any Partners yet."
                    buttonText="Link Partners to sub-LOB"
                    onClickButton={() => {
                      setIsLinkPartnersDialogOpen(true);
                    }}
                    hideButton
                  />
                )}
                {addedPartners.length !== 0 && (
                  <LinkedPartnersDesktop partners={addedPartners} />
                )}
              </div>
              {/* <div slot="panel-1">
                <ProfileAuditHistoryTab />
              </div> */}
            </MdsTabBar>
          )}
        </div>
      </div>

      {/* Link partners dialog */}
      <MdsDialog
        open={isLinkPartnersDialogOpen}
        dialogWidth={550}
        dialogHeight={!isMobile && isEditingLinkPartners ? 600 : undefined} // variable height
        headerText="Link Partners"
        onClose={() => {
          setIsLinkPartnersDialogOpen(false);
          reset();
        }}
      >
        <form
          id="link-partners-dialog-content"
          slot="dialogContent"
          onSubmit={handleSubmit(onSubmit)}
        >
          <MdsMultiselect
            variant="box"
            label="Partner name"
            placeholder="Search and link partners"
            {...register('linkedPartners', {
              required: true,
            })}
            onFocus={() => setIsEditingLinkPartners(true)}
            onBlur={() => setIsEditingLinkPartners(false)}
          >
            {availablePartners.map((partner) => (
              <MdsMultiselectOption
                key={partner.partner_id}
                value={partner.partner_id}
                selected={formData.linkedPartners?.includes(partner.partner_id)}
                label={partner.name}
              />
            ))}
          </MdsMultiselect>
          <div className="bottom-button-group">
            <MdsButton
              variant="secondary"
              text="Cancel"
              widthType={isMobile ? 'layout' : 'content'}
              onClick={() => {
                setIsLinkPartnersDialogOpen(false);
                reset();
              }}
            />
            <MdsButton
              text="Link Partner(s)"
              inactive={!isValid}
              onClick={onSubmit}
              widthType={isMobile ? 'layout' : 'content'}
            />
          </div>
        </form>
      </MdsDialog>

      {/* Success dialog */}
      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        onDialogOpen={handleIsSuccessDialogOpen}
        successMessage={
          <>
            {`You've succesfully linked `}
            <strong>Partners.</strong>
          </>
        }
      />
    </>
  );
};

export default SubLobItemView;
