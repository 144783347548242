import DescriptionPair from '../../../../../../../../../../../global-components/DescriptionPair';
import { convertToTicketActivityDateFormat } from '../../../../../../../../../../../global-components/Tickets/TicketActivity';
import { IPartner } from '../../../../../../../../../../BreakoutPages/components/SinglePartnerBreakoutPage';
import { LinkToPartner } from '../LinkedPartnersDesktop/LinkedPartnersDesktop';
import './linked-partners-mobile-stylesheet.scss';

const LinkedPartnersMobile = ({ partners }: { partners: IPartner[] }) => {
  /**
   * TODO values commented out in this file and marked with TODO
   */
  return (
    <>
      {partners.map((partner) => (
        <div className="partner-card" key={partner.partnerId}>
          <div className="link-to">
            {/* <img src="TODO" />  */}
            <LinkToPartner data={partner} />
          </div>
          <DescriptionPair
            term="Accounts"
            value={partner.accounts.length}
            boldedValue
          />
          <DescriptionPair
            term="Solution"
            value={partner.accounts
              .map((account) => account.solutions.length)
              .reduce((a, b) => a + b, 0)}
            boldedValue
          />
          {/* <DescriptionPair
            term="Chase member"
            value={'INT -- TODO'}
            boldedValue
          />
          <DescriptionPair
            term="Partner member"
            value={'INT -- TODO'}
            boldedValue
          /> */}
          <DescriptionPair
            term="Date Added"
            value={convertToTicketActivityDateFormat(partner.lastUpdated)}
            boldedValue
          />
          {/* <MdsSwitch stateLabelOn="Unlink" stateLabelOff="Relink" /> */}
        </div>
      ))}
    </>
  );
};
export default LinkedPartnersMobile;
