import { formatDistanceToNow } from 'date-fns';
import { format, formatInTimeZone } from 'date-fns-tz';

const toLocaleDateString = (stringDate: string) => {
  const date = new Date(stringDate);
  return date.toLocaleDateString();
};

const toLocaleTimeString = (stringDate: string) => {
  const addUTCId = stringDate?.includes('Z');
  const date = new Date(`${stringDate}${addUTCId ? '' : 'Z'}`);
  return date.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

// Note: currently not using this function
// const formatAMPM = (dateString: string) => {
//   const date = new Date(`${dateString}Z`);
//   let hours = date.getHours();
//   let minutes: number | string = date.getMinutes();
//   const ampm = hours >= 12 ? 'PM' : 'AM';
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'
//   minutes = minutes < 10 ? '0' + minutes : minutes;
//   const strTime = hours + ':' + minutes + ampm;
//   return strTime;
// };

const convertDateToTimeAgo = (stringDate: string) => {
  const timeAgo = formatDistanceToNow(stringDate, { addSuffix: true });
  return timeAgo + ' ' + toLocaleTimeString(stringDate);
};

const convertDateToDashboardFormat = () => {
  return format(new Date(), 'MMMM dd, yyyy pa z');
};

const convertDateTimeToUTC = (date: string, time: string, timeZone: string) => {
  const [month, day, year] = date.split('/');

  const dateTimeString = `${year}-${month}-${day} ${time}`;
  // convert to UTC by adjusting for the UTC offset
  const formattedUTCString = formatInTimeZone(
    new Date(dateTimeString),
    timeZone,
    "yyyy-MM-dd'T'HH:mm:ssXXX",
  );

  return formattedUTCString;
};

const toLocaleTimeStringWithSeconds = (stringDate: string) => {
  const addUTCId = stringDate?.includes('Z');
  const date = new Date(`${stringDate}${addUTCId ? '' : 'Z'}`);
  return date.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  });
};

const parseDate = (dateString: string | null) => {
  if (!dateString) return '-';
  const monthNames = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JULY',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  const addUTCId = dateString?.includes('Z');
  const date = new Date(`${dateString}${addUTCId ? '' : 'Z'}`);
  return `${date.getDate()}-${
    monthNames[date.getMonth()]
  }-${date.getFullYear()}`;
};

const handleGetTableDateValues = (date: string | undefined) => {
  return date
    ? `${parseDate(date)} ${toLocaleTimeStringWithSeconds(date)}`
    : 'N/A';
};

const convertToGroupDateFormat = (date: string | undefined) => {
  return date ? `${parseDate(date)} at ${toLocaleTimeString(date)}` : 'N/A';
};

const convertToTicketActivityDateFormat = (date: string) => {
  return `${parseDate(date)} at ${toLocaleTimeString(date)}`;
};

const timeFormatOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true,
  timeZoneName: 'short',
};

export {
  toLocaleDateString,
  toLocaleTimeString,
  convertDateToTimeAgo,
  convertDateToDashboardFormat,
  convertDateTimeToUTC,
  toLocaleTimeStringWithSeconds,
  parseDate,
  handleGetTableDateValues,
  convertToGroupDateFormat,
  convertToTicketActivityDateFormat,
  timeFormatOptions,
};
