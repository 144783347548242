const linkToDevPortal = (route: string) => {
  return `${
    window.location.hostname === 'localhost'
      ? 'https://portal.eks.apix.dev.aws.jpmchase.net'
      : ''
  }${route}`;
};

const linkToSignIn = `/login`;

const linkToSignOut = `/api/adfs/logout`;

const getLinktoRegistration = () => {
  switch (window.location.hostname) {
    case 'localhost':
    case 'portal.eks.apix.dev.aws.jpmchase.net':
      return 'https://nwas-sit.jpmchase.net/apix_registration/';
    case 'portal.eks.apix.uat.aws.jpmchase.net':
    case 'developer-uat.chase.com':
      return 'https://nwas-ent-uat.jpmorgan.com/apix_registration/';
    case 'portal.eks.apix.prod.aws.jpmchase.net':
    case 'developer.chase.com':
    default:
      return 'https://nwas-ent.jpmorgan.com/apix_registration/';
  }
};

// TODO: Uncomment and remove other text block when ready to use internal registration
// const getLinktoRegistration = () => {
//   switch (window.location.hostname) {
//     case 'localhost':
//     case 'portal.eks.apix.dev.aws.jpmchase.net':
//       return `${window.location.origin}/registration/`;
//     case 'portal.eks.apix.uat.aws.jpmchase.net':
//     case 'developer-uat.chase.com':
//       return `${window.location.origin}/registration/`;
//     case 'portal.eks.apix.prod.aws.jpmchase.net':
//     case 'developer.chase.com':
//     default:
//       return `${window.location.origin}/registration/`;
//   }
// };

const getShowcaseEndpoint = () => {
  let endpoint;
  switch (window.location.origin) {
    case 'http://localhost:8080':
    case 'https://portal.eks.apix.dev.aws.jpmchase.net':
      endpoint = 'https://showcase.eks.apix.dev.aws.jpmchase.net/';
      break;
    case 'https://portal.eks.apix.uat.aws.jpmchase.net':
      endpoint = 'https://showcase.eks.apix.uat.aws.jpmchase.net/';
      break;
    case 'https://developer-uat.chase.com':
      endpoint = 'https://apidemo-uat.chase.com/';
      break;
    case 'https://portal.eks.apix.prod.aws.jpmchase.net':
      endpoint = 'https://showcase.eks.apix.prod.aws.jpmchase.net/';
      break;
    case 'https://developer.chase.com':
    default:
      endpoint = 'https://apidemo.chase.com/';
      break;
  }
  return endpoint;
};

const linkToRegistration = getLinktoRegistration();
const linkToApiDemo = getShowcaseEndpoint();
export {
  linkToDevPortal,
  linkToSignOut,
  linkToRegistration,
  linkToSignIn,
  linkToApiDemo,
};
