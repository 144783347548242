import { ReactNode, useState } from 'react';
import './edit-text-item.scss';
import { MdsTextInput } from '@mds/react-es';
import InlineEditButtons from '../../../../../../../../../../../global-components/InlineEditButtons';

const EditTextItem = ({
  initTextValue,
  textElement,
  small,
  className,
  isEditable = true,
}: {
  initTextValue: string;
  textElement: ReactNode;
  small?: boolean;
  className?: string;
  isEditable?: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentTextValue, setCurrentTextValue] = useState(initTextValue);

  return (
    <div
      className={`edit-text-item ${small ? 'small' : 'large'} ${isEditing && 'editing'} ${className && className}`}
    >
      {isEditing ? (
        <MdsTextInput
          value={currentTextValue}
          multiline={small}
          variant="box"
        />
      ) : (
        <div className="text">{textElement}</div>
      )}
      <div>
        {isEditable && (
          <InlineEditButtons
            isEditing={isEditing}
            onCancel={() => {
              setIsEditing(false);
              setCurrentTextValue(initTextValue); // reset
            }}
            onClickEditButton={() => {
              setIsEditing(true);
            }}
            onConfirm={() => {
              // update function should go here
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EditTextItem;
