import { MdsDialog } from '@mds/react-es';
import { alertSpot } from './';

const GenericTicketErrorDialog = (props: {
  isDialogOpen: boolean;
  handleClose: () => void;
  headerText: string;
  dialogHeaderIconName: string;
  message: string;
  description: string;
}) => {
  const {
    isDialogOpen,
    handleClose,
    headerText,
    dialogHeaderIconName,
    message,
    description,
  } = props;

  return (
    <MdsDialog
      id="ticket-dialog"
      open={isDialogOpen}
      onClose={handleClose}
      dialogWidth={620}
      headerText={headerText}
      dialogHeaderIconName={dialogHeaderIconName}
      primaryButtonText="Ok"
      onClickButton1={handleClose}
    >
      <div slot="dialogContent">
        <div className="tickets-dialog-content">
          <img src={alertSpot} alt="Alert" height={130} width={130} />
          <div id="tickets-dialog-description">
            <span id="dialog-message">{message}</span>
            <span>{description}</span>
          </div>
        </div>
      </div>
    </MdsDialog>
  );
};

export default GenericTicketErrorDialog;
