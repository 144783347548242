import { useEffect, useState } from 'react';
import '@fontsource/open-sans/latin.css';
import './styles/global-styles.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import '@mds/resources-web/dist/tokens/components/MdsComponents-cpo.css';
import { Theme } from '@mds/web-ui-theme';
import '@mds/web-ui-theme/cpo';
import {
  getUserProfile,
  AccessDeniedErrorPage,
  updatePartnerAdminLastLogin,
  GlobalStoreContext,
  ScrollToTop,
  useWindowSize,
  UserProfileInterface,
  SessionAndErrorPagesLayoutComponent,
  LoggedOutPage,
  DashboardTwoPointZero,
  deriveUserPersona,
  Homepage,
  ProductsRouter,
  OurProcess,
  Solution,
  solutions,
  FAQ,
  ContactUs,
  slugify,
  Terms,
  personaMapsInterface,
  SinglePartnerBreakoutPage,
  SingleAccountBreakoutPage,
  SingleSolutionBreakoutPage,
  UserPersonaEnum,
  isUserPartnerCentral,
  Glossary,
  SingleEnvironmentBreakoutPage,
  ApiDocumentation,
  LSNSolutions,
  LSNAccounts,
  GeneralLayout,
  MemberLSNPage,
  ViewAllTicketsPage,
  ticketMapsInterface,
  SingleTicketPage,
  AdminPage,
  ViewSingleAssigneeGroupPage,
  UserAccessInterface,
  setLoginTime,
  featureMapsInterface,
  profileMapInterface,
} from './';
import _ from 'lodash';
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import IAMLeftSideNavPage from './components/pages/LeftSideNav/IAM/IAMLeftSideNavPage';
import SingleProfilePage from './components/pages/LeftSideNav/IAM/SingleProfilePage/SingleProfilePage';
import SinglePartnerPage from './components/pages/LeftSideNav/IAM/SinglePartnerPage';
import ManageLobPage from './components/pages/LeftSideNav/IAM/ManageLobPage';
import SubLobItemView from './components/pages/LeftSideNav/IAM/ManageLobPage/components/ManageLobTab/components/LobAccordion/components/SubLobItemView';
import RegistrationPage from './components/pages/Registration';
import EmailVerification from './components/pages/Registration/components/EmailVerification';
import RegistrationErrorPage from './components/pages/Registration/components/RegistrationErrorPage';
import PartnerEmailSent from './components/pages/Registration/components/PartnerEmailSent';

const queryClient = new QueryClient();
const defaultLink = new HttpLink({ uri: '/partners/api/v1/dashboard' });
const additionalLink = new HttpLink({ uri: '/partners/api/v1/api-details' });
const linkSelector = (operation: any) => {
  const operationContext = operation.getContext();
  return operationContext.endpoint === 'api-inventory-service';
};
const link = split(linkSelector, additionalLink, defaultLink);

const graphQLClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

function App(): JSX.Element {
  const windowWidth = useWindowSize().width;
  const isMobile = windowWidth < 769;
  const isMobileWithLSN = windowWidth < 769 + 278;
  const [userProfile, setUserProfile] = useState({} as UserProfileInterface);
  const [userAccess, setUserAccess] = useState({} as UserAccessInterface);
  const [isLeftSideNavOpen, setIsLeftSideNavOpen] = useState(true);
  const [personaMaps, setPersonaMaps] = useState({} as personaMapsInterface);
  const [featureMap, setFeatureMap] = useState({} as featureMapsInterface);
  const [ticketMaps, setTicketMaps] = useState({} as ticketMapsInterface);
  const [profileMap, setProfileMap] = useState({} as profileMapInterface);

  const { isPartnerCentralUser } = userProfile;

  const getProfile = async () => {
    try {
      const userProfile = await getUserProfile();
      const userPersona = deriveUserPersona(userProfile);
      const isChaseUser =
        userPersona === UserPersonaEnum.ChaseSuperAdmin ||
        userPersona === UserPersonaEnum.RelationshipManager ||
        userPersona === UserPersonaEnum.ChaseAdmin ||
        userPersona === UserPersonaEnum.ChaseSupportTeam;
      const isExternalUser = !isChaseUser;
      const isPartnerCentralUser = isUserPartnerCentral(userPersona);

      Object.assign(
        userProfile,
        { userPersona: userPersona },
        { isPartnerCentralUser: isPartnerCentralUser },
        { isChaseUser: isChaseUser },
        { isExternalUser: isExternalUser },
      );
      if (
        +userPersona === UserPersonaEnum.PartnerDeveloper ||
        +userPersona === UserPersonaEnum.PartnerAdmin ||
        +userPersona === UserPersonaEnum.PartnerRelationshipManager
      ) {
        await updatePartnerAdminLastLogin();
      }
      setUserProfile(userProfile);
      setLoginTime();
    } catch (e) {
      setUserProfile({
        isLoggedIn: false,
        isPartnerCentralUser: false,
        userId: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        userPersona: UserPersonaEnum.AnonymousUser,
        isChaseUser: false,
        isExternalUser: false,
      });
      console.error(e);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const renderSolutions = () => {
    return solutions.map(
      (solution: {
        solutionName: string;
        content: any;
        hasShowcaseDemo: boolean;
      }) => {
        return (
          <Route
            key={solution.solutionName}
            path={`/solutions/chase-${slugify(solution.solutionName)}`}
            element={
              <Solution
                solutionName={solution.solutionName}
                content={solution.content}
                hasShowcaseDemo={solution.hasShowcaseDemo}
              />
            }
          />
        );
      },
    );
  };

  if (_.isEmpty(userProfile)) return <></>;

  return (
    <HelmetProvider>
      <ApolloProvider client={graphQLClient}>
        <QueryClientProvider client={queryClient}>
          <GlobalStoreContext.Provider
            value={{
              userProfile,
              userAccess: userAccess,
              setUserAccess: setUserAccess,
              isMobile,
              isLeftSideNavOpen,
              setIsLeftSideNavOpen,
              personaMaps: personaMaps,
              featureMap: featureMap,
              setFeatureMaps: setFeatureMap,
              setPersonaMaps: setPersonaMaps,
              ticketMaps: ticketMaps,
              setTicketMaps: setTicketMaps,
              profileMap: profileMap,
              setProfileMap: setProfileMap,
              isMobileWithLSN,
            }}
          >
            <Theme brand={'cpo'}>
              {/* Theme needed for new MDS composable components to have styling: https://mds-web-ui.apps.test.na-9z.gap.jpmchase.net/?path=/docs/theme-web-ui-theme-theme-custom-theming--docs */}
              <BrowserRouter>
                <ScrollToTop>
                  <Routes>
                    <Route element={<GeneralLayout />}>
                      <Route
                        path="/"
                        element={
                          isPartnerCentralUser ? (
                            <DashboardTwoPointZero />
                          ) : (
                            <Homepage />
                          )
                        }
                      />

                      <Route path="/support/faqs/" element={<FAQ />} />
                      <Route path="/terms/" element={<Terms />} />
                      <Route path="/support/glossary/" element={<Glossary />} />
                      <Route path="/products/*" element={<ProductsRouter />} />
                      <Route
                        path="/solutions/chase-our-process/"
                        element={<OurProcess />}
                      />

                      {userProfile.isLoggedIn && (
                        <Route
                          path="/support/contact/"
                          element={<ContactUs />}
                        />
                      )}
                      {renderSolutions()}
                      {isPartnerCentralUser && (
                        <>
                          <Route
                            path={'/partners/solutions'}
                            element={<LSNSolutions />}
                          />
                          <Route
                            path="/partners/admin/"
                            element={<AdminPage />}
                          />
                          <Route
                            path="/partners/admin/:groupId"
                            element={<ViewSingleAssigneeGroupPage />}
                          />
                          <Route
                            path="/partners/accounts"
                            element={<LSNAccounts />}
                          />
                          <Route
                            path="/partners/:partnerId/"
                            element={<SinglePartnerBreakoutPage />}
                          />
                          <Route
                            path="/partners/account/:partnerId/:accountId/"
                            element={<SingleAccountBreakoutPage />}
                          />
                          <Route
                            path="/partners/:partnerId/:accountId/:solutionId/"
                            element={<SingleSolutionBreakoutPage />}
                          />
                          <Route
                            path="/partners/:partnerId/:accountId/:solutionId/:solutionEnvironmentId/"
                            element={<SingleEnvironmentBreakoutPage />}
                          />
                          <Route
                            path="/partners/apis/:apiId/"
                            element={<ApiDocumentation />}
                          />
                          <Route
                            path="/partners/members/:partnerId/:accountId/:solutionId/"
                            element={<MemberLSNPage renderBackButton />}
                          />
                          <Route
                            path="/partners/members/"
                            element={<MemberLSNPage />}
                          />
                          <Route
                            path="/partners/tickets/"
                            element={<ViewAllTicketsPage />}
                          />
                          <Route
                            path="/partners/tickets/:ticketId"
                            element={<SingleTicketPage />}
                          />
                          {userProfile.userPersona ===
                            UserPersonaEnum.ChaseSuperAdmin && (
                            <>
                              <Route
                                path="/partners/manage-lob/"
                                element={<ManageLobPage />}
                              />
                              <Route
                                path="/partners/sub-lobs/:lobApplicationId/"
                                element={<SubLobItemView />}
                              />
                              <Route
                                path="/partners/profiles/"
                                element={<IAMLeftSideNavPage />}
                              />
                              <Route
                                path="/partners/profiles/:profileId"
                                element={<SingleProfilePage />}
                              />
                              <Route
                                path="/partners/manage-lob/partner/:partnerId"
                                element={<SinglePartnerPage />}
                              />
                            </>
                          )}
                        </>
                      )}
                    </Route>
                    {/* Pages outside of Layout component */}

                    {/* Registration Pages */}
                    <Route
                      path="/registration"
                      element={<RegistrationPage />}
                    />
                    <Route
                      path="/registration/partner-email-sent"
                      element={<PartnerEmailSent />}
                    />
                    <Route
                      path="/registration/email-verification"
                      element={<EmailVerification />}
                    />
                    <Route
                      path="/registration/registration-error"
                      element={
                        <RegistrationErrorPage page="Create an account" />
                      }
                    />
                    <Route
                      path="/registration/login-error"
                      element={<RegistrationErrorPage page="Sign in" />}
                    />
                    <Route
                      path="/registration/oops-error"
                      element={<RegistrationErrorPage page="Generic" />}
                    />

                    <Route element={<SessionAndErrorPagesLayoutComponent />}>
                      <Route
                        path="/session/logged-out/"
                        element={<LoggedOutPage />}
                      />
                      <Route
                        path="/error/access-denied"
                        element={<AccessDeniedErrorPage />}
                      />
                    </Route>
                  </Routes>
                </ScrollToTop>
              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false} />
            </Theme>
          </GlobalStoreContext.Provider>
        </QueryClientProvider>
      </ApolloProvider>
    </HelmetProvider>
  );
}
export default App;
