import { gql } from '@apollo/client';
import {
  LOB_APPLICATION_FRAGMENT,
  LOB_FRAGMENT,
  FEATURE_FRAGMENT,
  ROLE_FRAGMENT,
  ENVIRONMENT_FRAGMENT,
  GROUP_FRAGMENT,
  GROUP_RULE_FRAGMENT,
  MEMBER_FRAGMENT,
  PARTNER_FRAGMENT,
  ACCOUNT_FRAGMENT,
  SOLUTION_FRAGMENT,
  SOLUTION_ENVIRONMENT_FRAGMENT,
  API_FRAGMENT,
} from '../graphql-fragments/fragments';
export const superAdminQuery = gql`
  query {
    dashboard {
      ...lobFragment
      ...lobApplicationFragment
      ticketTypes
      roles {
        ...roleFragment
      }
      environments {
        ...environmentFragment
      }
      features {
        ...featureFragment
      }
      groups {
        ...groupFragment
        groupRules {
          ...groupRuleFragment
        }
        members {
          ...memberFragment
          createdBy {
            userId
            firstName
            lastName
          }
        }
      }
      partners {
        ...partnerFragment
        accounts {
          ...accountFragment
          solutions {
            ...solutionFragment
            members {
              ...memberFragment
              emailAddress
              status
              userType
              lastLogin
              lastUpdated
              environments
              dateAdded
            }
            solutionEnvironments {
              ...solutionEnvironmentFragment
              apis {
                ...apiFragment
              }
            }
          }
        }
      }
    }
  }
  ${LOB_FRAGMENT}
  ${LOB_APPLICATION_FRAGMENT}
  ${ROLE_FRAGMENT}
  ${ENVIRONMENT_FRAGMENT}
  ${FEATURE_FRAGMENT}
  ${GROUP_FRAGMENT}
  ${GROUP_RULE_FRAGMENT}
  ${MEMBER_FRAGMENT}
  ${PARTNER_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
  ${SOLUTION_FRAGMENT}
  ${SOLUTION_ENVIRONMENT_FRAGMENT}
  ${API_FRAGMENT}
`;
