import { useContext } from 'react';
import { GlobalStoreContext } from './';

/*
  This series of hooks is meant to interact with our GlobalStoreContext.and
  should allow a user to easily invoke a single function to receive data
  within any component

  You may decide that in the future, you would like to add more
  functions, in which case you may add to this file

*/

const useGetLobs = () => {
  const { personaMaps } = useContext(GlobalStoreContext);
  return {
    lobMap: personaMaps.lobMap,
    lobArray: Array.from(personaMaps.lobMap.values()),
  };
};

const useGetAllLobApplications = () => {
  const { personaMaps } = useContext(GlobalStoreContext);

  return {
    lobApplicationsMap: personaMaps.lobApplicationMap,
    lobApplicationsArray: Array.from(personaMaps.lobApplicationMap.values()),
  };
};

const useGetLobMembers = () => {
  const { personaMaps } = useContext(GlobalStoreContext);
  return {
    lobMembersMap: personaMaps.memberMap,
    lobMembersArray: Array.from(personaMaps.memberMap.values()),
  };
};

const useGetAllPartners = () => {
  const { personaMaps } = useContext(GlobalStoreContext);
  return {
    partnersMap: personaMaps.partnerMap,
    partnersArray: Array.from(personaMaps.partnerMap.values()),
  };
};

const useGetAllAccounts = () => {
  const { personaMaps } = useContext(GlobalStoreContext);

  return {
    accountsMap: personaMaps.accountMap,
    accountsArray: Array.from(personaMaps.accountMap.values()),
  };
};

const useGetAllSolutions = () => {
  const { personaMaps } = useContext(GlobalStoreContext);

  return {
    solutionsMap: personaMaps.solutionMap,
    solutionsArray: Array.from(personaMaps.solutionMap.values()),
  };
};

const useGetAllEnvironments = () => {
  const { personaMaps } = useContext(GlobalStoreContext);

  return {
    environmentMap: personaMaps.environmentMap,
    environmentArray: Array.from(personaMaps.environmentMap.values()),
  };
};

const useGetAllSolutionEnvironments = () => {
  const { personaMaps } = useContext(GlobalStoreContext);

  return {
    solEnvMap: personaMaps.solEnvMap,
    solEnvArray: Array.from(personaMaps.solEnvMap.values()),
  };
};

const useGetAllMembers = () => {
  const { personaMaps } = useContext(GlobalStoreContext);

  return {
    memberMap: personaMaps.memberMap,
    memberArray: Array.from(personaMaps.memberMap.values()),
  };
};

const useGetAllTickets = () => {
  const { ticketMaps } = useContext(GlobalStoreContext);

  return {
    ticketMap: ticketMaps.ticketMap,
    ticketArray: Array.from(ticketMaps.ticketMap.values()),
  };
};

const useGetAllFeatures = () => {
  const { personaMaps } = useContext(GlobalStoreContext);

  return {
    featureMap: personaMaps.featureMap,
    featureArray: Array.from(personaMaps.featureMap.values()),
  };
};

const useGetAllIAMFeatures = () => {
  const { featureMap } = useContext(GlobalStoreContext);

  return {
    featureMap: featureMap.featureMap,
    featureArray: Array.from(featureMap.featureMap.values()),
  };
};

const useGetAllProfiles = () => {
  const { profileMap } = useContext(GlobalStoreContext);

  return {
    profileMap: profileMap.profileMap,
    profileArray: Array.from(profileMap.profileMap.values()),
  };
};

const useGetProfileById = (profileId: string | undefined) => {
  const allProfiles = useGetAllProfiles();
  return profileId ? allProfiles.profileMap?.get(profileId) : undefined;
};

const useGetAllGroups = () => {
  const { personaMaps } = useContext(GlobalStoreContext);
  return {
    groupMap: personaMaps.groupMap,
    groupArray: Array.from(personaMaps.groupMap.values()),
  };
};

const useGetAllRoles = () => {
  const { personaMaps } = useContext(GlobalStoreContext);
  return {
    roleMap: personaMaps.roleMap,
    roleArray: Array.from(personaMaps.roleMap.values()),
  };
};

const useGetGroupById = (groupId: string | undefined) => {
  const allGroups = useGetAllGroups();
  return groupId ? allGroups.groupMap.get(groupId) : undefined;
};

const useGetTicketById = (ticketId: string) => {
  const allTickets = useGetAllTickets();
  return allTickets.ticketMap.get(ticketId);
};

/*
    I won't write all the possible uses for this just yet.. but using this,
    a user should be able to get data for the following with O(1) lookup
*/

const useGetLobApplicationById = (lobApplicationId: string | undefined) => {
  const allLobApplications = useGetAllLobApplications();
  return lobApplicationId
    ? allLobApplications.lobApplicationsMap?.get(lobApplicationId)
    : undefined;
};

const useGetPartnerById = (partnerId: string | undefined) => {
  const allPartners = useGetAllPartners();
  return partnerId ? allPartners.partnersMap?.get(partnerId) : undefined;
};

const useGetSolutionById = (solutionId: string | undefined) => {
  const allSolutions = useGetAllSolutions();
  return solutionId ? allSolutions.solutionsMap?.get(solutionId) : undefined;
};

const useGetAccountsByPartnerId = (partnerId: string | undefined) => {
  return useGetPartnerById(partnerId)?.accounts;
};

const useGetAllApis = () => {
  const { personaMaps } = useContext(GlobalStoreContext);
  return {
    apiMap: personaMaps.apiMap,
    apiArray: Array.from(personaMaps.apiMap.values()),
  };
};

const useGetEnvironmentsBySolutionEnvironmentId = (
  solutionEnvironmentId: string | undefined,
) => {
  const allSolutionEnvironments = useGetAllSolutionEnvironments();
  return solutionEnvironmentId
    ? allSolutionEnvironments.solEnvMap?.get(solutionEnvironmentId)
    : undefined;
};

const useGetAllRoleMembers = () => {
  const { personaMaps } = useContext(GlobalStoreContext);
  return {
    memberRoleMap: personaMaps.memberRoleMap,
    memberArray: Array.from(personaMaps.memberRoleMap.values()),
  };
};

const useGetAccountById = (accountId: string | undefined) => {
  const allAccounts = useGetAllAccounts();
  return accountId ? allAccounts.accountsMap?.get(accountId) : undefined;
};

const useGetAllMembersBySolutionId = (solutionId: string) => {
  return useGetAllSolutions()?.solutionsMap?.get(solutionId)?.members;
};

const useGetAllExternalMembersBySolutionId = (
  solutionId: string | undefined,
  hasPermission: boolean,
) => {
  const allSolution = useGetAllSolutions();
  if (!hasPermission) return [];
  const solutionMap = allSolution.solutionsMap;
  const solution = solutionId ? solutionMap.get(solutionId) : undefined;
  return solution
    ? solution.members.filter(
        (members) => members.userType?.toString() !== 'WORKFORCE',
      )
    : [];
};

const useGetAllChaseAdmins = () => {
  const { memberArray } = useGetAllMembers();
  const chaseAdmins = memberArray.filter(
    (member) => member?.roleName === 'ChaseAdmin',
  );
  return chaseAdmins;
};

const useGetAllGroupMembersbyGroupName = (groupName: string | undefined) => {
  const { groupArray } = useGetAllGroups();
  const group = groupArray.find((group) => group.groupName === groupName);
  const groupMembers = group?.members;
  return groupMembers;
};

const useGetChaseMembers = () => {
  return useGetAllSolutions()?.solutionsArray?.[0].members;
};

const useGetUserByUserId = (userId: string) => {
  return useGetChaseMembers().filter(
    (members) =>
      members.userId.toLowerCase().toString() ===
      userId?.toLowerCase().toString(),
  )[0];
};

export {
  useGetAllLobApplications,
  useGetAllPartners,
  useGetAllAccounts,
  useGetAllSolutions,
  useGetAllEnvironments,
  useGetAllSolutionEnvironments,
  useGetAllMembers,
  useGetAllTickets,
  useGetTicketById,
  useGetPartnerById,
  useGetAccountsByPartnerId,
  useGetAccountById,
  useGetSolutionById,
  useGetEnvironmentsBySolutionEnvironmentId,
  useGetAllMembersBySolutionId,
  useGetAllGroupMembersbyGroupName,
  useGetAllChaseAdmins,
  useGetChaseMembers,
  useGetUserByUserId,
  useGetAllGroups,
  useGetGroupById,
  useGetAllRoles,
  useGetAllApis,
  useGetAllFeatures,
  useGetAllIAMFeatures,
  useGetAllProfiles,
  useGetProfileById,
  useGetAllRoleMembers,
  useGetAllExternalMembersBySolutionId,
  useGetLobs,
  useGetLobMembers,
  useGetLobApplicationById,
};
