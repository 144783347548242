import { MdsTextInput } from '@mds/react-es';
import { useContext, useMemo, useState } from 'react';
import { GlobalStoreContext } from '../../../../Solutions';
import ManageLobPartnersTableDesktop from './components/ManageLobPartnersTableDesktop';
import './manage-lob-partners-tab-stylesheet.scss';
//import AddLobPartnerSlideIn from './components/AddLobPartnerSlideIn';
import ManageLobPartnersMobile from './components/ManageLobPartnersMobile';
import { useGetAllLobApplications } from '../../../../../../../services/graphql-utils/useGraphQLMaps';
import { IPartner } from '../../../../../BreakoutPages/components/SinglePartnerBreakoutPage';
import EmptyTableMessage from '../../../../../../global-components/EmptyTableMessage/EmptyTableMessage';

export interface IPartnersWithLobApplicationNames extends IPartner {
  lobApplicationNames: string[];
}

const ManageLobPartnersTab = () => {
  const { isMobile } = useContext(GlobalStoreContext);
  const [isAddLobPartnerSlideInOpen, setIsAddLobPartnerSlideInOpen] =
    useState(false);
  const [searchText, setSearchText] = useState('');

  const handleLobAddPartnerSlideInToggle = (isOpen: boolean) => {
    setIsAddLobPartnerSlideInOpen(isOpen);
  };

  const { lobApplicationsArray } = useGetAllLobApplications();

  // Traverse the LOB applications and setup maps of the unique partners and associated
  // LOB application names. Return an array of partners with the lob names attached.
  const partners = useMemo((): IPartnersWithLobApplicationNames[] => {
    const uniquePartners = new Map<IPartner['partnerId'], IPartner>();
    const subLobMap = new Map<IPartner['partnerId'], string[]>();

    lobApplicationsArray.forEach((lobApplication) => {
      lobApplication.partners.forEach((partner) => {
        if (!partner?.partnerId) {
          return;
        } else {
          if (!uniquePartners.has(partner.partnerId)) {
            uniquePartners.set(partner.partnerId, partner);
          }
          if (!subLobMap.has(partner.partnerId)) {
            subLobMap.set(partner.partnerId, []);
          }
          subLobMap
            .get(partner.partnerId)
            ?.push(lobApplication?.applicationName);
        }
      });
    });

    return Array.from(uniquePartners.values()).map((partner) => {
      return {
        ...partner,
        lobApplicationNames: subLobMap.get(partner.partnerId) ?? [],
      };
    }) as IPartnersWithLobApplicationNames[];
  }, [lobApplicationsArray]);

  const isPartnersEmpty = !!(partners?.length === 0);

  return (
    <div className="manage-lob-partners-tab">
      <div className="top-section">
        {!isPartnersEmpty && (
          <div className="search-group">
            {!isMobile && <strong>Search</strong>}
          </div>
        )}
        {/* <MdsButton
          text="Add partner"
          onClick={() => handleLobAddPartnerSlideInToggle(true)}
          iconType="ico_add_circle"
          widthType={isMobile ? 'layout' : 'content'}
          variant={isMobile ? 'secondary' : 'tertiary'}
        /> */}
      </div>

      <MdsTextInput
        label="You can search by Partner name"
        fieldPrefix="ico_search"
        placeholder="Search"
        variant="box"
        onInput={(e) => setSearchText(e.target.value)}
      />

      {partners.length === 0 && (
        <EmptyTableMessage
          message={`You haven't added any partners yet.`}
          onClickButton={() => setIsAddLobPartnerSlideInOpen(true)}
          buttonText="Add Partner"
          hideButton
        />
      )}

      {partners.length > 0 && isMobile && (
        <ManageLobPartnersMobile partners={partners} searchText={searchText} />
      )}

      {partners.length > 0 && !isMobile && (
        <ManageLobPartnersTableDesktop
          partners={partners}
          searchText={searchText}
        />
      )}

      {/* <AddLobPartnerSlideIn
        isOpen={isAddLobPartnerSlideInOpen}
        onToggle={handleLobAddPartnerSlideInToggle}
        partners={partners}
      /> */}
    </div>
  );
};

export default ManageLobPartnersTab;
