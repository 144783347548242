import DescriptionPair from '../../../../../../../../../../global-components/DescriptionPair';
import { convertToTicketActivityDateFormat } from '../../../../../../../../../../global-components/Tickets/TicketActivity';
import './sub-lob-index-mobile-styles.scss';
import {
  LinkToSubLob,
  PartnersArrayAsGroupOfPills,
} from '../SubLobTableDesktop/SubLobTableDesktop';
import { ILobApplication } from '../../../../../../../../../Dashboard/scenes/SuperAdminLandingScreen';

const SubLobIndexMobile = ({ subLobs }: { subLobs: ILobApplication[] }) => {
  return (
    <div className="sub-lob-index-mobile">
      {subLobs.map((subLob: ILobApplication) => (
        <div className="sub-lob-card" key={subLob.lobApplicationId}>
          <LinkToSubLob data={subLob} />
          <div className="sub-lob-grid">
            <DescriptionPair
              term="Partner"
              value={<PartnersArrayAsGroupOfPills value={subLob.partners} />}
              boldedValue
              className="span-columns"
            />
            <DescriptionPair
              term="Date added"
              value={convertToTicketActivityDateFormat(subLob.createdAt)}
              boldedValue
            />
            <DescriptionPair
              term="Updated on"
              value={convertToTicketActivityDateFormat(subLob.lastUpdated)}
              boldedValue
            />
            {/* <DescriptionPair
                term="Added by"
                value={<FirstAndLastNameCellRenderer value={subLob.addedBy} />}
                boldedValue
              />
              <DescriptionPair
                term="Status"
                value={<EntityStatus status={subLob.status} />}
                boldedValue
              /> */}
          </div>
          {/*// TODO: add the following admin dashboard button with correct even*/}
          {/*<MdsButton*/}
          {/*  text="Admin dashboard"*/}
          {/*  variant="secondary"*/}
          {/*  iconType="ico_new_window"*/}
          {/*  widthType="layout"*/}
          {/*/>*/}
        </div>
      ))}
    </div>
  );
};
export default SubLobIndexMobile;
