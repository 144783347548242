import {
  MdsButton,
  MdsDialog,
  MdsSelect,
  MdsSelectOption,
  MdsTextInput,
} from '@mds/react-es';
import { axiosInstance } from '../../../../../../../services/webClientAxiosCalls';
import EmptyTableMessage from '../../../../../../global-components/EmptyTableMessage/EmptyTableMessage';
import './manage-lob-tab-stylesheet.scss';
import { useContext, useState } from 'react';
import { GlobalStoreContext } from '../../../../../Tickets/ViewAllTicketsPage';
import { useForm, useWatch } from 'react-hook-form';
import SuccessDialog from '../../../../../../global-components/SuccessDialog';
import checkboxSpot from '@mds/web-illustrations/dist/cmb/svgs/mds_ill_checkbox_featured.svg';
import LobAccordion from './components/LobAccordion';
import { useGetLobs } from '../../../../../../../services/graphql-utils/useGraphQLMaps';
import { useGetPersonaDash } from '../../../../../../../services/graphql-utils';

const ManageLobTab = () => {
  const { userProfile } = useContext(GlobalStoreContext);
  const { isMobile } = useContext(GlobalStoreContext);
  const { lobArray } = useGetLobs();
  const { refetch } = useGetPersonaDash(userProfile.userPersona);
  const {
    register,
    formState: { isValid, errors },
    control,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: { name: '', description: '' },
    mode: 'onChange',
    shouldUnregister: false, // Add this line to prevent unregistering fields
  });
  const formData = useWatch({ control });

  const [isAddLobDialogOpen, setIsAddLobDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [selectedLobIndex, setSelectedLobIndex] = useState(-1);

  const onSubmit = async () => {
    try {
      await axiosInstance.post('/partners/api/v1/lob', formData, {
        headers: {
          'x-request-id': crypto.randomUUID(),
        },
      });
      refetch();
      setIsAddLobDialogOpen(false);
      setIsSuccessDialogOpen(true);
      reset();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSuccessDialogOpen = (isOpen: boolean) => {
    setIsSuccessDialogOpen(isOpen);
  };

  const displayedLobs =
    // shows all lobs if no lob is selected
    // shows selectedLobIndex if a lob is selected from dropdown
    selectedLobIndex === -1 ? lobArray : [lobArray[selectedLobIndex]];

  return (
    <>
      <div id="manage-lob-tab">
        <div className="top-section">
          <div>
            {/* left side */}
            <span>Filters</span>
            <MdsSelect
              placeholder="LOB"
              variant="box"
              selectedIndex={selectedLobIndex}
              onValidate={(e) => {
                setSelectedLobIndex(e.target.selectedIndex);
              }}
            >
              {lobArray.map((lob) => (
                <MdsSelectOption
                  key={lob.lobId}
                  value={lob.lobId}
                  label={lob.lobName}
                />
              ))}
            </MdsSelect>
            <button
              onClick={() => {
                setSelectedLobIndex(-1);
              }}
            >
              Clear filters
            </button>
          </div>
          <MdsButton
            iconType="ico_add_circle"
            widthType={isMobile ? 'layout' : 'content'}
            variant={isMobile ? 'secondary' : 'tertiary'}
            text="Add LOB"
            onClick={() => setIsAddLobDialogOpen(true)}
          />
        </div>
        {displayedLobs.length > 0 ? (
          <>
            {displayedLobs.map((lob) => (
              <LobAccordion key={lob.lobId} lob={lob} />
            ))}
          </>
        ) : (
          <EmptyTableMessage
            message={`You haven't added any LOBs yet.`}
            onClickButton={() => setIsAddLobDialogOpen(true)}
            buttonText="Add LOB"
          />
        )}
      </div>

      {/* Add Lob dialog */}
      <MdsDialog
        open={isAddLobDialogOpen}
        onClose={() => setIsAddLobDialogOpen(false)}
        headerText="Add LOB"
        dialogWidth={588}
      >
        <div slot="dialogContent" id="add-lob-dialog">
          <form onSubmit={handleSubmit(onSubmit)}>
            <MdsTextInput
              label="Name"
              variant="box"
              value={formData.name}
              errorMessage={errors.name?.message}
              {...register('name', {
                required: true,
                validate: (value) =>
                  !lobArray.some(
                    (lob) =>
                      lob.lobName.toLowerCase().trim() ===
                      value.toLowerCase().trim(),
                  ) || 'An LOB with this name already exists',
              })}
            />
            <MdsTextInput
              label="Description"
              variant="box"
              multiline
              value={formData.description}
              {...register('description', {
                required: true,
              })}
            />
            <div className="bottom-button-group">
              <MdsButton
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  setIsAddLobDialogOpen(false);
                  reset();
                }}
                widthType={isMobile ? 'layout' : 'content'}
              />
              <MdsButton
                variant="primary"
                text="Add LOB"
                inactive={!isValid}
                widthType={isMobile ? 'layout' : 'content'}
                onClick={onSubmit}
              />
            </div>
          </form>
        </div>
      </MdsDialog>

      {/* Success dialog */}
      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        onDialogOpen={handleSuccessDialogOpen}
        spotIcon={checkboxSpot}
        successMessage={
          <>
            {`You've successfully added a new `}
            <strong>LOB.</strong>
          </>
        }
      />
    </>
  );
};
export default ManageLobTab;
