import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { convertToTicketActivityDateFormat } from '../../../../../../../../../../../../utils/time/dateConversion';
import { useMemo } from 'react';
import CustomHeader from '../../../../../../../../../../../global-components/AgGridUtils/CustomHeader/CustomHeader';
import { MdsLink } from '@mds/react-es';
import {
  IAccount,
  IPartner,
} from '../../../../../../../../../../BreakoutPages/components/SinglePartnerBreakoutPage';

export const LinkToPartner = ({ data }: { data: IPartner }) => (
  <MdsLink
    text={data.name}
    endIcon="ico_chevron_right"
    href={`/partners/${data.partnerId}/`}
  />
);

const columnDefs: ColDef[] = [
  /**
   * TODO: The Figma displays a count for internal/external members
   * This is a large calculation and has been deemed out of scope for now
   * */
  {
    headerName: 'Partner',
    field: 'partnerId',
    headerComponentParams: { enableSorting: true },
    minWidth: 125,
    cellRenderer: LinkToPartner,
  },
  {
    headerName: 'Accounts',
    field: 'accounts',
    headerComponentParams: { enableSorting: true },
    minWidth: 145,
    valueFormatter: ({ value }) => value.length,
  },
  {
    headerName: 'Solutions',
    field: 'accounts',
    headerComponentParams: { enableSorting: true },
    valueFormatter: ({ value }) =>
      value
        .map((account: IAccount) => account.solutions.length)
        .reduce((a: number, b: number) => a + b, 0),
    minWidth: 120,
  },
  {
    headerName: 'Updated on',
    field: 'lastUpdated',
    headerComponentParams: { enableSorting: true },
    valueFormatter: ({ value }) => convertToTicketActivityDateFormat(value),
    minWidth: 120,
  },
  // {
  //   headerName: '',
  //   field: 'partnerId',
  //   minWidth: 125,
  //   cellRenderer: () => (
  //     // unclear how link/relink status will be determined here
  //     <div style={{ display: 'flex', justifyContent: 'center' }}>
  //       <MdsSwitch stateLabelOn="Unlink" stateLabelOff="Relink" />
  //     </div>
  //   ),
  // },
];

const LinkedPartnersDesktop = ({ partners }: { partners: IPartner[] }) => {
  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  }, []);
  return (
    <div className="linked-partners-table apix-ag-grid ag-theme-balham">
      <AgGridReact
        containerStyle={{ width: '100%', height: '400px' }}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          autoHeight: true,
          wrapText: true,
          flex: 2,
        }}
        rowData={partners}
        components={components}
      />
    </div>
  );
};

export default LinkedPartnersDesktop;
