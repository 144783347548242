import {
  chaseAdminQuery,
  chaseRelationShipManagerQuery,
  chaseSupportQuery,
  featuresQuery,
  partnerAdminQuery,
  partnerDeveloperQuery,
  PartnerRelationshipManagerQuery,
  profilesQuery,
  superAdminQuery,
  ticketQuery,
  UserPersonaEnum,
  userProfileInfoQuery,
} from './';
import { useQuery } from '@apollo/client';
import { IUseQueryOptions } from './dashtype';

function selectQuery(userPersona: UserPersonaEnum) {
  switch (userPersona) {
    case UserPersonaEnum.ChaseAdmin:
      return chaseAdminQuery;
    case UserPersonaEnum.ChaseSuperAdmin:
      return superAdminQuery;
    case UserPersonaEnum.ChaseSupportTeam:
      return chaseSupportQuery;
    case UserPersonaEnum.PartnerAdmin:
      return partnerAdminQuery;
    case UserPersonaEnum.PartnerDeveloper:
      return partnerDeveloperQuery;
    case UserPersonaEnum.PartnerRelationshipManager:
      return PartnerRelationshipManagerQuery;

    case UserPersonaEnum.RelationshipManager:
      return chaseRelationShipManagerQuery;
    default:
      throw new Error('Invalid user persona');
  }
}

const useGetPersonaDash = (userPersona: UserPersonaEnum) => {
  const query = selectQuery(userPersona);

  return useQuery(query, {
    pollInterval: 500000,
  });
};

const useGetTicketDash = ({ skipQuery }: IUseQueryOptions) => {
  return useQuery(ticketQuery, {
    pollInterval: 60000,
    skip: skipQuery,
  });
};

const useGetUserProfileInfo = ({ skipQuery }: IUseQueryOptions) => {
  return useQuery(userProfileInfoQuery, {
    pollInterval: 60000,
    skip: skipQuery,
  });
};

const useGetPlatformFeatures = ({ skipQuery }: IUseQueryOptions) => {
  return useQuery(featuresQuery, {
    pollInterval: 60000,
    skip: skipQuery,
  });
};

const useGetPlatformProfiles = ({ skipQuery }: IUseQueryOptions) => {
  return useQuery(profilesQuery, {
    pollInterval: 60000,
    skip: skipQuery,
  });
};

export {
  useGetPersonaDash,
  useGetTicketDash,
  useGetUserProfileInfo,
  useGetPlatformFeatures,
  useGetPlatformProfiles,
};
