import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { convertToTicketActivityDateFormat } from '../../../../../../../../../../../utils/time/dateConversion';
import { EntityStatusCellRenderer } from '../../../../../ManageLobMembersTab/components/ManageLobMembersTableDesktop/ManageMembersTableDesktop';
import { useMemo, useState } from 'react';
import CustomHeader from '../../../../../../../../../../global-components/AgGridUtils/CustomHeader/CustomHeader';
import './sub-lob-table-desktop-stylesheet.scss';
import { MdsButton, MdsLink, MdsTag } from '@mds/react-es';
import { ILobApplication } from '../../../../../../../../../Dashboard/scenes/SuperAdminLandingScreen';
import { IPartner } from '../../../../../../../../../../../services/graphql-utils';
import { useNavigate } from 'react-router-dom';

export const LinkToSubLob = ({ data: subLob }: { data: ILobApplication }) => {
  /*
   * @param {ILobApplication} data - LobApplication object
   */
  const navigate = useNavigate();
  return (
    <MdsLink
      text={subLob.applicationName}
      endIcon="ico_chevron_right"
      onClick={() => navigate(`/partners/sub-lobs/${subLob.lobApplicationId}/`)}
      isButton
    />
  );
};
export const FirstAndLastNameCellRenderer = (
  { value }: { value: any },
  // TODO: any_typing
) => <span>{`${value?.firstName} ${value?.lastName}`}</span>;

export const PartnersArrayAsGroupOfPills = (
  { value }: { value: IPartner[] },
  // TODO: any_typing
) => {
  const needMoreLength = 8; //
  const needViewMore = value.length > needMoreLength;
  const [isViewingAll, setIsViewingAll] = useState(false);
  const displayedTags =
    isViewingAll || !needViewMore ? value : value.slice(0, needMoreLength);
  return (
    <div
      className="group-of-pills"
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '10px',
      }}
    >
      {displayedTags.map((partner) => (
        <MdsTag key={partner.partnerId} text={partner?.name} />
      ))}
      {needViewMore && (
        <button
          className="view-more-or-less mds-styled-button"
          onClick={() => setIsViewingAll(!isViewingAll)}
        >
          {isViewingAll ? 'View less' : 'View more'}
        </button>
      )}
    </div>
  );
};

const StatusAndAdminDashboardLink = ({ value }: { value: string }) => {
  return (
    <div className="status-and-admin-dashboard-link">
      <EntityStatusCellRenderer value={value} />
      <MdsButton
        text="Admin dashboard"
        variant="tertiary"
        iconType="ico_new_window"
        widthType="content"
        small
      />
    </div>
  );
};
const columnDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'applicationName',
    headerComponentParams: { enableSorting: true },
    minWidth: 125,
    cellRenderer: LinkToSubLob,
  },
  {
    headerName: 'Partners',
    field: 'partners',
    headerComponentParams: { enableSorting: true },
    cellRenderer: PartnersArrayAsGroupOfPills,
    minWidth: 145,
  },
  {
    headerName: 'Date added',
    field: 'createdAt',
    headerComponentParams: { enableSorting: true },
    valueFormatter: ({ value }) => convertToTicketActivityDateFormat(value),
    minWidth: 120,
  },
  {
    headerName: 'Updated on',
    field: 'lastUpdated',
    headerComponentParams: { enableSorting: true },
    valueFormatter: ({ value }) => convertToTicketActivityDateFormat(value),
    minWidth: 120,
  },
  //TODO: add AddedBy
  // {
  //   headerName: 'Added by',
  //   field: 'addedBy',
  //   headerComponentParams: { enableSorting: true },
  //   cellRenderer: FirstAndLastNameCellRenderer,
  //   minWidth: 70,
  // },
  // {
  //   headerName: 'Status',
  //   field: 'status',
  //   headerComponentParams: { enableSorting: true },
  //   cellRenderer: StatusAndAdminDashboardLink,
  //   minWidth: 244,
  // },
];

const SubLobTableDesktop = ({ subLobs }: { subLobs: ILobApplication[] }) => {
  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  }, []);
  return (
    <div className="manage-lob-table apix-ag-grid ag-theme-balham">
      <AgGridReact
        containerStyle={{ width: '100%', height: '400px' }}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          autoHeight: true,
          wrapText: true,
          flex: 2,
        }}
        rowData={subLobs}
        components={components}
      />
    </div>
  );
};

export default SubLobTableDesktop;
