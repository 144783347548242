import chaseLogo from '../../_images/logo-chase-white.svg';
import partnerCentralWordmark from '../../_images/partner-central-wordmark.svg';
import './registration-header-stylesheet.scss';
export default function RegistrationHeader() {
  return (
    <header className={'header'}>
      <div className="bounded-width">
        <div id={'bwmc'}>
          <a
            href={'/'}
            id="home-link"
            aria-label="back-to-homepage"
            data-pt-name="hd_header_backtohp"
            data-testId="header-home-link"
          >
            <div className="chase-developer">
              <span className="logo" title="Chase Logo">
                <img src={chaseLogo} alt={''} />{' '}
              </span>
              <span className="logo" id="chase-wordmark" title="Chase Wordmark">
                <img src={partnerCentralWordmark} alt={''} />{' '}
              </span>
            </div>
          </a>
        </div>
      </div>
    </header>
  );
}
