import { ArrayToTagGroupCellRenderer } from '../ManageLobPartnersTableDesktop/ManageLobPartnersTableDesktop';
import { parseDate } from '../../../../../../../../../utils/time/dateConversion';
import DescriptionPair from '../../../../../../../../global-components/DescriptionPair';
import './manage-lob-partners-mobile.scss';
import { searchArrayForText } from '../../../../../../../../../utils/searchArray';
import { useMemo } from 'react';
import { IPartnersWithLobApplicationNames } from '../../ManageLobPartnersTab';

const ManageLobPartnersMobile = ({
  partners,
  searchText,
}: {
  partners: IPartnersWithLobApplicationNames[];
  searchText: string;
}) => {
  const filteredPartners = useMemo(
    () => searchArrayForText(partners, searchText),
    [partners, searchText],
  );

  return (
    <div className="mobile-manage-lob-partners">
      {filteredPartners.map((partner) => (
        <ManageLobPartnersCard key={partner.id} partner={partner} />
      ))}
    </div>
  );
};
export default ManageLobPartnersMobile;

const ManageLobPartnersCard = ({
  partner,
}: {
  partner: IPartnersWithLobApplicationNames;
}) => {
  return (
    <div className="partner-card">
      <div className="top span-columns">
        <strong>{partner.partnerId}</strong>
      </div>
      <DescriptionPair term="Partner name" value={partner.name} boldedValue />
      <DescriptionPair
        term="SubLOB"
        className="tag-list span-columns"
        value={
          <ArrayToTagGroupCellRenderer value={partner.lobApplicationNames} />
        }
      />
      <DescriptionPair
        term="Date added"
        value={parseDate(partner.createdAt)}
        boldedValue
      />
      <DescriptionPair
        term="Last updated"
        value={parseDate(partner.lastUpdated)}
        boldedValue
      />
    </div>
  );
};
