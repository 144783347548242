import './full-page-container.scss';
const FullPageContainer = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={`full-page-container ${className && className}`}>
      <div>{children}</div>
    </div>
  );
};

export default FullPageContainer;
