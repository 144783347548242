type TEnvironmentEndpoints = {
  login: string;
  authe: string;
  registration: string;
  homePage: string;
  partnerCentralPage: string;
  reinvite: string;
  termsOfUse: string;
  journeyParams: {
    acr_values: string;
    ids: string;
    aud: string;
    [key: string]: any;
  };
};

type TEndpoints = Record<string, TEnvironmentEndpoints>;

const endpoints: TEndpoints = {
  local: {
    login: 'https://portal.eks.apix.dev.aws.jpmchase.net/login',
    authe: 'https://nwas-sit.jpmchase.net',
    //registration: 'https://portal.eks.apix.dev.aws.jpmchase.net/registration/',
    registration: 'https://nwas-sit.jpmchase.net/apix_registration/',
    homePage: 'https://portal.eks.apix.dev.aws.jpmchase.net/',
    partnerCentralPage: 'https://portal.eks.apix.dev.aws.jpmchase.net',
    reinvite:
      'https://nwas-sit.jpmchase.net/apix_registration/api/reinvite?email=',
    termsOfUse: 'https://portal.eks.apix.dev.aws.jpmchase.net/terms',
    journeyParams: {
      acr_values: 'AAL1',
      ids: '1',
      aud: 'JPMC:URI:RS-87374-UserArea-SIT',
    },
  },
  sit: {
    login: 'https://portal.eks.apix.dev.aws.jpmchase.net/login',
    //registration: 'https://portal.eks.apix.dev.aws.jpmchase.net/registration/',
    registration: 'https://nwas-sit.jpmchase.net/apix_registration/',
    homePage: 'https://portal.eks.apix.dev.aws.jpmchase.net/',
    authe: 'https://nwas-sit.jpmchase.net',
    partnerCentralPage: 'https://portal.eks.apix.dev.aws.jpmchase.net',
    reinvite:
      'https://nwas-sit.jpmchase.net/apix_registration/api/reinvite?email=',
    termsOfUse: 'https://portal.eks.apix.dev.aws.jpmchase.net/terms',
    journeyParams: {
      acr_values: 'AAL1',
      ids: '1',
      aud: 'JPMC:URI:RS-87374-UserArea-SIT',
    },
  },
  uat: {
    login: 'https://developer-uat.chase.com/login',
    //registration: 'https://developer-uat.chase.com/registration/',
    registration: 'https://nwas-ent-uat.jpmorgan.com/apix_registration/',
    homePage: 'https://developer-uat.chase.com/',
    authe: 'https://nwas-ent-uat.jpmorgan.com',
    partnerCentralPage: 'https://developer-uat.chase.com',
    reinvite:
      'https://nwas-ent-uat.jpmorgan.com/apix_registration/api/reinvite?email=',
    termsOfUse: 'https://developer-uat.chase.com/terms',
    journeyParams: {
      acr_values: 'AAL1',
      ids: '1',
      aud: 'JPMC:URI:RS-82001-UserArea-UAT',
    },
  },
  prod: {
    login: 'https://developer.chase.com/login',
    //registration: 'https://developer.chase.com/registration/',
    registration: 'https://nwas-ent.jpmorgan.com/apix_registration/',
    homePage: 'https://developer.chase.com/',
    authe: 'https://nwas-ent.jpmorgan.com',
    partnerCentralPage: 'https://developer.chase.com',
    reinvite:
      'https://nwas-ent.jpmorgan.com/apix_registration/api/reinvite?email=',
    termsOfUse: 'https://developer.chase.com/terms',
    journeyParams: {
      acr_values: 'AAL2',
      ids: '1',
      aud: 'JPMC:URI:RS-82001-UserArea-PROD',
    },
  },
};

export default endpoints;
