import { MdsButton } from '@mds/react-es';
import { useNavigate } from 'react-router-dom';
import './manage-profiles-mobile-stylesheet.scss';
import { convertToTicketActivityDateFormat } from '../../../../../../../utils/time/dateConversion';
import { useMemo } from 'react';
import { searchArrayForText } from '../../../../../../../utils/searchArray';
import { IProfile } from '../../../../../../../services/graphql-utils/dashtype';

const ManageProfilesMobile = ({
  profiles,
  searchText,
}: {
  profiles: IProfile[];
  searchText: string;
}) => {
  const navigate = useNavigate();

  const filteredProfiles = useMemo(
    () => searchArrayForText(profiles, searchText),
    [searchText, profiles],
  );

  const getRoleName = (roleId: string) => {
    const role = roleId
      ? profiles.find((profile) => profile.profileId === roleId)
      : undefined;
    if (!role) return 'N/A';

    return role.name;
  };

  return (
    <>
      <ul id="manage-profiles-mobile">
        {filteredProfiles.map((profile: IProfile) => (
          <li key={profile.profileId}>
            <MdsButton
              variant="tertiary"
              small
              text={profile.name}
              onClick={() =>
                navigate(`/partners/profiles/${profile.profileId}/`)
              }
              iconType="ico_chevron_right"
              iconPosition="trailing"
            />
            <div className="group role">
              <p>Role</p>
              <p>{getRoleName(profile.parentProfileId)}</p>
            </div>
            <div className="grid">
              <div className="group">
                <p>Date added</p>
                <p>
                  <strong>
                    {convertToTicketActivityDateFormat(profile.createdAt)}
                  </strong>
                </p>
              </div>
              <div className="group">
                <p>Modified date</p>
                <p>
                  <strong>
                    {convertToTicketActivityDateFormat(profile.lastUpdated)}
                  </strong>
                </p>
              </div>
              {/*TODO: fix this once added by GraphQL is ready */}
              {/*<div className="group">*/}
              {/*  <p>Added by</p>*/}
              {/*  <p>*/}
              {/*    <strong>{null}</strong>*/}
              {/*  </p>*/}
              {/*</div>*/}
              {/*TODO: fix this once status is part of GraphQL */}
              {/*<div className="group">*/}
              {/*  <p>Status</p>*/}
              {/*  <EntityStatus status="INACTIVE" entityName="profile" />*/}
              {/*</div>*/}
            </div>
            {/*<MdsButton*/}
            {/*  variant="secondary"*/}
            {/*  text="Access admin"*/}
            {/*  iconType="ico_new_window"*/}
            {/*  iconPosition="leading"*/}
            {/*  widthType="layout"*/}
            {/*/>*/}
          </li>
        ))}
      </ul>
    </>
  );
};
export default ManageProfilesMobile;
