/**
 * This is legacy code used for the registration section. This will need to be refactored in the future.
 *
 * @returns ENV environment needed for registration URLs
 */

const getEnvironment = (): string => {
  const winLocation = window.location.hostname;
  let env = '';
  switch (true) {
    case winLocation.includes('localhost') ||
      winLocation.includes('nwas-sit.jpmchase.net'):
      env = 'sit';
      break;
    case winLocation.includes('uat'):
      env = 'uat';
      break;
    case winLocation.includes('prod') || winLocation.includes('nwas-ent'):
      env = 'prod';
      break;
    default:
      env = 'sit';
  }
  return env;
};

export default getEnvironment;
