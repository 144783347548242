import BackButton from '../../../../global-components/BackButton/BackButton';
import {
  MdsAccordionItem,
  MdsSelect,
  MdsSelectOption,
  MdsTabBar,
} from '@mds/react-es';
import ProfileAuditHistoryTab from './components/ProfileAuditHistoryTab/ProfileAuditHistoryTab';
import { useContext, useState } from 'react';
import { GlobalStoreContext } from '../../../../../utils/hooks/GlobalStoreProvider';
import { convertToTicketActivityDateFormat } from '../../../../../utils/time/dateConversion';
import ViewAndManageFeature from './components/ViewAndManageFeature/ViewAndManageFeature';
import ApixSuccessBanner from '../../../../global-components/ApixSuccessBanner/ApixSuccessBanner';
import './single-profile-page-stylesheet.scss';
import {
  useGetAllIAMFeatures,
  useGetProfileById,
} from '../../../../../services/graphql-utils/useGraphQLMaps';
import { useParams } from 'react-router-dom';
import { useGetPlatformProfiles } from '../../../../../services/graphql-utils/graphQLAxios';

const SingleProfilePage = () => {
  const { profileId } = useParams();
  const profile = useGetProfileById(profileId);
  const { featureArray } = useGetAllIAMFeatures();
  const { isMobile } = useContext(GlobalStoreContext);
  const [isSuccessBannerShown, setIsSuccessBannerShown] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { refetch } = useGetPlatformProfiles({
    skipQuery: false,
  });

  const handleSuccessBanner = (isOpen: boolean) => {
    refetch();
    setIsSuccessBannerShown(isOpen);
  };

  const role = useGetProfileById(profile?.parentProfileId);

  // TODO: currently only showing ones w child permissions, in the future may do limited feature set based on parent profile for custom profiles
  const filteredFeatureArray = featureArray.filter(
    (feature) => feature.permissions?.length > 0,
  );

  return (
    <>
      <div id="single-profile-page">
        <BackButton />
        <div className="top-section">
          <div id="title">
            <h1>{profile?.name}</h1>
            {/*<button className="mds-styled-button">*/}
            {/*  <MdsIcon type="ico_pencil" size={24} />*/}
            {/*</button>*/}
          </div>
          {/*<MdsSwitch*/}
          {/*  stateLabelOn="Deactivate profile"*/}
          {/*  stateLabelOff="Activate profile"*/}
          {/*/>*/}
        </div>
        <div className="accordion-container">
          <MdsAccordionItem
            id="overview-accordion"
            label="Overview"
            variant="borderless"
            size="18px"
            expanded
          >
            <div id="profile-overview" className="overviews">
              {/* <div id="overviews-description">
                <span className="bolded">Description</span>
                <span className="description">{description}</span>
                <button className="mds-styled-button">
                  <MdsIcon type="ico_pencil" size={16} />
                </button>
              </div> */}
              <div id="overviews-grid">
                {/* <div className="overview-group">
                  <span className="bolded">Added by</span>
                  <span>{profile?.addedBy}</span>
                </div> */}
                <div className="overview-group">
                  <span className="bolded">Added on</span>
                  <span>
                    {profile
                      ? convertToTicketActivityDateFormat(profile.createdAt)
                      : ''}
                  </span>
                </div>
                <div className="overview-group">
                  <span className="bolded">Updated on</span>
                  <span>
                    {profile
                      ? convertToTicketActivityDateFormat(profile.lastUpdated)
                      : ''}
                  </span>
                </div>
              </div>
              <div className="overview-group">
                <span className="bolded">Role</span>
                <span>{role ? role.name : 'N/A'}</span>
              </div>
            </div>
          </MdsAccordionItem>
        </div>
        <div className="accordion-container">
          {isMobile ? (
            <>
              <span className="details">Details</span>
              <MdsSelect
                selectedIndex={selectedTabIndex}
                onValidate={(e) => setSelectedTabIndex(e.target.selectedIndex)}
                variant="box"
              >
                <MdsSelectOption
                  label="Manage features"
                  value="manage_features"
                />
                {/*<MdsSelectOption label="Audit history" value="audit_history" />*/}
              </MdsSelect>
              {selectedTabIndex === 0 ? (
                <>
                  <ApixSuccessBanner
                    headerText="Success!"
                    text={
                      <>
                        {`You've successfully updated features for this `}
                        <strong>profile</strong>
                      </>
                    }
                    isOpen={isSuccessBannerShown}
                  />
                  <div id="manage-features-tab">
                    {filteredFeatureArray.map((feature) => (
                      <ViewAndManageFeature
                        key={feature.featureId}
                        feature={feature}
                        profile={profile}
                        handleSuccessBanner={handleSuccessBanner}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <ProfileAuditHistoryTab />
              )}
            </>
          ) : (
            <MdsTabBar
              selectedTab={selectedTabIndex}
              tabs={[{ text: 'Manage features' }]}
              // tabs={[{ text: 'Manage features' }, { text: 'Audit history' }]}
              onClickTab={(e) => setSelectedTabIndex(e.target.selectedIndex)}
            >
              <div slot="panel-0">
                <div id="manage-features-tab">
                  <ApixSuccessBanner
                    headerText="Success!"
                    text={
                      <>
                        {`You've successfully updated features for this `}
                        <strong>profile</strong>
                      </>
                    }
                    isOpen={isSuccessBannerShown}
                  />
                  {filteredFeatureArray.map((feature) => (
                    <ViewAndManageFeature
                      key={feature.featureId}
                      feature={feature}
                      profile={profile}
                      handleSuccessBanner={handleSuccessBanner}
                    />
                  ))}
                </div>
              </div>
              {/*<div slot="panel-1">*/}
              {/*  <ProfileAuditHistoryTab />*/}
              {/*</div>*/}
            </MdsTabBar>
          )}
        </div>
      </div>
    </>
  );
};

export default SingleProfilePage;
