import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { MdsIcon, MdsLink, MdsTag } from '@mds/react-es';
import { useNavigate } from 'react-router-dom';
import {
  ApixAgGridPagination,
  CustomHeader,
} from '../../../../../../AdminPage/AdminPageTabs/ManageLOBAssignmentTab/DesktopAndMobileViews';
import '../../../../../../../../../styles/apix-ag-grid-stylesheet.scss';
import '../../../../../../../../../styles/apix-table-stylesheet.scss';
import { parseDate } from '../../../../../../../../global-components/Forms/CreateTicketForm';
import './manage-lob-partners-table-desktop-stylesheet.scss';
import { IPartnersWithLobApplicationNames } from '../../ManageLobPartnersTab';

const defaultColumnDefs: ColDef = {
  sortable: true,
  autoHeight: true,
  wrapText: true,
  flex: 2,
};

export const ArrayToTagGroupCellRenderer = ({ value }: { value: string[] }) => (
  <div
    className="group-of-pills"
    style={{
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      gap: '10px',
    }}
  >
    {value.map((singleItem) => (
      <MdsTag key={singleItem} text={singleItem} />
    ))}
  </div>
);

const LinkCellRenderer = (props: CustomCellRendererProps) => {
  const { value, data } = props;
  const navigate = useNavigate();

  return (
    <MdsLink
      text={value}
      endIcon="ico_chevron_right"
      onClick={() => navigate(`/partners/manage-lob/partner/${data.partnerId}`)}
    />
  );
};

export const EntityStatusCellRenderer = ({ value }: { value: string }) => {
  const style = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
  };

  const { text, icon, color } =
    value === 'INACTIVE'
      ? { text: 'Inactive', icon: 'ico_alert_filled', color: 'default' }
      : { text: 'Active', icon: 'ico_checkmark_filled', color: 'success' };

  return (
    <div className="partner-status" style={style}>
      <MdsIcon
        size={16}
        color={(color as 'default') || 'success'}
        type={icon}
        removeHorizontalMargin
      />
      <span>{text}</span>
    </div>
  );
};

const columnDefs: ColDef[] = [
  {
    headerName: 'Partner',
    field: 'name',
    headerComponentParams: { enableSorting: true },
    cellRenderer: LinkCellRenderer,
    minWidth: 125,
  },
  {
    headerName: 'Sub-lob',
    field: 'lobApplicationNames',
    headerComponentParams: { enableSorting: true },
    cellRenderer: ArrayToTagGroupCellRenderer,
    minWidth: 100,
  },
  {
    headerName: 'Date added',
    field: 'createdAt',
    headerComponentParams: { enableSorting: true },
    valueFormatter: ({ value }) => parseDate(value),
    minWidth: 120,
  },
  {
    headerName: 'Updated on',
    field: 'updatedAt',
    headerComponentParams: { enableSorting: true },
    valueFormatter: ({ value }) => parseDate(value),
    minWidth: 70,
  },
  {
    headerName: 'Status',
    field: 'status',
    headerComponentParams: { enableSorting: true },
    cellRenderer: EntityStatusCellRenderer,
    minWidth: 130,
  },
];

const ManageLobPartnersTableDesktop = ({
  partners,
  searchText,
}: {
  partners: IPartnersWithLobApplicationNames[];
  searchText: string;
}) => {
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const totalPages = gridApi?.paginationGetTotalPages() || 1;
  const tableRef = useRef<any>();
  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const evaluateGridPageSize = () => {
    if (!tableRef.current) return;
    const idealRowNum = Math.floor(
      tableRef.current.getBoundingClientRect().height / 100,
    );
    gridApi?.setGridOption('paginationPageSize', idealRowNum || 5);
  };

  useLayoutEffect(() => {
    evaluateGridPageSize();
  }, [gridApi, evaluateGridPageSize]);

  return (
    <div
      id="manage-lob-partners-table"
      className="apix-ag-grid ag-theme-balham"
      ref={tableRef}
    >
      <AgGridReact
        containerStyle={{ width: '100%', height: '100%' }}
        columnDefs={columnDefs}
        defaultColDef={defaultColumnDefs}
        rowData={partners}
        components={components}
        onGridReady={onGridReady}
        quickFilterText={searchText}
        pagination
        suppressPaginationPanel
      />
      {gridApi && totalPages > 1 && <ApixAgGridPagination gridApi={gridApi} />}
    </div>
  );
};

export default ManageLobPartnersTableDesktop;
