import { MdsButton, MdsDialog, MdsTextInput } from '@mds/react-es';
import { useContext, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { GlobalStoreContext } from '../../../../Solutions';
import SuccessDialog from '../../../../../../global-components/SuccessDialog';
import './add-new-profile-modal-form-stylesheet.scss';
import checkboxSpot from '@mds/web-illustrations/dist/cmb/svgs/mds_ill_checkbox_featured.svg';
import alertSpot from '@mds/web-illustrations/dist/cmb/svgs/mds_ill_alert_featured_circle.svg';
import { useMutation } from '@tanstack/react-query';
import { addCustomProfile } from '../../../../../../../services/webClientAxiosCalls';
import SelectEntity from '../../../../../../global-components/AddEntitySuperForm/WrappedMds/SelectEntity';
import { IProfile } from '../../../../../../../services/graphql-utils/dashtype';
import { GenericTicketErrorDialog } from '../../../../../../global-components/Tickets/TicketDetails';

interface AddProfileType {
  name: string;
  description: string;
  role: string;
}

const AddNewProfileModalForm = ({
  profiles,
  isOpen,
  handleAddNewProfileModal,
}: {
  profiles: IProfile[];
  isOpen: boolean;
  handleAddNewProfileModal: (isOpen: boolean) => void;
}) => {
  const { isMobile } = useContext(GlobalStoreContext);
  const allRoles = profiles?.filter(
    (profile) => profile.profileType === 'SYSTEM',
  );
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const methods = useForm<AddProfileType>();
  const { register, reset, control, formState } = methods;
  const formData = useWatch({ control });
  const [isNameError, setIsNameError] = useState(false);

  const addCustomProfileMutation = useMutation({
    mutationFn: (payload: AddProfileType) =>
      addCustomProfile(payload.name, payload.description, payload.role),
    onSuccess: () => {
      handleAddNewProfileModal(false);
      setIsSuccessDialogOpen(true);
      reset();
    },
    onError: () => {
      handleAddNewProfileModal(false);
      setIsErrorDialogOpen(true);
      reset();
    },
  });

  const submitCustomProfile = (): void => {
    const payload = {
      name: formData.name!,
      description: formData.description!,
      role: formData.role!,
    };

    addCustomProfileMutation.mutate(payload);
  };

  const handleSuccessDialogOpen = (isOpen: boolean) => {
    setIsSuccessDialogOpen(isOpen);
  };

  const getIsNameError = (currentName: string | undefined) => {
    if (!currentName) {
      return false;
    }
    return profiles.some(
      (profile) => profile.name.toLowerCase() === currentName?.toLowerCase(),
    );
  };

  return (
    <>
      <MdsDialog
        headerText="Add a new profile"
        dialogWidth={588}
        heightType="absolute"
        closeOnOverlayClick={false}
        open={isOpen}
        onClose={() => handleAddNewProfileModal(false)}
      >
        <div slot="dialogContent" id="add-new-profile">
          <FormProvider {...methods}>
            <MdsTextInput
              label="Profile name"
              variant="box"
              value={formData.name}
              {...register('name', { required: true })}
              onInput={(e) => setIsNameError(getIsNameError(e.target.value))}
              errorMessage={
                isNameError ? 'A profile with this name already exists.' : ''
              }
            />
            <MdsTextInput
              label="Description"
              variant="box"
              value={formData.description}
              multiline
              {...register('description', { required: true })}
            />
            <SelectEntity
              useFormField="role"
              required
              labelText="Role"
              contentData={{
                arr: allRoles,
                labelKey: 'name', // TODO: replace with description when GraphQL is updated
                valueKey: 'name',
              }}
            />

            <div className="bottom-button-group">
              <MdsButton
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  handleAddNewProfileModal(false);
                  setIsCancelDialogOpen(true);
                }}
                widthType={isMobile ? 'layout' : 'content'}
              />
              <MdsButton
                variant="primary"
                text="Add profile"
                inactive={!formState.isValid || isNameError}
                widthType={isMobile ? 'layout' : 'content'}
                onClick={() => {
                  submitCustomProfile();
                }}
              />
            </div>
          </FormProvider>
        </div>
      </MdsDialog>

      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        onDialogOpen={handleSuccessDialogOpen}
        successMessage={"You've successfully added a new profile."}
        spotIcon={checkboxSpot}
      />

      <MdsDialog
        open={isCancelDialogOpen}
        dialogWidth={619}
        dialogHeight={isMobile ? undefined : 366}
        headerText="Are you sure?"
        dialogHeaderIconName="ico_alert_filled"
        primaryButtonText="Go back"
        onClickButton1={() => {
          setIsCancelDialogOpen(false);
          handleAddNewProfileModal(true);
        }}
        secondaryButtonOneText="Cancel"
        onClickButton2={() => {
          setIsCancelDialogOpen(false);
          reset();
        }}
      >
        <div slot="dialogContent" id="cancel-profile-dialog-content">
          <img src={alertSpot} alt="Checkbox" width={130} height={130} />
          If you cancel now, your changes won&#39;t be saved.
        </div>
      </MdsDialog>
      {/*reusing generic ticket error dialog component for now*/}
      <GenericTicketErrorDialog
        isDialogOpen={isErrorDialogOpen}
        handleClose={() => setIsErrorDialogOpen(false)}
        headerText="Error"
        dialogHeaderIconName="ico_alert_filled"
        message="New profile was not added."
        description="Please go back and try again."
      />
    </>
  );
};
export default AddNewProfileModalForm;
