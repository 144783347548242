type RecaptchaSiteKey = Record<
  string,
  {
    siteKey: string;
  }
>;

const recaptchaSiteKey: RecaptchaSiteKey = {
  local: {
    siteKey: '6Lec2ikjAAAAAKjD_uBzkGFdiu823PPcCGccG-KE',
  },
  sit: {
    siteKey: '6Lec2ikjAAAAAKjD_uBzkGFdiu823PPcCGccG-KE',
  },
  uat: {
    siteKey: '6LcZ5ykjAAAAADJlqRm7msliF3htQLcyJvHbccb8',
  },
  prod: {
    siteKey: '6Ld6EfgiAAAAAOnA-sAANnIovUAL6Cy5v19kFMwy',
  },
};

export default recaptchaSiteKey;
