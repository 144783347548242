import { MdsSelect, MdsSelectOption } from '@mds/react-es';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const SelectEntity = ({
  useFormField,
  labelText,
  placeholder = 'Select',
  required = true,
  contentData,
  isParentSelected = true,
  dependentChild,
  searchEnabled = false,
  errorMessage = '',
  autoSelectFirstValue = false,
  id,
  clearErrorsOnChange = false,
}: {
  useFormField: string;
  labelText?: string;
  placeholder?: string;
  required?: boolean;
  contentData: {
    arr: any;
    labelKey: any;
    labelKey2?: any;
    labelKey3?: any;
    valueKey: any;
  };
  isParentSelected?: boolean;
  dependentChild?: string;
  searchEnabled?: boolean;
  errorMessage?: string;
  autoSelectFirstValue?: boolean;
  id?: string;
  clearErrorsOnChange?: boolean;
}) => {
  const {
    register,
    unregister,
    setValue,
    getValues,
    control,
    getFieldState,
    clearErrors,
  } = useFormContext();
  const formData = useWatch({ control });
  const { error } = getFieldState(useFormField);
  const showSelectText =
    !isParentSelected || (isParentSelected && contentData.arr.length);
  const currentValue = formData[useFormField];

  const resetDependentChild = (e?: Event) => {
    if (dependentChild && getValues(dependentChild)) {
      setValue(dependentChild, undefined);
    }
  };

  const handleChange = (e: Event) => {
    if (clearErrorsOnChange) clearErrors();
    resetDependentChild();
    setValue(useFormField, (e.target as any).value);
  };

  useEffect(() => {
    setValue(useFormField, undefined);
    return () => {
      unregister(useFormField);
    };
  }, [useFormField, setValue, unregister]);

  useEffect(() => {
    if (!currentValue) {
      resetDependentChild();
    }
  }, [currentValue]);

  useEffect(() => {
    if (autoSelectFirstValue && contentData.arr?.length === 1) {
      setValue(useFormField, contentData.arr[0][contentData.valueKey]);
    }
    if (!showSelectText || !contentData.arr?.length) resetDependentChild();
  }, [
    autoSelectFirstValue,
    contentData.arr,
    contentData.valueKey,
    useFormField,
    setValue,
  ]);

  return (
    <div className="input-wrapper select-wrapper">
      <MdsSelect
        label={labelText ? labelText : `Select ${useFormField}`}
        key={`${useFormField} ${JSON.stringify(contentData.arr)} ${showSelectText ? 'select' : 'n/a'}`}
        placeholder={showSelectText ? placeholder : 'N/A'}
        variant="box"
        searchEnabled={searchEnabled}
        clearSearchButtonAcessibleText=""
        errorMessage={error ? errorMessage : ''}
        {...(id && { id: id })}
        id={id}
        inactive={!isParentSelected || !contentData.arr.length}
        {...(register(useFormField, { required: required }) || undefined)}
        value={formData[useFormField]}
        onChange={handleChange}
      >
        {contentData.arr?.map((contentObj: any, idx: number) => {
          const labelString = contentData.labelKey3
            ? `${contentObj[contentData.labelKey]} - ${contentObj[contentData.labelKey2]} ${contentObj[contentData.labelKey3]}`
            : contentData.labelKey2
              ? `${contentObj[contentData.labelKey]} ${contentObj[contentData.labelKey2]}`
              : `${contentObj[contentData.labelKey]}`;
          return (
            <MdsSelectOption
              key={`${useFormField} ${idx}`}
              label={labelString}
              value={contentObj[contentData.valueKey] as string}
            />
          );
        })}
      </MdsSelect>
    </div>
  );
};

export default SelectEntity;
