import { gql } from '@apollo/client';

export const featuresQuery = gql`
  query {
    featuresQuery {
      featureId
      featureName
      permissions {
        featurePermissionId
        displayName
        actionName
      }
    }
  }
`;
