import { MdsButton, MdsTextInput } from '@mds/react-es';
import EmptyTableMessage from '../../../../../global-components/EmptyTableMessage/EmptyTableMessage';
import ManageProfilesDataTable from './components/ManageProfilesDataTable';
import { useContext, useState } from 'react';
import './manage-profiles-tab-stylesheet.scss';
import { GlobalStoreContext } from '../../../Solutions';
import ManageProfilesMobile from './components/ManageProfilesMobile';
import AddNewProfileModalForm from './components/AddNewProfileModalForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAllProfiles } from '../../../../../../services/graphql-utils/useGraphQLMaps';
import { useGetPlatformProfiles } from '../../../../../../services/graphql-utils/graphQLAxios';

const ManageProfilesTab = () => {
  const [searchText, setSearchText] = useState('');
  const [isAddNewProfileModalOpen, setIsAddNewProfileModalOpen] =
    useState(false);
  const { profileArray } = useGetAllProfiles();
  const { refetch } = useGetPlatformProfiles({
    skipQuery: false,
  });
  const location = useLocation();
  const navigate = useNavigate();

  const { isMobile } = useContext(GlobalStoreContext);
  const isNoProfiles = !profileArray || profileArray.length === 0;

  const handleAddNewProfileModal = (isOpen: boolean) => {
    setIsAddNewProfileModalOpen(isOpen);
    refetch();
  };

  return (
    <div id="manage-profiles-tab">
      {!isNoProfiles && (
        <div className="search-group">
          <p>Search</p>
          <MdsTextInput
            label="You can search by profile name or role."
            fieldPrefix="ico_search"
            placeholder="Search"
            variant="box"
            onInput={(e) => setSearchText(e.target.value)}
          />
        </div>
      )}

      {/* location?.state?.showSuccessBanner && (
        <ApixSuccessBanner
          headerText="Success!"
          text={
            <>
              {`You've successfully created a profile. You can now add a `}
              <MdsLink
                text="sub-LOB"
                isButton
                onClick={() =>
                  navigate('//', {
                    state: {
                      arrivingFromLobId: location.state.arrivingFromLobId,
                    },
                  })
                }
              />
            </>
          }
          isOpen
        />
      ) */}
      <div className="button-wrapper">
        <MdsButton
          variant={isMobile ? 'secondary' : 'tertiary'}
          widthType={isMobile ? 'layout' : 'content'}
          text="Add new profile"
          iconType="ico_add_circle"
          iconPosition="leading"
          onClick={() => handleAddNewProfileModal(true)}
        />
      </div>
      <AddNewProfileModalForm
        profiles={profileArray}
        isOpen={isAddNewProfileModalOpen}
        handleAddNewProfileModal={handleAddNewProfileModal}
      />
      {isNoProfiles ? (
        <EmptyTableMessage
          message="You haven't added any profiles yet."
          buttonText="Add a new profile"
          onClickButton={() => handleAddNewProfileModal(true)}
        />
      ) : (
        <>
          {isMobile ? (
            <ManageProfilesMobile
              profiles={profileArray}
              searchText={searchText}
            />
          ) : (
            <ManageProfilesDataTable
              profiles={profileArray}
              searchText={searchText}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ManageProfilesTab;
