import { MdsSelect, MdsSelectOption, MdsTabBar } from '@mds/react-es';
import { GlobalStoreContext } from '../../Solutions';
import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { WelcomeMessage } from '../../../Dashboard/scenes/PartnerAdminLandingScreen';
import { FullPageContainer } from '../../AdminPage';
import { useWindowSize } from 'react-use';
import './manage-lob-page-stylesheet.scss';
import ManageLobTab from './components/ManageLobTab';
import ManageLobMembersTab from './components/ManageLobMembersTab';
import ManageLobPartnersTab from './components/ManageLobPartnersTab';

const tabs = [
  { text: 'Manage LOB' },
  { text: 'Manage LOB members' },
  { text: 'Manage partners' },
];

const ManageLobPage = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [computedHeight, setComputedHeight] = useState(500);
  const { isMobile } = useContext(GlobalStoreContext);
  const { width, height } = useWindowSize();
  const tabRef = useRef();

  useLayoutEffect(() => {
    // this layoutEffect is used to calculate the height of the tab panel
    // MDS tab bar does allow for dynamic height, so we need to calculate it
    if (tabRef.current) {
      const htmlRef = (tabRef?.current as any)._ref?.current;
      const { height } = htmlRef.getBoundingClientRect();

      setComputedHeight(height - 32); // offset by 32px for 16px padding on top and bottom
    }
  }, [width, height]);

  return (
    <div id="iam-manage-lob-page">
      <WelcomeMessage />
      <FullPageContainer
        className={tabs[selectedTabIndex].text
          .toLowerCase()
          .split(' ')
          .join('-')}
      >
        {isMobile ? (
          <>
            <MdsSelect
              id="manage-lob-select"
              selectedIndex={selectedTabIndex}
              onValidate={(e) => setSelectedTabIndex(e.target.selectedIndex)}
              variant="box"
            >
              {tabs.map((tab) => (
                <MdsSelectOption
                  key={tab.text}
                  label={tab.text}
                  value={tab.text}
                />
              ))}
            </MdsSelect>
            {selectedTabIndex === 0 && <ManageLobTab />}
            {selectedTabIndex === 1 && <ManageLobMembersTab />}
            {selectedTabIndex === 2 && <ManageLobPartnersTab />}
          </>
        ) : (
          <MdsTabBar
            id="manage-lob-page-tab-bar"
            tabs={tabs}
            selectedTab={selectedTabIndex}
            onClickTab={(e) => setSelectedTabIndex(e.target.selectedTab)}
            ref={tabRef}
          >
            <div
              style={{ height: computedHeight }}
              slot="panel-0"
              className="panel"
            >
              <ManageLobTab />
            </div>
            <div
              style={{ height: computedHeight }}
              slot="panel-1"
              className="panel"
            >
              <ManageLobMembersTab />
            </div>
            <div
              style={{ height: computedHeight }}
              slot="panel-2"
              className="panel"
            >
              <ManageLobPartnersTab />
            </div>
          </MdsTabBar>
        )}
      </FullPageContainer>
    </div>
  );
};

export default ManageLobPage;
