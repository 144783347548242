import {
  MdsAlert,
  MdsButton,
  MdsDialog,
  MdsMultiselect,
  MdsMultiselectOption,
  MdsSearchBox,
  MdsSelect,
  MdsSelectOption,
  MdsSlideInPanel,
} from '@mds/react-es';
import { useContext, useState } from 'react';
import { GlobalStoreContext, IMember } from '../../../../../../Solutions';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import './add-lob-member-slide-in-stylesheet.scss';
import SuccessDialog from '../../../../../../../../global-components/SuccessDialog';
import alertSpot from '@mds/web-illustrations/dist/cmb/svgs/mds_ill_alert_featured_circle.svg';
import _ from 'lodash';
import {
  addNewBatchChaseMemberType,
  Worker,
} from '../../../../../../../../../utils/types/types';
import {
  addNewBatchChaseMember,
  getInternalWorkforceMember,
} from '../../../../../../../../../services/webClientAxiosCalls';
import { useGetLobs } from '../../../../../../../../../services/graphql-utils/useGraphQLMaps';
import {
  IAccount,
  IPartner,
  IRole,
  ISolution,
} from '../../../../../../../../../services/graphql-utils/dashtype';
import { useGetPersonaDash } from '../../../../../../../../../services';
import { useMutation } from '@tanstack/react-query';

const defaultValues = {
  /*
    Not mock data
  
    Default values for form
    This is used to reset the form to its original state
    Not used within useForm because we will run into issues using a form of indeterminate size

   */
  userId: '',
  lobId: '',
  subLobId: '',
  selectedRoleName: '',
  partnerIds: [],
};

interface SearchOption {
  primaryText: string;
  value: string;
  worker: Worker;
}

const AddLobMemberSlideIn = ({
  isOpen,
  onLobAddMemberSlideInOpen,
}: {
  isOpen: boolean;
  onLobAddMemberSlideInOpen: (isOpen: boolean) => void;
}) => {
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const { isMobile } = useContext(GlobalStoreContext);
  const [searchResults, setSearchResults] = useState<SearchOption[]>([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const methods = useForm({ mode: 'onChange' });
  const { lobArray } = useGetLobs();
  const {
    register,
    reset,
    setValue,
    control,
    formState,
    trigger,
    handleSubmit,
    clearErrors,
  } = methods;
  const { userProfile } = useContext(GlobalStoreContext);
  const { refetch } = useGetPersonaDash(userProfile.userPersona);
  const formData = useWatch({ control });
  const { isValid, errors } = formState;
  const { userId, lobId, subLobId, selectedRoleName, partnerIds } = formData;
  /* This selects from our graphQL mostly by using a find() or a filter()
    This is slowand can be improved by:
      - A more dynamic solution from our graphQL service
      - Use of hooks to process data -- ex: useGetThingById()
  */
  const selectedLob = lobArray.find((lob) => lob.lobId === lobId);
  const selectedSubLob = selectedLob?.lobApplications.find(
    (subLob) => subLob.lobApplicationId === subLobId,
  );
  const availableChaseRoles = selectedSubLob?.roles.filter(
    (role) => role.roleType === 'INTERNAL' && role.name != 'ChaseSuperAdmin',
  );

  const selectedPartners = selectedSubLob?.partners.filter((partner) =>
    partnerIds?.includes(partner.partnerId),
  );

  const partnersWithoutMemberAsRM = _.reject(
    selectedPartners,
    (
      partner, // reject is opposite of filter
    ) =>
      partner.accounts.some((account) =>
        account.solutions.some((solution) =>
          solution.members.some(
            (member) =>
              member.userId === userId &&
              member.roleName === 'RelationshipManager',
          ),
        ),
      ),
  );

  const checkExistingRelationshipManagerInPartner = (
    localPartnerIds: string[],
  ) => {
    /* this is messy but again we're working with mock data
      This function is used to validate if a user is already a RelationshipManager for a partner
      First -- checks to see if in the selectedPartners array, any memberIds match the userId from our select and if the role is RelationshipManager
        => output: partners for which the user is already a RelationshipManager
      Second -- creates a string of all the partners that the user is already a RelationshipManager for
        => output: string 
    */

    const currentPartners = selectedSubLob?.partners.filter((partner) =>
      localPartnerIds.includes(partner.partnerId),
    );

    const partnersWhereMemberIsRM = currentPartners?.filter(
      (partner: IPartner) =>
        partner.accounts.some((account: IAccount) =>
          account.solutions.some((solution: ISolution) =>
            solution.members.some(
              (member: IMember) =>
                member.userId === userId &&
                member.roleName === 'RelationshipManager',
            ),
          ),
        ),
    );

    const getRelationshipManager = (partner: IPartner, userId: string) => {
      return partner.accounts
        .flatMap((account: IAccount) => account.solutions)
        .flatMap((solution: ISolution) => solution.members)
        .find(
          (member: IMember) =>
            member.userId === userId &&
            member.roleName === 'RelationshipManager',
        );
    };
    const retString = partnersWhereMemberIsRM
      ?.map((errorPartner) => {
        const relationshipManager = getRelationshipManager(
          errorPartner,
          userId,
        );
        return relationshipManager
          ? `${relationshipManager.firstName} ${relationshipManager.lastName} is already a Relationship Manager for ${errorPartner.name}`
          : null;
      })
      .filter(Boolean) //to filter out null or undefined values in array
      .join(', ');

    return partnersWhereMemberIsRM?.length === 0 || retString;
  };

  const removePartnerById = (partnerId: string) => {
    setValue(
      'partnerIds',
      partnerIds?.filter((id: string) => id !== partnerId),
    );
    setValue(`accountIdsFor-${partnerId}`, ''); // clears the selected accounts for this partner
    trigger(); // trigger allows for validation to run after the value has been updated
  };

  const customReset = (formKeyArray: (keyof typeof defaultValues)[]) => {
    /* wrote a customReset function because using reset() 
     and passing defaultValues to our useForm will create type issues
     for form of indeterminate size/shape
     */
    formKeyArray.forEach((key) => {
      setValue(key, defaultValues[key as keyof typeof defaultValues]);
    });
  };

  const handleIsSuccessDialogOpen = (isOpen: boolean) => {
    setIsSuccessDialogOpen(isOpen);
  };

  const onSubmit = () => {
    const addMember = searchResults.find(
      (member) => member.worker.StandardId == formData.userId,
    )?.worker;

    const solutionIds: string[] = [];
    formData.partnerIds.map((partnerId: string) =>
      solutionIds.push(...formData[`accountIdsFor-${partnerId}-solutionIds`]),
    );

    const payload = {
      lob_application_id: formData.subLobId,
      solution_ids: solutionIds,
      role_id: (
        availableChaseRoles?.find(
          (role) => role.name == formData.selectedRoleName,
        ) as IRole
      ).roleId,
      permissions: [],
      user_ids: [
        {
          user_id: addMember!.StandardId,
          first_name: addMember!.Name.First,
          last_name: addMember!.Name.Last,
          email_address: addMember!.ContactInfo.WorkEmail,
        },
      ],
    };
    addNewBatchChaseMemberMutation.mutate(payload);
  };

  const addNewBatchChaseMemberMutation = useMutation({
    mutationFn: (payload: addNewBatchChaseMemberType) =>
      addNewBatchChaseMember(payload),
    onSuccess: () => {
      refetch();
      onLobAddMemberSlideInOpen(false);
      handleIsSuccessDialogOpen(true);
      setSearchResults([]);
      setSearchInputValue('');
      reset();
    },
  });

  const handleSearch = (searchText: string) => {
    const findWorker = searchResults.filter((uniqueWorker) =>
      uniqueWorker.worker.StandardId.toLowerCase().includes(
        searchText.toLowerCase(),
      ),
    );

    if (!findWorker?.length && searchText.length === 7) {
      getInternalWorkforceMember(searchText)
        .then((res) => {
          const workerList: SearchOption[] = [];
          res.Workers.Worker.map((worker: Worker) =>
            workerList.push({
              primaryText: `${worker.StandardId} - ${worker.Name.First} ${worker.Name.Last}`,
              value: worker.StandardId,
              worker: worker,
            }),
          );
          setSearchResults([...searchResults, ...workerList]);
        })
        .catch((e) => console.error(JSON.stringify(e)));
    }
  };

  return (
    <>
      <MdsSlideInPanel
        titleText="Add Member"
        open={isOpen}
        onClose={() => {
          onLobAddMemberSlideInOpen(false);
          setSearchResults([]);
          setSearchInputValue('');
          reset();
        }}
        widthType="small"
        hideHeader={isMobile}
      >
        <FormProvider {...methods}>
          <form
            className="add-lob-member-slide-in"
            slot="panelContent"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-inputs">
              <MdsSearchBox
                label="Add Chase member"
                labelStyle="vertical"
                placeholder="Search by SID"
                searchMode="simple"
                showQueryIfNoResults
                variant="box"
                options={searchResults}
                noResultsText="Searching results for"
                onInput={(e) => handleSearch(e.target.inputValue)}
                {...register('userId', { required: true })}
                onSelection={(e) => {
                  setSearchInputValue(e.target.inputValue);
                  customReset(['lobId']);
                  clearErrors('partnerIds');
                }}
                inputValue={searchInputValue}
              />
              <MdsSelect
                label="LOB"
                variant="box"
                placeholder="Select"
                value={lobId}
                {...register('lobId', { required: true })}
                onValidate={() => customReset(['subLobId', 'selectedRoleName'])}
              >
                {lobArray.map((lob) => (
                  <MdsSelectOption
                    key={lob.lobId}
                    value={lob.lobId}
                    label={lob.lobName}
                  />
                ))}
              </MdsSelect>
              <MdsSelect
                label="Sub-LOB"
                variant="box"
                placeholder="Select"
                inactive={!selectedLob}
                value={subLobId}
                {...register('subLobId', { required: true })}
              >
                {selectedLob?.lobApplications.map((subLob) => (
                  <MdsSelectOption
                    key={subLob.lobApplicationId}
                    label={subLob.applicationName}
                    value={subLob.lobApplicationId}
                  />
                ))}
              </MdsSelect>
              <MdsSelect
                label="Select Chase role"
                variant="box"
                placeholder="Select"
                value={selectedRoleName}
                inactive={!availableChaseRoles}
                {...register('selectedRoleName', { required: true })}
                onValidate={() => customReset(['partnerIds'])}
              >
                {availableChaseRoles?.map((role) => (
                  <MdsSelectOption
                    key={role.roleId}
                    value={role.name}
                    label={role.name}
                  />
                ))}
              </MdsSelect>

              {/* if User selects RelationshipManager */}
              {selectedRoleName === 'RelationshipManager' && (
                <>
                  <MdsMultiselect
                    label="Partner"
                    variant="box"
                    placeholder="Select partner(s)"
                    value={JSON.stringify(formData.partnerIds)}
                    {...register('partnerIds', {
                      required: true,
                      validate: (value) =>
                        checkExistingRelationshipManagerInPartner(value),
                    })}
                  >
                    {selectedSubLob?.partners.map((partner) => (
                      <MdsMultiselectOption
                        key={partner.partnerId}
                        selected={partnerIds?.includes(partner.partnerId)}
                        value={partner.partnerId}
                        label={partner.name}
                      />
                    ))}
                  </MdsMultiselect>

                  {errors?.partnerIds?.message && (
                    // errorMessages are returned as a string separated by commas
                    // we cannot keep them as an array as per
                    <>
                      {(errors?.partnerIds?.message as string)
                        ?.split(',')
                        .map((errorMsg) => (
                          <MdsAlert
                            key={errorMsg}
                            alertTitle={errorMsg}
                            size="mini"
                            variant="error"
                            fullWidth
                          />
                        ))}
                    </>
                  )}

                  {partnersWithoutMemberAsRM && (
                    <div className="configure-selected-partners">
                      {partnersWithoutMemberAsRM.map((partner: IPartner) => {
                        const indexForAccountIds = `accountIdsFor-${partner.partnerId}`; // used to easily create setting/getting for selects in this map
                        const selectedAccountIds = formData[indexForAccountIds]; // get the selected accountIds for this partner
                        const availableSolutions = partner.accounts // gets all solutions for selected accounts
                          .filter((account: IAccount) =>
                            selectedAccountIds?.includes(account?.accountId),
                          )
                          .map(
                            (entireAccount: IAccount) =>
                              entireAccount.solutions,
                          )
                          .flat();

                        return (
                          <>
                            <div className="configure-top">
                              <span>
                                Configure <strong>{partner.name}</strong>
                              </span>
                              <MdsButton
                                iconType="ico_trash_can"
                                iconPosition="icon_only"
                                variant="tertiary"
                                onClick={() =>
                                  removePartnerById(partner.partnerId)
                                }
                              />
                            </div>
                            <div className="configure-inputs">
                              <MdsMultiselect
                                label="Accounts"
                                placeholder="Select accounts"
                                variant="box"
                                {...register(`${indexForAccountIds}`, {
                                  required: true,
                                })}
                              >
                                {partner.accounts.map((account: IAccount) => (
                                  <MdsMultiselectOption
                                    key={account.accountId}
                                    value={account.accountId}
                                    label={account.name}
                                  />
                                ))}
                              </MdsMultiselect>
                              <MdsMultiselect
                                label="Solutions"
                                variant="box"
                                placeholder="Select solutions"
                                {...register(
                                  `${indexForAccountIds}-solutionIds`,
                                  {
                                    required: true,
                                  },
                                )}
                                inactive={(!selectedAccountIds?.length).toString()} // is inactive if no accounts are selected
                              >
                                {availableSolutions.map(
                                  (solution: ISolution) => (
                                    <MdsMultiselectOption
                                      key={solution.solutionId}
                                      value={solution.solutionId}
                                      label={solution.name}
                                    />
                                  ),
                                )}
                              </MdsMultiselect>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="bottom-button-group">
              <MdsButton
                variant="secondary"
                id="cancel-button"
                text="Cancel"
                onClick={() => {
                  setIsConfirmationDialogOpen(true);
                }}
                widthType={isMobile ? 'layout' : 'content'}
              />
              <MdsButton
                variant="primary"
                text="Add member"
                inactive={!isValid}
                widthType={isMobile ? 'layout' : 'content'}
                onClick={onSubmit}
              />
            </div>
          </form>
        </FormProvider>
      </MdsSlideInPanel>

      {/* Success dialog */}
      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        onDialogOpen={handleIsSuccessDialogOpen}
        successMessage="You've successfully added this member."
      />

      {/* Confirmation dialog */}
      <MdsDialog
        open={isConfirmationDialogOpen}
        dialogWidth={619}
        dialogHeight={isMobile ? undefined : 366}
        headerText="Are you sure?"
        dialogHeaderIconName="ico_alert_filled"
        primaryButtonText="Go back"
        onClickButton1={() => {
          setIsConfirmationDialogOpen(false);
          onLobAddMemberSlideInOpen(true);
        }}
        secondaryButtonOneText="Cancel"
        onClickButton2={() => {
          setIsConfirmationDialogOpen(false);
          onLobAddMemberSlideInOpen(false);
          reset();
          setSearchResults([]);
          setSearchInputValue('');
        }}
      >
        <div slot="dialogContent" id="cancel-profile-dialog-content">
          <img src={alertSpot} alt="Checkbox" width={130} height={130} />
          {`If you cancel now, your changes won't be saved.`}
        </div>
      </MdsDialog>
    </>
  );
};

export default AddLobMemberSlideIn;
