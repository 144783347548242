import {
  MdsButton,
  MdsDialog,
  MdsIcon,
  MdsSlideInPanel,
  MdsTextInput,
} from '@mds/react-es';
import { useContext, useState } from 'react';
import {
  GlobalStoreContext,
  useGetPersonaDash,
} from '../../../../../../../../../services/graphql-utils';
import EmptyTableMessage from '../../../../../../../../global-components/EmptyTableMessage/EmptyTableMessage';
import { useForm, useWatch } from 'react-hook-form';
import './lob-accordion.scss';
import { useLocation } from 'react-router-dom';
import SubLobIndexMobile from './components/SubLobIndexMobile';
import SubLobTableDesktop from './components/SubLobTableDesktop';
import DescriptionPair from '../../../../../../../../global-components/DescriptionPair';
import { parseDate } from '../../../../../../../../../utils/time/dateConversion';
import SuccessDialog from '../../../../../../../../global-components/SuccessDialog';
import checkboxSpot from '@mds/web-illustrations/dist/cmb/svgs/mds_ill_checkbox_featured.svg';
import {
  ILob,
  ILobApplication,
} from '../../../../../../../../../services/graphql-utils/dashtype';
import { useMutation } from '@tanstack/react-query';
import { AddSubLobFormData } from '../../../../../../../../../utils/types/dashboardTypes';
import { addSubLob } from '../../../../../../../../../services/webClientAxiosCalls';

const LobAccordion = ({ lob }: { lob: ILob }) => {
  const location = useLocation();
  const isVisitingThisLob = location.state?.arrivingFromLobId === lob.lobId; // keeps track of the lob that ManageProfilesTab is sending us to

  const [isRowOpen, setIsRowOpen] = useState(isVisitingThisLob); // if we're being sent from ManageProfilesTab, open the row else false
  const [isAddSubLobDialogOpen, setIsAddSubLobDialogOpen] =
    useState(isVisitingThisLob);
  const [isAddSubLobSuccessDialogOpen, setIsAddSubLobSuccessDialogOpen] =
    useState(false);
  const [isSubLobNameError, setIsSubLobNameError] = useState(false);

  const [isDetailsSlideInOpen, setIsDetailsSlideInOpen] = useState(false);
  const { userProfile } = useContext(GlobalStoreContext);
  const { refetch } = useGetPersonaDash(userProfile.userPersona);

  const {
    lobName,
    lobApplications,
    lobLastUpdated,
    lobAddedOn,
    lobDescription,
  } = lob;

  const toggleRow = () => {
    setIsRowOpen(!isRowOpen);
  };

  const handleAddSubLobSuccessDialog = (isOpen: boolean) => {
    setIsAddSubLobSuccessDialogOpen(isOpen);
  };

  const {
    register: registerAddSubLob,
    formState: { isValid: isValidAddSubLob },
    control: controlAddSubLob,
    reset: resetAddSubLob,
    handleSubmit: handleSubmitAddSubLob,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
    },
    mode: 'onChange',
  });

  const formDataAddSubLob = useWatch({ control: controlAddSubLob });
  const { isMobile } = useContext(GlobalStoreContext);

  const getIsSubLobNameError = (currentName: string | undefined) => {
    if (!currentName) {
      return false;
    }
    return lobApplications.some(
      (subLob: ILobApplication) =>
        subLob.applicationName.toLowerCase().trim() ===
        currentName?.toLowerCase()?.trim(),
    );
  };

  const addNewSubLobMutation = useMutation({
    mutationFn: (payload: { lobId: string; data: AddSubLobFormData }) =>
      addSubLob(payload.lobId, payload.data),
    onSuccess: () => {
      refetch();
      setIsAddSubLobDialogOpen(false);
      handleAddSubLobSuccessDialog(true);
      resetAddSubLob();
    },
    onError: (error) => console.log('Error adding lob'), // TODO: add error dialog
  });

  const onAddSubLobSubmit = () => {
    const payload = {
      application_name: formDataAddSubLob.name!,
      description: formDataAddSubLob.description!,
    };

    addNewSubLobMutation.mutate({ lobId: lob.lobId, data: payload });
  };

  return (
    <div className={`sub-lob-accordion-item ${isRowOpen ? 'open' : 'closed'}`}>
      <div className="accordion-container">
        <div
          className={`accordion-title-items ${isRowOpen ? 'open' : 'closed'}`}
        >
          <button className="mds-styled-button" onClick={toggleRow}>
            <MdsIcon
              id={`dropdown-icon-${lobName.replace(/\s/g, '').toLowerCase()}`}
              type={isRowOpen ? 'ico_caret_down' : 'ico_caret_right'}
              size={16}
              color="interactive"
              removeHorizontalMargin
            />
            <div className="lob-name">{lobName}</div>
          </button>
        </div>
        {isRowOpen && (
          <div className="super-admin-breakout-link">
            <MdsButton
              text="Details"
              iconType="ico_clock_expiring"
              widthType={isMobile ? 'layout' : 'content'}
              variant={isMobile ? 'secondary' : 'tertiary'}
              onClick={() => setIsDetailsSlideInOpen(true)}
            />
            <MdsButton
              text="Add sub-LOB"
              iconType="ico_add_circle"
              widthType={isMobile ? 'layout' : 'content'}
              variant={isMobile ? 'secondary' : 'tertiary'}
              onClick={() => setIsAddSubLobDialogOpen(true)}
            />
            {/* <MdsSwitch
              checked
              stateLabelOn="Deactivate LOB"
              stateLabelOff="Activate LOB"
            /> */}
          </div>
        )}
      </div>
      {isRowOpen && (
        <div className="accordion-item-container">
          {lobApplications.length === 0 && (
            <EmptyTableMessage
              buttonText="Add sub-LOB"
              message="You haven't added any sub-LOBs yet."
              onClickButton={() => setIsAddSubLobDialogOpen(true)}
            />
          )}
          {lobApplications.length > 0 && (
            <>
              {isMobile && <SubLobIndexMobile subLobs={lobApplications} />}
              {!isMobile && <SubLobTableDesktop subLobs={lobApplications} />}
            </>
          )}
          <>
            {/* DetailsSlideIn*/}
            <>
              <MdsSlideInPanel
                open={isDetailsSlideInOpen}
                onClose={() => setIsDetailsSlideInOpen(false)}
                widthType="small"
                titleText="Details"
              >
                <div slot="panelContent" className="details-slide-in">
                  <div className="overview">
                    <h3>Overview</h3>
                    <div className="overview-content">
                      {/*
                      <DescriptionPair
                        term="Added by"
                        value={`${addedBy.userId} - ${addedBy.firstName} ${addedBy.lastName}`}
                        itemLayout="horizontal"
                        boldedTerm
                      />
                      */}
                      <DescriptionPair
                        term="Added on"
                        value={parseDate(lobAddedOn)}
                        itemLayout="horizontal"
                        boldedTerm
                      />
                      <DescriptionPair
                        term="Updated on"
                        className="span-columns"
                        value={parseDate(lobLastUpdated)}
                        itemLayout="horizontal"
                        boldedTerm
                      />
                      <DescriptionPair
                        term="Description"
                        className="span-columns"
                        value={
                          <div className="description-editable">
                            {lobDescription}
                            {/* <button className="mds-styled-button icon-button">
                              <MdsIcon
                                type="ico_pencil"
                                removeHorizontalMargin
                              />
                            </button> */}
                          </div>
                        }
                        itemLayout="horizontal"
                        boldedTerm
                      />
                    </div>
                    {/*
                    <div className="audit-history">
                      <h3>Audit history</h3>
                      <ul className="audit-history-ul">
                        {history.map(
                          (
                            history: any,
                            // TODO: any_typing
                          ) => (
                            <li key={history.id}>
                              {parseDate(history.dateAdded)} |{' '}
                              <strong>{history.performedBy}</strong>{' '}
                              {history.action.toLowerCase()}{' '}
                              {history.historyType} {history.newValue}
                            </li>
                          ),
                        )}
                      </ul>
                    </div>
                    */}
                  </div>
                </div>
              </MdsSlideInPanel>
            </>

            {/* AddSubLobDialog*/}
            <>
              <MdsDialog
                open={isAddSubLobDialogOpen}
                onClose={() => setIsAddSubLobDialogOpen(false)}
                headerText="Add sub-LOB"
                dialogWidth={588}
              >
                <div
                  slot="dialogContent"
                  id="add-lob-dialog"
                  className="add-sub-lob-dialog"
                >
                  <form onSubmit={handleSubmitAddSubLob(onAddSubLobSubmit)}>
                    {isVisitingThisLob && (
                      <DescriptionPair
                        term="LOB"
                        value={lobName}
                        boldedTerm
                        itemLayout="vertical"
                      />
                    )}
                    <MdsTextInput
                      label="Sub-LOB name"
                      variant="box"
                      value={formDataAddSubLob.name}
                      errorMessage={
                        isSubLobNameError
                          ? 'This sub-LOB has already been added.'
                          : ''
                      }
                      onInput={(e) =>
                        setIsSubLobNameError(
                          getIsSubLobNameError(e.target.value),
                        )
                      }
                      {...registerAddSubLob('name', {
                        required: true,
                      })}
                    />
                    <MdsTextInput
                      label="Description"
                      variant="box"
                      multiline
                      value={formDataAddSubLob.description}
                      {...registerAddSubLob('description', {
                        required: true,
                      })}
                    />

                    <div className="bottom-button-group">
                      <MdsButton
                        variant="secondary"
                        text="Cancel"
                        onClick={() => {
                          setIsAddSubLobDialogOpen(false);
                          resetAddSubLob();
                        }}
                        widthType={isMobile ? 'layout' : 'content'}
                      />
                      <MdsButton
                        variant="primary"
                        text="Add sub-LOB"
                        inactive={!isValidAddSubLob || isSubLobNameError}
                        widthType={isMobile ? 'layout' : 'content'}
                        onClick={onAddSubLobSubmit}
                      />
                    </div>
                  </form>
                </div>
              </MdsDialog>
              <SuccessDialog
                isDialogOpen={isAddSubLobSuccessDialogOpen}
                successMessage={
                  <>
                    {`You've successfully added a new `}
                    <strong>sub-LOB.</strong>
                  </>
                }
                onDialogOpen={handleAddSubLobSuccessDialog}
                spotIcon={checkboxSpot}
              />
            </>
          </>
        </div>
      )}
    </div>
  );
};

export default LobAccordion;
