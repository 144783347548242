import { gql } from '@apollo/client';

export const profilesQuery = gql`
  query {
    profilesQuery {
      profileId
      name
      profileType
      entitlementName
      parentProfileId
      createdAt
      lastUpdated
      permissions {
        profileId
        featurePermissionId
      }
    }
  }
`;
