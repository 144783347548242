import { useEffect } from 'react';
import errorImage from '../../_images/illustration-unplug.png';
import error from './error-content.json';
import getEnvironment from '../../../../../utils/getEnvironment';
import endpoints from '../../_data/endpoints';
import './registration-error-page-stylesheet.scss';

const RegistrationErrorPage = (props: { page: string }) => {
  const env = getEnvironment();
  const page = props.page;
  let error_msg = '';
  let redirectTo: string[] = [];
  let link: string[] = [];

  switch (page) {
    case 'Sign in':
      error_msg = error.LOGIN_ERROR_MSG;
      redirectTo = [error.SIGN_IN_PAGE];
      link = [endpoints[env].login];
      break;
    case 'Create an account':
      error_msg = error.OTP_ERROR_MSG;
      redirectTo = [error.REGISTRATION_PAGE];
      link = [endpoints[env].registration];
      break;
    default:
      error_msg = error.GENERIC_ERROR_MSG;
      redirectTo = [
        error.HOME_PAGE,
        error.REGISTRATION_PAGE,
        error.SIGN_IN_PAGE,
      ];
      link = [
        endpoints[env].homePage,
        endpoints[env].registration,
        endpoints[env].login,
      ];
      break;
  }

  useEffect(() => {
    document.title = 'Error | Chase Developer';
  }, []);

  return (
    <main id="error-container" data-testid="error-component">
      <div className="error-content">
        <div className="error-div">
          <div>
            <h1 className="error-h1" data-testid="error-h1">
              Oops something went wrong.
            </h1>
            <div className="error-p" data-testid="error-p1">
              {error_msg}
            </div>
            <div className="error-link">
              <ul>
                {redirectTo.map((item, index) => {
                  return (
                    <li key={index}>
                      <a id="error-link-a" href={link[index]}>
                        {item}
                      </a>{' '}
                      <span>|</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="error-illustration">
        <img className="hero-img" src={errorImage} alt="Confused man" />
      </div>
    </main>
  );
};

export default RegistrationErrorPage;
