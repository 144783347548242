import {
  MdsButton,
  MdsDialog,
  MdsIcon,
  MdsSlideInPanel,
  MdsSwitch,
  MdsTextInput,
} from '@mds/react-es';
import { ApixCustomAccordion } from '../../../../../../global-components/ApixCustomAccordion/ApixCustomAccordion';
import EntityStatus from '../../../../../../global-components/EntityStatus/EntityStatus';
import { useContext, useState } from 'react';
import { GlobalStoreContext } from '../../../../Solutions';
import { useForm, useWatch } from 'react-hook-form';
import './single-feature-stylesheet.scss';
import _ from 'lodash';
import alertSpot from '@mds/web-illustrations/dist/cmb/svgs/mds_ill_alert_featured_circle.svg';
import { IFeature } from '../../../../../../../utils';
import { IPermission, IProfile } from '../../../../../../../utils/hooks';
import { useMutation } from '@tanstack/react-query';
import { updateProfilePermissions } from '../../../../../../../services/webClientAxiosCalls';

interface UpdateProfilePermissionsType {
  profileId: string;
  featureId: string;
  permissions_to_add: string[];
  permissions_to_remove: string[];
}

const getDefaultValues = (feature: IFeature, profile?: IProfile) => {
  /*
    Creates object of defaultValues. necessary for checking dirtyFields. 
    In the future, this kind of mapping may be better served closer to our graphQL call 
    or perhaps as a utility function in a separate file if we cannot get a more dynamic solution from our REST service
   */
  const defaultValues: Record<
    string,
    | { status: 'ACTIVE' | 'INACTIVE'; actionName: string; displayName: string }
    | string
  > = {
    reason: '',
  };

  feature.permissions.forEach((featurePermission: IPermission) => {
    defaultValues[featurePermission.featurePermissionId] = {
      status: profile?.permissions.some(
        (profilePermission) =>
          profilePermission.featurePermissionId ===
          featurePermission.featurePermissionId,
      )
        ? 'ACTIVE'
        : 'INACTIVE',
      actionName: featurePermission.actionName!,
      displayName: featurePermission.displayName!,
    };
  });

  return { defaultValues };
};

const ViewAndManageFeature = ({
  feature,
  profile,
  handleSuccessBanner,
}: {
  feature: IFeature;
  profile?: IProfile;
  handleSuccessBanner: (isOpen: boolean) => void;
}) => {
  /* 
  The purpose of this component is to view/manage individual features 
  
  Some notes:
      - There is a dirtyFields/isDirty check from react-hook-form that is not used in the code,
        because it doesn't quite handle deep object comparisons or MdsComponents correctly (without the use of Controller). 
        Instead, we have a betterDirtyFields comparison to manage user changes. 
      - handleConfirm is a placeholder function for the API call that will be made to update the feature status
      - Resetting the form is done after any close of form through react-hook-form.reset()

  */
  const { defaultValues } = getDefaultValues(feature, profile);
  const [isSlideOutOpen, setIsSlideOutOpen] = useState(false);
  const [isEditAreYouSureDialogOpen, setIsEditAreYouSureDialogOpen] =
    useState(false);
  const [showReasonError, setShowReasonError] = useState(false);
  const { isMobile } = useContext(GlobalStoreContext);
  const { register, reset, setValue, control } = useForm({
    defaultValues: defaultValues,
  });
  const formData = useWatch({ control });
  const betterDirtyFields = _.pickBy(
    formData,
    (value, key) => !_.isEqual(value, defaultValues[key]),
  );

  const updateProfilePermissionsMutation = useMutation({
    mutationFn: (payload: UpdateProfilePermissionsType) =>
      updateProfilePermissions(
        payload.profileId,
        payload.featureId,
        payload.permissions_to_add,
        payload.permissions_to_remove,
      ),
    onSuccess: () => {
      setIsSlideOutOpen(false);
      setIsEditAreYouSureDialogOpen(false);
      handleSuccessBanner(true);
      reset(defaultValues);
    },
    // TODO: add generic error dialog on error
  });

  const handleConfirm = () => {
    if (formData.reason) {
      setShowReasonError(false);

      const permissionsToAdd = Object.entries(betterDirtyFields)
        .filter(
          ([, value]) => (value as { status: string }).status === 'ACTIVE',
        )
        .map(([, value]) => (value as { actionName: string }).actionName);

      const permissionsToRemove = Object.entries(betterDirtyFields)
        .filter(
          ([, value]) => (value as { status: string }).status === 'INACTIVE',
        )
        .map(([, value]) => (value as { actionName: string }).actionName);

      const payload = {
        profileId: profile!.profileId,
        featureId: feature.featureId,
        permissions_to_add: permissionsToAdd,
        permissions_to_remove: permissionsToRemove,
      };

      updateProfilePermissionsMutation.mutate(payload);
    } else {
      setShowReasonError(true);
    }
  };

  /*
    Current layout => 
    <>

      <ApixCustomAcordion rightHandNode={<SlideInButton/>}>
        {subFeature.map(subFeature => <li>{subFeature.name}</li>)}
      </ApixCustomAcordion>
      
      // Slide in Panel
      <SlideInPanel/>
      
      // Are you sure dialog
      <AreYouSureDialog/>
    </>

   */
  return (
    <>
      <ApixCustomAccordion
        name={feature.featureName}
        key={feature.featureId}
        entityName="partner"
        showDropdownIcon
        isDefaultOpen
        status="other"
        customRightHandNode={
          <>
            {profile!.profileType === 'CUSTOM' && (
              <button
                className="mds-styled-button"
                onClick={() => {
                  setIsSlideOutOpen(!isSlideOutOpen);
                  reset(defaultValues);
                }}
              >
                <MdsIcon type="ico_pencil" size={16} removeHorizontalMargin />
              </button>
            )}
          </>
        }
      >
        <>
          <ul className={'view-permission-list'}>
            {feature.permissions.map((featurePermission: IPermission) => (
              <li key={featurePermission.featurePermissionId}>
                {featurePermission.displayName}
                <EntityStatus
                  status={
                    profile?.permissions.some(
                      (profilePermission) =>
                        profilePermission.featurePermissionId ===
                        featurePermission.featurePermissionId,
                    )
                      ? 'ACTIVE'
                      : 'INACTIVE'
                  }
                  entityName="feature"
                />
              </li>
            ))}
          </ul>
        </>
      </ApixCustomAccordion>

      {/* Slide in Panel */}
      <MdsSlideInPanel
        titleText="Manage features"
        open={isSlideOutOpen}
        hideHeader={isMobile}
        widthType="small"
        onClose={() => setIsSlideOutOpen(false)}
      >
        <div className="manage-feature-slide-out" slot="panelContent">
          {isMobile && <h2>Manage features</h2>}
          <div className="features-container">
            <div className="feature-switch-group">
              <strong>{feature.featureName}</strong>
            </div>
            <ul>
              {feature.permissions.map((featurePermission: IPermission) => {
                return (
                  <li
                    key={featurePermission.featurePermissionId}
                    className="feature-switch-group"
                  >
                    {featurePermission.displayName}
                    <MdsSwitch
                      stateLabelOn="Active"
                      stateLabelOff="Inactive"
                      checked={
                        (
                          formData[featurePermission.featurePermissionId] as {
                            status: 'ACTIVE' | 'INACTIVE';
                            actionName: string;
                            displayName: string;
                          }
                        )?.status === 'ACTIVE'
                      }
                      onChange={(e) => {
                        setValue(featurePermission.featurePermissionId, {
                          status: e.target.checked ? 'ACTIVE' : 'INACTIVE',
                          actionName: featurePermission.actionName!,
                          displayName: featurePermission.displayName!,
                        });
                      }}
                      {...(register(featurePermission.featurePermissionId),
                      { required: false })}
                    />
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="bottom-button-group">
            <MdsButton
              variant="secondary"
              text="Cancel"
              widthType={isMobile ? 'layout' : 'content'}
              onClick={() => {
                setIsSlideOutOpen(false);
                reset(defaultValues);
              }}
            />
            <MdsButton
              variant="primary"
              text="Update"
              inactive={_.isEqual(formData, defaultValues)} //using isEqual instead of is dirty because consecutive switch clicks register
              widthType={isMobile ? 'layout' : 'content'}
              onClick={() => {
                setIsEditAreYouSureDialogOpen(true);
              }}
            />
          </div>
        </div>
      </MdsSlideInPanel>

      {/* Are you sure dialog */}
      <MdsDialog
        headerText={'Alert!'}
        dialogHeaderIconName="ico_alert_filled"
        dialogWidth={619}
        primaryButtonText="Confirm"
        secondaryButtonOneText="Cancel"
        open={isEditAreYouSureDialogOpen}
        onClickButton1={handleConfirm}
        onClickButton2={() => {
          setIsEditAreYouSureDialogOpen(false);
          reset();
        }}
      >
        <div
          slot="dialogContent"
          id="edit-profile-confirm-dialog"
          className="tickets-dialog-content"
        >
          <img src={alertSpot} alt={'Alert icon'} width={130} height={130} />
          <span>
            {`You're about to update access to features for this `}
            <strong>profile</strong>.
          </span>
          <ul>
            {Object.entries(betterDirtyFields).map(([key, value]) => {
              if (key === 'reason') return null; // Excludes business justification reason from our list
              const { status, displayName } = value as {
                displayName: string;
                status: 'ACTIVE' | 'INACTIVE';
              };
              return (
                <li key={`dirtyField-${key}`}>
                  <span>{displayName}</span>
                  <span>
                    {status === 'ACTIVE' ? 'Activated' : 'Deactivated'}
                  </span>
                </li>
              );
              // }
            })}
          </ul>
          <MdsTextInput
            id={`update-reason`}
            label="Business justification"
            variant="box"
            multiline
            defaultHeight="102px"
            errorMessage={
              showReasonError
                ? 'Business justification is required.'
                : undefined
            }
            value={formData.reason as string}
            {...register('reason', { required: true })}
          />
        </div>
      </MdsDialog>
    </>
  );
};

export default ViewAndManageFeature;
