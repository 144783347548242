import { MdsDialog } from '@mds/react-es';
import maintentanceSpot from '@mds/web-illustrations/dist/cmb/svgs/mds_ill_maintenance_featured_circle.svg';
import './dialog.scss';
import { ReactNode } from 'react';

const SuccessDialog = ({
  isDialogOpen,
  onDialogOpen,
  successMessage,
  spotIcon = maintentanceSpot,
  className,
}: {
  isDialogOpen: boolean;
  onDialogOpen: (isOpen: boolean) => void;
  successMessage: string | ReactNode;
  spotIcon?: any;
  className?: string;
}) => {
  return (
    <>
      <MdsDialog
        id="success-dialog"
        headerText="Success!"
        dialogWidth={619}
        dialogHeaderIconName="ico_checkmark_filled"
        primaryButtonText="Ok"
        open={isDialogOpen}
        onClose={() => onDialogOpen(false)}
        onClickButton1={() => {
          onDialogOpen(false);
        }}
      >
        <div
          slot="dialogContent"
          id="success-dialog-content"
          className={className}
        >
          <img src={spotIcon} alt="Spot icon" width={130} height={130} />
          <span>{successMessage}</span>
        </div>
      </MdsDialog>
    </>
  );
};

export default SuccessDialog;
