import axios from 'axios';
import {
  StatusType,
  AddMemberFormData,
  AddSubLobFormData,
  transformPartnerAccountData,
  AuditRequest,
  ActionType,
  ContactUsType,
  ICreateTicketFormData,
  ITicketCommentRequest,
  IUpdateTicketStateRequest,
  ITicketingUpdateEmail,
  IDownloadAttachmentRequest,
  ICreateUserGroupRequest,
  ICreateSupportGroupRule,
  IDeleteTicketAttachmentRequest,
  GlobalStoreContext,
  useGetPersonaDash,
} from './';
import { useMutation } from '@tanstack/react-query';
import {
  addNewBatchChaseMemberType,
  addNewChaseMemberType,
} from '../utils/types/types';
import { useContext } from 'react';
import { IApis } from './graphql-utils';

export interface NewAccountType {
  name: string;
  description: string;
  lob_application_name: string;
}

export interface NewSolutionType {
  name: string;
  description: string;
  max_user_count?: number;
}
export const axiosInstance = axios.create({
  data: {
    credentials: 'include',
    cache: 'no-store',
  },
});

const origin =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8080'
    : `${window.location.origin}`;

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Want to return response as-is
    return response;
  },
  function (error) {
    return Promise.reject(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // commmenting out redirect on 403 until we resolve issues
    /*
      if (error.response.status === 403) {
        console.log('Access denied. Redirecting to Access Denied page ...');
        window.location.replace('/error/access-denied');
        return error;
      } else {
        return error;
      }
      */
  },
);

const updatePartnerAdminLastLogin = async () => {
  try {
    const res = await axiosInstance.put(`${origin}/partners/api/v1/users`);
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const updateUserByUserId = async ({
  userId,
  reqBody,
}: {
  userId: string;
  reqBody: any;
}) => {
  try {
    const res = await axiosInstance.put(
      `${origin}/partners/api/v1/user/${userId}`,
      reqBody,
    );
    return res;
  } catch (e) {
    console.error(e);
  }
};

const getPartnersAndAccounts = async (pageSize = 1000, pageNumber = 1) => {
  try {
    const res = await axiosInstance.get(`${origin}/partners/api/v1/partners`, {
      params: {
        pageSize: pageSize,
        pageNumber: pageNumber,
      },
    });
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getAccountsAndSolutions = async () => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/partner-accounts/`,
    );
    return transformPartnerAccountData(res.data);
  } catch (e) {
    console.error(e);
  }
};

const getPartnerById = async (partnerId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/partners/${partnerId}`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getUsersByPartnerId = async (partnerId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/partners/${partnerId}/users`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getUsersByAccountId = async (accountId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/accounts/${accountId}/users`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getUsersBySolutionId = async (solutionId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/solutions/${solutionId}/users`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getUsersBySolutionEnvironmentId = async (
  solutionEnvironmentId: string,
) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/solution-environments/${solutionEnvironmentId}/users`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getUsersBySolutionEnvironmentIdForApi = async (
  solutionEnvironmentId: string,
  apiId: string,
) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/solution-environments/${solutionEnvironmentId}/api/${apiId}/users`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const updatePartnerStatusById = async ({
  partnerId,
  reqBody,
}: {
  partnerId: string;
  reqBody: StatusType;
}) => {
  try {
    const res = await axiosInstance.put(
      `${origin}/partners/api/v1/partners/${partnerId}`,
      reqBody,
    );
    return res;
  } catch (e) {
    console.error(e);
  }
};

const updateAccountStatusById = async ({
  accountId,
  reqBody,
}: {
  accountId: string;
  reqBody: StatusType;
}) => {
  try {
    const res = await axiosInstance.put(
      `${origin}/partners/api/v1/accounts/${accountId}`,
      reqBody,
    );
    return res;
  } catch (e) {
    console.error(e);
  }
};

const updateSolutionStatusById = async ({
  solutionId,
  reqBody,
}: {
  solutionId: string;
  reqBody: StatusType;
}) => {
  try {
    const res = await axiosInstance.put(
      `${origin}/partners/api/v1/solutions/${solutionId}`,
      reqBody,
    );
    return res;
  } catch (e) {
    console.error(e);
  }
};

const getSolutionInfoById = async (solutionId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/solutions/${solutionId}/info`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const updateMemberBySolutionId = async ({
  solutionId,
  reqBody,
}: {
  solutionId: string;
  reqBody: any;
}) => {
  try {
    const res = await axiosInstance.put(
      `${origin}/partners/api/v1/solutions/${solutionId}/members`,
      reqBody,
    );
    return res;
  } catch (e) {
    console.error(e);
  }
};

const updateSolutionEnvironmentStatusById = async ({
  solEnvId,
  reqBody,
}: {
  solEnvId: string;
  reqBody: StatusType;
}) => {
  try {
    const res = await axiosInstance.put(
      `${origin}/partners/api/v1/solution-environments/${solEnvId}`,
      reqBody,
    );
    return res;
  } catch (e) {
    console.error(e);
  }
};

const updateApiStatusById = async ({
  solutionEnvironmentId,
  apiId,
  reqBody,
}: {
  apiId: string;
  solutionEnvironmentId: string;
  reqBody: StatusType;
}) => {
  try {
    const res = await axiosInstance.put(
      `${origin}/partners/api/v1/environments/${solutionEnvironmentId}/apis/${apiId}`,
      reqBody,
    );
    return res;
  } catch (e) {
    console.error(e);
  }
};

const addPartner = async (newPartner: {
  name: string;
  description: string;
}) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/partners/`,
      newPartner,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getAccounts = async (partnerId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/partners/${partnerId}/accounts`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getAccountById = async (accountId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/accounts/${accountId}`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const addAccount = async (
  partnerId: string | undefined,
  newAccount: NewAccountType,
) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/partners/${partnerId}/accounts`,
      newAccount,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getAllSolutionsByAccountId = async (
  accountId: string,
  queryParams?: { pageSize: number; currentPage: number; sortEntity: string },
) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/accounts/${accountId}/solutions`,
      queryParams
        ? {
            params: {
              pageSize: queryParams.pageSize,
              pageNumber: queryParams.currentPage,
              sortEntity: queryParams.sortEntity,
            },
          }
        : {},
    );
    return res.data;
  } catch (e) {
    console.error('Error calling solutions: ', e);
  }
};

const addSolution = async (
  accountId: string | undefined,
  newSolution: NewSolutionType,
) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/accounts/${accountId}/solutions/`,
      newSolution,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getSolutionById = async (solutionId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/solutions/${solutionId}`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const addMember = async ({
  solutionId,
  reqBody,
}: {
  solutionId: string;
  reqBody: AddMemberFormData;
}) => {
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${origin}/partners/api/v1/solutions/${solutionId}/members`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: reqBody,
  };
  try {
    const res = await axiosInstance(config);
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const addSubLob = async (lobId: string, reqBody: AddSubLobFormData) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/lob/${lobId}/lob_application`,
      reqBody,
      { headers: { 'x-request-id': crypto.randomUUID() } },
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const sendEmailToNewMember = async ({
  adfsEntitlement,
  sendEmail,
  reqBody,
}: {
  adfsEntitlement: string;
  reqBody: {
    firstName: string;
    lastName: string;
    emailAddress: string;
  };
  sendEmail: boolean;
}) => {
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${origin}/partners/api/v1/member/create?role=${adfsEntitlement}&sendEmail=${sendEmail}`,
    data: reqBody,
  };

  try {
    const res = await axiosInstance(config);
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const sendWelcomeEmailToNewMember = async ({
  reqBody,
}: {
  reqBody: {
    firstName: string;
    lastName: string;
    emailAddress: string;
  };
}) => {
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${origin}/partners/api/v1/email/welcome-notification`,
    data: reqBody,
  };

  try {
    await axiosInstance(config);
    return;
  } catch (e) {
    console.error(e);
  }
};

const sendUpdateEmailToMember = async ({
  reqBody,
}: {
  reqBody: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    partner: string;
    account: string;
    solution?: string;
    environment: string;
    api: string;
  };
}) => {
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${origin}/partners/api/v1/email/update-notification`,
    data: reqBody,
  };

  try {
    await axiosInstance(config);
    return;
  } catch (e) {
    console.error(e);
  }
};

const sendTicketingUpdateEmail = async (reqBody: ITicketingUpdateEmail) => {
  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${origin}/partners/api/v1/email/ticketing-update-email`,
    data: reqBody,
  };

  try {
    await axiosInstance(config);
    return;
  } catch (e) {
    console.error(e);
  }
};

const submitContactUs = async (reqBody: ContactUsType) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/email/contact-us`,
      reqBody,
    );
    return res.status;
  } catch (e) {
    console.error(e);
  }
};

const removeMemberInAuthE = async ({
  currentEntitlement,
  reqBody,
}: {
  currentEntitlement: string;
  reqBody: {
    firstName: string;
    lastName: string;
    emailAddress: string;
  };
}) => {
  try {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${origin}/partners/api/v1/member/remove?role=${currentEntitlement}`,
      data: reqBody,
    };
    const res = await axiosInstance(config);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

const updateMemberInAuthEForEntitlement = async ({
  newEntitlement,
  reqBody,
}: {
  newEntitlement: string;
  reqBody: {
    firstName: string;
    lastName: string;
    emailAddress: string;
  };
}) => {
  try {
    const requestID = crypto.randomUUID();

    const config = {
      method: 'put',
      headers: {
        'x-request-id': requestID,
      },
      maxBodyLength: Infinity,
      url: `${origin}/partners/api/v1/member/update?role=${newEntitlement}`,
      data: reqBody,
    };
    const res = await axiosInstance(config);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

const updateMemberInAuthEForUserName = async ({
  reqBody,
}: {
  reqBody: {
    firstName: string;
    lastName: string;
    emailAddress: string;
  };
}) => {
  try {
    const config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${origin}/partners/api/v1/member/update`,
      data: reqBody,
    };
    const res = await axiosInstance(config);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteMemberBySolutionId = async ({
  solutionId,
  reqBody,
}: {
  solutionId: string;
  reqBody: {
    user_id: string;
    account_id: string;
    role_id: string;
    environments: string[];
  };
}) => {
  const config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: `${origin}/partners/api/v1/solutions/${solutionId}/members`,
    headers: {
      'X-Principal': 'W464125',
      //todo
      'X-Principal-Role': 'ChaseAdmin',
      //todo
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(reqBody),
  };
  try {
    const res = await axiosInstance(config);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

const useAddSolution = (accId: string | undefined) => {
  const { userProfile } = useContext(GlobalStoreContext);
  const { refetch } = useGetPersonaDash(userProfile.userPersona);
  return useMutation({
    mutationFn: (sol: NewSolutionType) => addSolution(accId, sol),
    onSettled: () => {
      refetch();
    },
  });
};

const getRoles = async () => {
  try {
    const res = await axiosInstance.get(`${origin}/partners/api/v1/roles`);
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getInternalWorkforceMember = async (searchValue: string) => {
  try {
    const requestID = crypto.randomUUID();
    const headers = {
      'x-request-id': requestID,
    };
    const params = {
      search: searchValue,
    };
    const config = {
      method: 'get',
      url: `${origin}/partners/api/v1/workers`,
      params,
      headers,
    };
    const res = await axios(config);
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getEnvironments = async (solId: string | undefined) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/solutions/${solId}/environments`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getAllEnvironments = async () => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/environments`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const createSupportGroupRule = async ({
  rule,
  group_id,
}: {
  rule: ICreateSupportGroupRule;
  group_id: string;
}) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const res = await axiosInstance.post(
    `${origin}/partners/api/v1/user-groups/${group_id}/group-rules`,
    rule,
    config,
  );
  return res.data;
};

const createSupportGroupMember = async (
  user_id: string,
  group_id: string,
  roleId: string,
) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/user-groups/${group_id}/members/${user_id}`,
      { role_id: roleId },
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const addEnvironmentToSolution = async (
  solutionId: string | undefined,
  environmentId: string | undefined,
  account_id: string | undefined,
) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/solutions/${solutionId}/environments`,
      {
        environment_id: environmentId,
        account_id: account_id,
      },
    );
    return res;
  } catch (e) {
    console.error(e);
  }
};

const getApiByApiId = async (apiId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/apis/${apiId}`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getSolutionEnvironment = async (solEnvId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/solution-environments/${solEnvId}`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getSolutionEnvironmentAPIs = async (
  solEnvId: string,
  queryParams?: { pageSize: number; currentPage: number; sortEntity: string },
) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/environments/${solEnvId}/apis`,
      queryParams
        ? {
            params: {
              pageSize: queryParams.pageSize,
              pageNumber: queryParams.currentPage,
              sortEntity: queryParams.sortEntity,
            },
          }
        : {},
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getAllAPIs = async () => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/api-metadata`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const addApi = async (solEnvId: string, apis: IApis[], account_id: string) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/environments/${solEnvId}/apis`,
      {
        apis,
        account_id,
      },
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getSolutionAuth = async (solutionId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/solutions/${solutionId}/role`,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const getActionReasons = async (actionType: ActionType) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/action_reasons/${actionType}/`,
    );
    return res.data
      .map(
        ({
          reason,
          action_reason_id,
        }: {
          reason: string;
          action_reason_id: string;
        }) => ({
          value: action_reason_id,
          label: reason,
        }),
      )
      .sort(({ label }: { label: string }) => {
        if (label.toLowerCase().includes('other')) {
          return 1;
        } else {
          return -1;
        }
      });
  } catch (e) {
    console.error(e);
  }
};

const addRemovalReason = async (reqBody: AuditRequest) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/audit/`,
      reqBody,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};
const getAPIsCatalog = async () => {
  const config = {
    method: 'get',
    url: `${origin}/api/v1/catalog/metadata/external-api/hydrate-catalog-index`,
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    console.log(error);
    return JSON.stringify(error);
  }
};

const getAPIsRouting = async () => {
  const config = {
    method: 'get',
    url: `${origin}/api/v1/catalog/metadata/external-api/product-routing`,
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    console.log(error);
    return JSON.stringify(error);
  }
};
const getAPIById = async (id: string) => {
  const config = {
    method: 'get',
    url: `${origin}/api/v1/catalog/metadata/${id}`,
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    console.log(error);
    return JSON.stringify(error);
  }
};

const getGroupings = async () => {
  const config = {
    method: 'get',
    url: `${origin}/api/v1/catalog/grouping`,
  };
  const res = await axios(config);
  return res.data;
};

const getSolutions = async () => {
  const config = {
    method: 'get',
    url: `${origin}/api/v1/catalog/solutions/lookup`,
  };
  const res = await axios(config);
  return res.data;
};

const createTicket = async (reqBody: ICreateTicketFormData) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/tickets`,
      reqBody,
      { headers: { 'x-request-id': crypto.randomUUID() } },
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const updateTicketState = async (
  reqBody: IUpdateTicketStateRequest,
  ticketId: string,
) => {
  try {
    const res = await axiosInstance.put(
      `${origin}/partners/api/v1/tickets/${ticketId}`,
      reqBody,
      { headers: { 'x-request-id': crypto.randomUUID() } },
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const addComment = async (reqBody: ITicketCommentRequest) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/tickets/comment`,
      reqBody,
      { headers: { 'x-request-id': crypto.randomUUID() } },
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const deleteAttachment = async (
  fileId: string,
  ticketCorrelationId: string,
) => {
  const requestID = crypto.randomUUID();
  const headers = {
    'x-request-id': requestID,
  };
  try {
    const res = await axiosInstance.request({
      url: `${origin}/partners/api/v1/tickets/${ticketCorrelationId}/attachments/${fileId}`,
      method: 'delete',
      headers,
    });
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const deleteTicketAttachment = async ({
  ticketId,
  fileId,
  reqBody,
}: {
  ticketId: string;
  fileId: string;
  reqBody: IDeleteTicketAttachmentRequest;
}) => {
  const requestID = crypto.randomUUID();
  const headers = {
    'x-request-id': requestID,
  };

  try {
    const res = await axiosInstance.request({
      url: `${origin}/partners/api/v1/tickets/${ticketId}/attachments/${fileId}`,
      method: 'delete',
      data: reqBody,
      headers,
    });
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const downloadAttachment = async (reqBody: IDownloadAttachmentRequest) => {
  try {
    const requestID = crypto.randomUUID();
    const url = `${origin}/partners/api/v1/storage/file`;

    const res = await axiosInstance.post(`${url}`, reqBody, {
      responseType: 'blob',
      headers: {
        'x-request-id': requestID,
      },
    });
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const createUserGroup = async (reqBody: ICreateUserGroupRequest) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/user-groups`,
      reqBody,
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const deleteMemberFromGroup = async ({
  actionReasonId,
  memberSID,
  assignmentGroupId,
}: {
  actionReasonId: string;
  memberSID: string;
  assignmentGroupId: string;
}) => {
  try {
    const res = await axiosInstance.request({
      url: `${origin}/partners/api/v1/user-groups/${assignmentGroupId}/members/${memberSID}`,
      method: 'delete',
      data: { action_reason_id: actionReasonId },
    });
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
const addNewChaseMember = async (addChaseMemberData: addNewChaseMemberType) => {
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/invite-members`,
      addChaseMemberData,
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const addNewBatchChaseMember = async (
  addChaseMemberData: addNewBatchChaseMemberType,
) => {
  try {
    const config = {
      headers: {
        'x-request-id': crypto.randomUUID(),
      },
    };

    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/batch-invite-members`,
      addChaseMemberData,
      config,
    );

    return res.data;
  } catch (e) {
    console.error(e);
  }
};

// This was used to test WebClient story end-to-end. This can be uncommented and leveraged in future story
// const deleteMemberFromGroup = async (groupId: string, userId: string) => {
//   try {
//     const res = await axiosInstance.delete(
//         `${origin}/partners/api/v1/user-groups/${groupId}/members/${userId}`,
//     );
//     return res.data;
//   } catch (e) {
//     console.error(e);
//     throw e;
//   }
// };

const deleteGroupRule = async ({
  assignmentGroupId,
  ruleId,
  actionReasonId,
}: {
  assignmentGroupId: string;
  ruleId: string;
  actionReasonId: string;
}) => {
  try {
    const reqBody = { action_reason_id: actionReasonId };
    const res = await axiosInstance.request({
      url: `${origin}/partners/api/v1/user-groups/${assignmentGroupId}/group-rules/${ruleId}`,
      method: 'delete',
      data: reqBody,
    });
    return res;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getFileUploadStatus = async (aFileId: string, xCorrelationId: string) => {
  try {
    const res = await axiosInstance.get(
      `${origin}/partners/api/v1/file-upload-transactions/${aFileId}`,
      {
        headers: {
          'x-request-id': crypto.randomUUID(),
          'x-ticket-correlation-id': xCorrelationId,
        },
      },
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const uploadFile = async ({
  aFile,
  xCorrelationId,
  setProgress,
  isExternal,
}: {
  aFile: File;
  xCorrelationId: string;
  setProgress: (progress: number) => void;
  isExternal: boolean;
}) => {
  const formData = new FormData();
  const originalFileName = aFile.name;
  const ticketCorrelationId = xCorrelationId;
  const requestId = crypto.randomUUID();
  formData.append('file', aFile, requestId);

  const url = `${origin}/partners/api/v1/tickets/${ticketCorrelationId}/attachments`;

  try {
    const result: {
      data: {
        ticket_correlation_id: string;
        file_id: string;
        status: string;
        file_name: string;
        file_size: number;
      };
    } = await axiosInstance.post(`${url}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-request-id': requestId,
        'x-original-file-name': originalFileName,
        'x-is-external': `${isExternal}`,
      },
      onUploadProgress: (progressEvent) =>
        setProgress(
          /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain*/
          Math.round((progressEvent.loaded * 100) / progressEvent?.total!),
        ),
    });
    return result;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const updateFileUploadTransactionStatus = async (
  aFileId: string,
  xCorrelationId: string,
  fileStatus: string,
  fileName: string,
  isExternalAttachment: boolean,
  ticketId?: string,
) => {
  try {
    const res = await axiosInstance.put(
      `${origin}/partners/api/v1/file-upload-transactions/${aFileId}`,
      {
        status: fileStatus,
        ticketId: ticketId,
        fileName: fileName,
        isExternalAttachment: isExternalAttachment,
      },
      {
        headers: {
          'x-request-id': crypto.randomUUID(),
          'x-ticket-correlation-id': xCorrelationId,
        },
      },
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const addCustomProfile = async (
  name: string,
  description: string,
  role: string,
) => {
  const requestID = crypto.randomUUID();
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/profile`,
      {
        name: name,
        description: description,
        profile_type: 'CUSTOM',
        parent_profile: role,
      },
      {
        headers: {
          'x-request-id': requestID,
        },
      },
    );
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const updateProfilePermissions = async (
  profileId: string,
  featureId: string,
  permissions_to_add: string[],
  permissions_to_remove: string[],
) => {
  const requestID = crypto.randomUUID();
  try {
    const res = await axiosInstance.post(
      `${origin}/partners/api/v1/profile/${profileId}/feature/${featureId}`,
      {
        permissions_to_add: permissions_to_add,
        permissions_to_remove: permissions_to_remove,
      },
      {
        headers: {
          'x-request-id': requestID,
        },
      },
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

export {
  getPartnersAndAccounts,
  getAccountsAndSolutions,
  getPartnerById,
  getUsersByPartnerId,
  getUsersByAccountId,
  getUsersBySolutionId,
  getUsersBySolutionEnvironmentId,
  getUsersBySolutionEnvironmentIdForApi,
  addPartner,
  getAccounts,
  getAccountById,
  addMember,
  sendEmailToNewMember,
  sendWelcomeEmailToNewMember,
  sendUpdateEmailToMember,
  getSolutionById,
  getAllSolutionsByAccountId,
  useAddSolution,
  getRoles,
  addSolution,
  getEnvironments,
  getApiByApiId,
  removeMemberInAuthE,
  updateMemberInAuthEForEntitlement,
  updateMemberInAuthEForUserName,
  deleteMemberBySolutionId,
  getAllEnvironments,
  addEnvironmentToSolution,
  getSolutionEnvironment,
  getSolutionEnvironmentAPIs,
  getAllAPIs,
  addApi,
  updatePartnerAdminLastLogin,
  updateUserByUserId,
  getSolutionAuth,
  updatePartnerStatusById,
  updateAccountStatusById,
  updateSolutionStatusById,
  updateApiStatusById,
  updateSolutionEnvironmentStatusById,
  getActionReasons,
  addRemovalReason,
  updateMemberBySolutionId,
  getSolutionInfoById,
  submitContactUs,
  getSolutions,
  getAPIsCatalog,
  getAPIsRouting,
  getAPIById,
  getGroupings,
  addAccount,
  createTicket,
  updateTicketState,
  addComment,
  sendTicketingUpdateEmail,
  deleteAttachment,
  downloadAttachment,
  createUserGroup,
  deleteMemberFromGroup,
  addNewChaseMember,
  addNewBatchChaseMember,
  deleteGroupRule,
  getInternalWorkforceMember,
  createSupportGroupRule,
  createSupportGroupMember,
  uploadFile,
  getFileUploadStatus,
  updateFileUploadTransactionStatus,
  addSubLob,
  addCustomProfile,
  updateProfilePermissions,
  deleteTicketAttachment,
};
