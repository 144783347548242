import { MdsIcon } from '@mds/react-es';
import { useNavigate } from 'react-router-dom';
import './breakout-link-stylesheet.scss';

const BreakoutLink = ({ path }: { path: string }) => {
  const navigate = useNavigate();

  return (
    <div className="supp-breakout-link">
      <MdsIcon
        id={`breakout-link-${path}`}
        size={16}
        color="default"
        type="ico_new_window"
        onClick={() => navigate(path)}
      />
    </div>
  );
};

export default BreakoutLink;
