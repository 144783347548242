import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { isEmail } from 'validator';

import './registration-stylesheet.scss';
import './recaptcha-stylesheet.scss';

import RegistrationSidebar from './components/RegistrationSidebar';
import MobileSvg from './components/MobileSVG';

import staticProperties from './_data/static_properties.json';
import endpoints from './_data/endpoints';
import recaptchaSitekey from './_data/recaptcha_sitekey';

import { useWindowSize } from '../../../utils';
import getEnvironment from '../../../utils/getEnvironment';

import {
  MdsCheckbox,
  MdsLink,
  MdsTextInput,
  MdsTextInputSecure,
} from '@mds/react-es';
import getRegistrationError from './_data/getRegistrationError';

declare global {
  interface Window {
    grecaptcha?: {
      enterprise: {
        ready: (callback: () => void) => void;
        execute: (
          siteKey: string,
          options: { action: string },
        ) => Promise<string>;
      };
    };
  }
}

const RegistrationPage = () => {
  const env = getEnvironment();
  const siteKey = recaptchaSitekey[env].siteKey;

  const autheLoginLink = endpoints[env].login;
  const termsOfUseLink = endpoints[env].termsOfUse;

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [emailAddressError, setEmailAddressError] = useState('');
  const [pswdError, setPswdError] = useState('');
  const [confirmPswdError, setConfirmPswdError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const isMobile = useWindowSize().width <= 1023;

  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://www.google.com/recaptcha/enterprise.js?render=' + siteKey;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, [siteKey]);

  async function handleCreateAccount(event: any) {
    event.preventDefault();
    const validForm = isFormValid(
      firstName,
      lastName,
      emailAddress,
      password,
      confirmPassword,
    );

    if (!validForm) {
      setButtonDisabled(true);
    } else {
      window.grecaptcha?.enterprise?.ready(() => {
        window.grecaptcha?.enterprise
          .execute(siteKey, { action: 'Registration' })
          .then(async (token: any) => {
            await axios
              .post(
                staticProperties.Authe_Create_Url,
                {
                  first_name: firstName,
                  last_name: lastName,
                  email_address: emailAddress,
                  password: password,
                },
                {
                  headers: {
                    'g-recaptcha-response': token,
                    'x-request-id': crypto.randomUUID(),
                  },
                },
              )
              .then((response) => {
                const res = getRegistrationError(response);

                // Success state
                if ([200, 201].includes(res.statusCode)) {
                  navigate(staticProperties.Email_Verification_Url, {
                    state: {
                      email: emailAddress,
                    },
                  });
                  return;
                }

                // Unknown error state
                if (res.statusCode === 500) {
                  navigate(staticProperties.Error_Page_Url);
                  return;
                }

                // Email error state
                if (res.statusCode === 400 && res.type === 'email') {
                  setEmailAddressError(res.message);
                  return;
                }

                // Password error state
                if (res.statusCode === 400 && res.type === 'password') {
                  setPswdError(res.message);
                  return;
                }
              })

              .catch((error) => {
                // errors that are not defined by AuthE
                navigate(staticProperties.Error_Page_Url);
              });
          });
      });
    }
  }

  const isFormValid = (
    firstName: string,
    lastName: string,
    emailAddress: string,
    password: string,
    confirmPassword: string,
  ) => {
    const isEmailValid = isEmail(emailAddress);
    let errorDetected = false;

    if (isEmpty(emailAddress)) {
      setEmailAddressError('Enter email address');
      errorDetected = true;
    }

    if (!isEmailValid && !isEmpty(emailAddress)) {
      setEmailAddressError('Email is not valid');
      errorDetected = true;
    }

    if (isEmpty(firstName)) {
      setFirstNameError('Enter first name');
      errorDetected = true;
    }

    if (isEmpty(lastName)) {
      setLastNameError('Enter last name');
      errorDetected = true;
    }

    if (isEmpty(password)) {
      setPswdError('Enter Password');
      errorDetected = true;
    }
    if (isEmpty(confirmPassword)) {
      setConfirmPswdError('Confirm password');
      errorDetected = true;
    }

    if (
      password !== confirmPassword &&
      !isEmpty(password) &&
      !isEmpty(confirmPassword)
    ) {
      setPswdError('Passwords do not match');
      setConfirmPswdError('Passwords do not match');
      errorDetected = true;
    }

    if (errorDetected) {
      return false;
    }

    return true;
  };

  const isEmpty = (value: string): boolean => {
    return value.trim().length === 0;
  };

  const handleFirstNameChange = (newFirstName: string) => {
    setFirstNameError('');
    setFirstName(newFirstName);
  };

  const handleLastNameChange = (newLastName: string) => {
    setLastNameError('');
    setLastName(newLastName);
  };

  const handleEmailAddressChange = (newEmail: string) => {
    setEmailAddressError('');
    setEmailAddress(newEmail);
  };

  const handlePasswordChange = (newPassword: string) => {
    setPswdError('');
    setConfirmPswdError('');
    setPassword(newPassword);
  };

  const handleConfirmPasswordChange = (newConfirmPassword: string) => {
    setPswdError('');
    setConfirmPswdError('');
    setConfirmPassword(newConfirmPassword);
  };

  const checkboxHandler = () => {
    setAcceptTerms(!acceptTerms);
    setButtonDisabled(acceptTerms);
  };

  return (
    <>
      <Helmet>
        <title>Create Account | Chase Developer</title>
        <meta
          name="description"
          content="Create a Chase Developer Account to get access to world class API solutions with easy-to-use documentation."
        />
      </Helmet>
      <div className="registration-container">
        {isMobile ? null : <RegistrationSidebar />}

        <div id="registration-form" className="registration-form">
          <form>
            <h1 data-testid="registration-h1" id="registration-h1">
              Create a Chase Developer account
            </h1>
            <div className="registration-input-side-by-side-left">
              <MdsTextInput
                id="firstname"
                label="First Name"
                data-testId="First Name"
                variant="box"
                onChange={(e) => handleFirstNameChange(e.target.value)}
                name="firstname"
                errorMessage={firstNameError}
              />
            </div>
            <div className="registration-input-side-by-side-right">
              <MdsTextInput
                id="lastname"
                label="Last Name"
                data-testId="Last Name"
                variant="box"
                onChange={(e) => handleLastNameChange(e.target.value)}
                name="lastname"
                errorMessage={lastNameError}
              />
            </div>
            <MdsTextInput
              id="emailAddress"
              label="Email Address"
              variant="box"
              name="emailAddress"
              data-testId="Email Address"
              onChange={(e) => handleEmailAddressChange(e.target.value)}
              errorMessage={emailAddressError}
            />

            <p id="please-use-company">
              Use your work email, if you{`'`}re part of a company
            </p>

            <MdsTextInputSecure
              id="registration-password"
              label="Password"
              data-testId="Password"
              variant="box"
              name="password"
              showButtonText="Show"
              hideButtonText="Hide"
              onChange={(e) => handlePasswordChange(e.target.value)}
              errorMessage={pswdError}
              capsLockMessage="Caps lock is on"
            />

            <MdsTextInputSecure
              id="registration-confirm-pswd"
              label="Confirm Password"
              data-testId="Confirm Password"
              variant="box"
              name="registration-confirm-pswd"
              showButtonText="Show"
              hideButtonText="Hide"
              onChange={(e) => handleConfirmPasswordChange(e.target.value)}
              errorMessage={confirmPswdError}
              capsLockMessage="Caps lock is on"
            />

            <div className="side-by-side-div">
              <div>
                <MdsCheckbox
                  data-testid="termsCheckbox"
                  accessibleText="Terms of Use Checkbox"
                  accessibleDescription="Terms of Use Checkbox"
                  onChange={checkboxHandler}
                  state={acceptTerms ? 'true' : 'false'}
                />
              </div>
              <div id="terms-agreement">
                <p>
                  I have read and agree to the Chase Developer{' '}
                  <MdsLink href={termsOfUseLink} text="Terms of Use" /> and{' '}
                  <MdsLink href="" text="Online Privacy Policy" />
                </p>
              </div>
            </div>

            <div className="create-account-button2">
              <button
                data-sitekey={recaptchaSitekey[env].siteKey}
                data-action="submit"
                data-testid="Submit Button"
                className="submitButton"
                disabled={buttonDisabled}
                onClick={(e) => handleCreateAccount(e)}
              >
                Create Account
              </button>
            </div>
          </form>
          <div className="signin-link">
            <div>
              Already have an account?{' '}
              <MdsLink
                data-testid="Login Link"
                href={autheLoginLink}
                text="Sign in"
              />
            </div>
          </div>
        </div>
        {isMobile ? <MobileSvg /> : null}
      </div>
    </>
  );
};

export default RegistrationPage;
