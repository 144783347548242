import chaseBackground from '../../_images/Left-side-bar.png';
import leftArrow from '../../_images/ico_chevron_left.png';
import chaseLogo from '../../_images/logo.png';
import getEnvironment from '../../../../../utils/getEnvironment';
import endpoints from '../../_data/endpoints';
import './registration-sidebar-stylesheet.scss';

export default function RegistrationSidebar() {
  const env = getEnvironment();
  const homePage = endpoints[env].homePage;

  return (
    <div className="sidebar-image-div" data-testid="sidebar-component">
      <img
        className="sidebar-image"
        src={chaseBackground}
        alt="People on their phone"
      />
      <div className="sidebar-text-content">
        <div className="button-div">
          <a id="back-button" data-testid="back-button" href={homePage}>
            <img src={leftArrow} alt="back-arrow" className="back-button-img" />
            Back
          </a>
        </div>
        <div className="sidebar-background-text">
          <img
            id="sidebar-img"
            src={chaseLogo}
            className="chase-logo"
            alt="Chase / developer"
          />
          <h2 data-testid="sidebar-h2" id="sidebar-h2">
            Power your business with Chase APIs
          </h2>
          <p data-testid="sidebar-p" id="sidebar-p">
            With our established expertise across a vast range of financial
            services, we{"'"}ll help you develop best-in-class digital banking
            solutions
          </p>
        </div>
      </div>
    </div>
  );
}
