import { useContext } from 'react';
import {
  GlobalStoreContext,
  UserPersonaEnum,
  useGetPartnerById,
  useGetAccountById,
  useGetSolutionById,
  useGetAllLobApplications,
  IFeature,
  ITicket,
  ILobApplication,
} from './';

const deriveUserPersona = ({ userPersona }: { userPersona: string }) => {
  switch (userPersona) {
    case 'ChaseAdmin':
      return UserPersonaEnum.ChaseAdmin;
    case 'ChaseSupportTeam':
      return UserPersonaEnum.ChaseSupportTeam;
    case 'ChaseSuperAdmin':
      return UserPersonaEnum.ChaseSuperAdmin;
    case 'RelationshipManager':
      return UserPersonaEnum.RelationshipManager;
    case 'PartnerDeveloper':
      return UserPersonaEnum.PartnerDeveloper;
    case 'PartnerAdmin':
      return UserPersonaEnum.PartnerAdmin;
    case 'ExternalDeveloper':
      return UserPersonaEnum.ExternalDeveloper;
    case 'PartnerRelationshipManager':
      return UserPersonaEnum.PartnerRelationshipManager;
    case 'AnonymousUser':
    default:
      return UserPersonaEnum.AnonymousUser;
  }
};

const isUserPartnerCentral = (userPersona: UserPersonaEnum) => {
  switch (+userPersona) {
    case UserPersonaEnum.ChaseSupportTeam:
    case UserPersonaEnum.ChaseAdmin:
    case UserPersonaEnum.ChaseSuperAdmin:
    case UserPersonaEnum.RelationshipManager:
    case UserPersonaEnum.PartnerDeveloper:
    case UserPersonaEnum.PartnerAdmin:
    case UserPersonaEnum.PartnerRelationshipManager:
      return true;
    case UserPersonaEnum.ExternalDeveloper:
    default:
      return false;
  }
};

const useGetIsPartnerPersona = () => {
  const { userProfile } = useContext(GlobalStoreContext);
  return userProfile.isExternalUser && userProfile.isPartnerCentralUser;
};

const useGetPartnerName = (ticket: ITicket | undefined) => {
  const { userProfile } = useContext(GlobalStoreContext);
  const { userPersona } = userProfile;
  const isChaseUser = userProfile.isChaseUser;
  const isPartnerAdmin = +userPersona === UserPersonaEnum.PartnerAdmin;

  const partnerNameByChaseUser = useGetPartnerById(
    (ticket?.partnerId as string) || undefined,
  )?.name;
  const partnerNameByPartnerAdmin = useGetAccountById(
    (ticket?.accountId as string) || undefined,
  )?.partnerName;
  const parterNameByDeveloper = useGetSolutionById(
    (ticket?.solutionId as string) || undefined,
  )?.partnerName;
  if (!ticket) return undefined;
  return isChaseUser
    ? partnerNameByChaseUser
    : isPartnerAdmin
      ? partnerNameByPartnerAdmin
      : parterNameByDeveloper;
};

const useGetAccountName = (ticket: ITicket) => {
  const { userProfile } = useContext(GlobalStoreContext);
  const { userPersona } = userProfile;
  const isChaseUser = userProfile.isChaseUser;
  const isPartnerAdmin = +userPersona === UserPersonaEnum.PartnerAdmin;

  const accountNameFromAccount = useGetAccountById(ticket.accountId)?.name;
  const accountNameFromSolution = useGetSolutionById(
    ticket.solutionId,
  )?.accountName;
  return isChaseUser || isPartnerAdmin
    ? accountNameFromAccount
    : accountNameFromSolution;
};

const getIsTicketingEnabled = () => {
  //eslint-disable-next-line
  const { lobApplicationsArray } = useGetAllLobApplications();

  const isTicketingEnabled = lobApplicationsArray.some((lobApplication) =>
    lobApplication?.features.some(
      (feature) =>
        feature.featureName === 'Ticketing' &&
        feature.featureStatus === 'ALLOW',
    ),
  );
  return { isTicketingEnabled };
};

const getIsTicketingEnabledById = (lobApplication: ILobApplication) => {
  if (!lobApplication) return { isTicketingEnabled: false };
  const isTicketingEnabled = lobApplication.features.some(
    (feature: IFeature) =>
      feature.featureName === 'Ticketing' && feature.featureStatus === 'ALLOW',
  );
  return { isTicketingEnabled };
};

const useGetUserhasAccess = (actionName: string) => {
  const { userAccess, userProfile } = useContext(GlobalStoreContext);
  const userPersona = userProfile.userPersona;
  if (userPersona !== UserPersonaEnum.ChaseSupportTeam) return true;
  const roleActions = userAccess.role.actions;
  const userActions = userAccess.actions;
  const roleHasPermission = roleActions?.find(
    (action) => action.actionName === actionName,
  );
  const userHasPermission = userActions?.find(
    (action) => action.actionName === actionName,
  );
  const hasPermission: boolean =
    (roleHasPermission ? roleHasPermission.permission === 'ALLOW' : false) ||
    (userHasPermission ? userHasPermission.permission === 'ALLOW' : false);
  return hasPermission;
};

export {
  deriveUserPersona,
  UserPersonaEnum,
  isUserPartnerCentral,
  useGetPartnerName,
  getIsTicketingEnabled,
  useGetAccountName,
  useGetUserhasAccess,
  getIsTicketingEnabledById,
  useGetIsPartnerPersona,
};
