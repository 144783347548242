import { useState, ReactNode } from 'react';
import { MdsIcon } from '@mds/react-es';
import Avatar from 'react-avatar';
import { EntityStatus, BreakoutLink, IResource } from './';
import './apix-custom-accordion-stylesheet.scss';

export const ApixCustomAccordion = (props: {
  name: string | ReactNode;
  id?: string;
  entityName?: 'partner' | 'account' | 'member' | 'env' | 'solution' | 'api';
  resources?: IResource[];
  breakoutLink?: string;
  status?: string;
  isPartnerWidgetHeading?: boolean;
  isSinglePartnerHeading?: boolean;
  isSolutionsAccordion?: boolean;
  className?: string;
  children?: ReactNode;
  hideResourceCount?: boolean;
  hideBreakoutLink?: boolean;
  showDropdownIcon?: boolean;
  customRightHandNode?: ReactNode;
  isDefaultOpen?: boolean;
}) => {
  const {
    name,
    id,
    entityName,
    className,
    resources,
    breakoutLink,
    status,
    isSinglePartnerHeading,
    isSolutionsAccordion,
    children,
    hideResourceCount,
    hideBreakoutLink,
    showDropdownIcon,
    customRightHandNode = <></>,
    isDefaultOpen = false,
  } = props;

  const [isRowOpen, setIsRowOpen] = useState(isDefaultOpen);
  const toggleRow = () => {
    setIsRowOpen(!isRowOpen);
  };

  return (
    <div
      id={id}
      className={`${isRowOpen ? 'row-container-open' : undefined} ${className ? className : undefined}`}
    >
      <div>
        <div
          className={`accordion-heading ${
            !isRowOpen && isSinglePartnerHeading
              ? 'closed-partner-remove-border'
              : ''
          } ${isSolutionsAccordion ? 'solutions-accordion-border' : undefined} ${isRowOpen ? 'open' : undefined}`}
          id={`accordion-dropdown-${id}`}
        >
          <button
            className="accordion-heading-left"
            onClick={toggleRow}
            value={id}
          >
            {(resources || showDropdownIcon) && (
              <MdsIcon
                id={`dropdown-icon-${id}`}
                type={isRowOpen ? 'ico_caret_down' : 'ico_caret_right'}
                color="interactive"
                size={16}
                accessibleText={`dropdown-button-${name}`}
              />
            )}
            <div
              className={`avatar-and-name ${
                !resources || resources[0].count < 1
                  ? 'no-accordion-offset'
                  : ''
              }`}
            >
              {typeof name === 'string' &&
                (isSinglePartnerHeading || entityName === 'account') && (
                  <Avatar
                    name={name}
                    size={'30'}
                    round
                    className="single-partner-avatar"
                    textSizeRatio={1.5}
                    maxInitials={2}
                  />
                )}
              <div
                className={`resource-name-and-count ${
                  resources && resources.length > 1
                    ? 'multiple-resource-count'
                    : undefined
                }`}
              >
                <div className="resource-name">{name}</div>
                {!hideResourceCount && resources && (
                  <div className="resource-count">
                    {isSinglePartnerHeading &&
                      `${resources[0].name} (${resources[0].count})`}
                    {resources.length > 1 &&
                      resources.map((resource) => {
                        return (
                          <span key={resource.name}>
                            {resource.name}: {resource.count}&nbsp;
                          </span>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </button>

          {isRowOpen && !hideBreakoutLink && breakoutLink && (
            <BreakoutLink path={breakoutLink} />
          )}
          {!isRowOpen && status && (
            <div className="chase-admin-partner-status">
              <EntityStatus
                entityName={entityName}
                status={status}
                isIconOnly
              />
            </div>
          )}
          {customRightHandNode}
        </div>
      </div>
      {isRowOpen && children}
    </div>
  );
};
