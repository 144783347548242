import './entity-status.scss';
import { MdsIcon, MdsLink } from '@mds/react-es';
import { titleize } from './';
import { useNavigate } from 'react-router-dom';

enum EntityTypes {
  partner,
  account,
  member,
  env,
  solution,
  api,
  profile,
  feature,
  cell,
}

const EntityStatus = ({
  entityName,
  status,
  linkTo,
  text,
  isIconOnly,
}: {
  entityName?: keyof typeof EntityTypes;
  status: string;
  linkTo?: string;
  text?: string;
  isIconOnly?: boolean;
}) => {
  enum MemberColors {
    Active = 'success',
    Invited = 'warning',
    Inactive = 'error',
  }

  enum StatusType {
    Active = 'ico_checkmark_filled',
    Invited = 'ico_alert_circle',
    Inactive = 'ico_alert_filled',
  }

  const navigate = useNavigate();

  const getColorName = (status: string) => {
    if (status === 'ACTIVE') {
      return MemberColors.Active;
    } else if (
      status === 'PENDING' ||
      (entityName === 'feature' && status === 'INACTIVE') // color for INACTIVE features is the same as PENDING entities
    ) {
      return MemberColors.Invited;
    } else if (status === 'INACTIVE') {
      return MemberColors.Inactive;
    }
  };

  const getTypeName = (status: string) => {
    if (status === 'ACTIVE') {
      return StatusType.Active;
    } else if (
      status === 'PENDING' ||
      (entityName === 'feature' && status === 'INACTIVE') // color for INACTIVE features is the same as PENDING entities
    ) {
      return StatusType.Invited;
    } else if (status === 'INACTIVE') {
      return StatusType.Inactive;
    }
  };

  return (
    <div
      className={`status-cell ${isIconOnly && 'icon-only'} ${entityName && `status-cell-${entityName.toLowerCase()}`}`}
    >
      <MdsIcon
        size={16}
        color={getColorName(status)}
        type={getTypeName(status)}
      />
      {!isIconOnly &&
        (linkTo ? (
          <MdsLink onClick={() => navigate(linkTo)} text={text} />
        ) : (
          <span>{titleize(status)}</span>
        ))}
    </div>
  );
};

export default EntityStatus;
