import confirmationImage from '../../_images/confirmationImage.svg';
import './partner-email-sent-stylesheet.scss';
import Header from '../RegistrationHeader';
import { Helmet } from 'react-helmet';

const PartnerEmailSent = () => {
  return (
    <>
      <Helmet>
        <title>Sign-in Link Sent</title>
      </Helmet>
      <Header />

      <div className={'email-sent-container'}>
        <h1 id={'email-sent-header'}>{'Email sent successfully'}</h1>

        <p id={'email-sent-information'}>
          A new link to sign in to Partner Central has been sent to the email
          you provided.
        </p>

        <img
          id={'confirmation-image'}
          src={confirmationImage}
          alt={'Confirmation'}
        />
      </div>
    </>
  );
};

export default PartnerEmailSent;
