import { MdsButton, MdsTextInput } from '@mds/react-es';
import { useContext, useState } from 'react';
import { GlobalStoreContext } from '../../../../Solutions';
import ManageMembersTableDesktop from './components/ManageLobMembersTableDesktop';
import './manage-lob-members-stylesheet.scss';
import AddLobMemberSlideIn from './components/AddLobMemberSlideIn';
import ManageLobMembersMobile from './components/ManageLobMembersMobile';
import { useGetLobMembers } from '../../../../../../../services/graphql-utils/useGraphQLMaps';

const ManageLobMembersTab = () => {
  const { isMobile } = useContext(GlobalStoreContext);
  const [isAddLobMemberSlideInOpen, setIsAddLobMemberSlideInOpen] =
    useState(false);
  const [searchText, setSearchText] = useState('');
  const { lobMembersArray } = useGetLobMembers();
  const isNoMembers = !lobMembersArray.length;
  const handleLobAddMemberSlideInOpen = (isOpen: boolean) =>
    setIsAddLobMemberSlideInOpen(isOpen);

  return (
    <div className="manage-lob-members-tab">
      <div className="top-section">
        {!isNoMembers && (
          <div className="search-group">
            {!isMobile && <strong>Search</strong>}
          </div>
        )}
        {
          <MdsButton
            text="Add member"
            onClick={() => handleLobAddMemberSlideInOpen(true)}
            iconType="ico_add_circle"
            widthType={isMobile ? 'layout' : 'content'}
            variant={isMobile ? 'secondary' : 'tertiary'}
          />
        }
      </div>
      <MdsTextInput
        label="You can search by Name or SID"
        fieldPrefix="ico_search"
        placeholder="Search"
        variant="box"
        onInput={(e) => setSearchText(e.target.value)}
      />
      {isMobile && (
        <ManageLobMembersMobile
          members={lobMembersArray}
          searchText={searchText}
        />
      )}
      {!isMobile && (
        <ManageMembersTableDesktop
          members={lobMembersArray}
          searchText={searchText}
        />
      )}
      {
        <AddLobMemberSlideIn
          isOpen={isAddLobMemberSlideInOpen}
          onLobAddMemberSlideInOpen={handleLobAddMemberSlideInOpen}
        />
      }
    </div>
  );
};

export default ManageLobMembersTab;
