import { MdsButton } from '@mds/react-es';
import alertSpot from '@mds/web-illustrations/dist/cmb/svgs/mds_ill_alert_featured.svg';
import './empty-table-message-stylesheet.scss';
const EmptyTableMessage = ({
  message,
  onClickButton,
  buttonText,
  hideButton = false,
}: {
  message: string;
  onClickButton: () => void;
  buttonText: string;
  hideButton?: boolean;
}) => {
  return (
    <div className="empty-table-message">
      <img src={alertSpot} alt="Alert" width={130} height={130} />
      <p>{message}</p>
      {!hideButton && (
        <MdsButton
          text={buttonText}
          onClick={onClickButton}
          iconType="ico_add_circle"
          iconPosition="leading"
        />
      )}
    </div>
  );
};
export default EmptyTableMessage;
