import { MdsIcon } from '@mds/react-es';
import { GridApi } from 'ag-grid-community';
import { KeyboardEvent, ReactNode, useEffect, useState } from 'react';
import './apix-ag-grid-pagination-stylesheet.scss';

const ApixAgGridPagination = ({ gridApi }: { gridApi: GridApi }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showGoToIndex, setShowGoToIndex] = useState(false);
  const [paginationButtons, setPaginationButtons] = useState<ReactNode[]>([]);
  const totalPages = gridApi.paginationGetTotalPages();
  const pageSize = gridApi.paginationGetPageSize();

  const handlePageSkip = (idx: number) => {
    const isError = idx > totalPages || idx < 1;
    const trueIndex = idx - 1;
    if (!isError) {
      setCurrentPage(trueIndex);
      gridApi.paginationGoToPage(trueIndex);
      setShowGoToIndex(false);
    }
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handlePageSkip(parseInt(event.currentTarget.value));
    }
    if (event.key === 'Tab') {
      setShowGoToIndex(false);
    }
  };
  const paginationButton = (idx: number) => {
    return (
      <button
        key={`go-to-${idx}`}
        id={`button-page-${idx}`}
        className={`pagination-item ${idx === currentPage ? 'selected' : 'unselected'}`}
        onClick={() => {
          gridApi.paginationGoToPage(idx);
          updatePagination(gridApi);
        }}
      >
        {(idx + 1).toString()}
      </button>
    );
  };

  const divider = () => {
    return (
      <div className="divider-wrapper">
        <svg
          width="1"
          height="24"
          viewBox="0 0 1 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="1" height="24" fill="#C1C3C3" />
        </svg>
      </div>
    );
  };

  const updatePagination = (api: GridApi) => {
    const tempTotalPages = api.paginationGetTotalPages();
    const tempCurrentPage = api.paginationGetCurrentPage();
    const tempPaginationButtons = [];
    /*
    pagination rules
    - if tempTotalPage <= 5, show all indices
    - start buttons at TempCurrentPage - 1 except when TempCurrentPage is 0 or 1
    - show last two indices always
    - add ellipsis if there are more than 5 pages
    */

    const showAll = tempTotalPages < 5;
    if (showAll) {
      for (let page = 0; page < tempTotalPages; page++) {
        tempPaginationButtons.push(paginationButton(page));
      }
    } else if (
      totalPages - 4 === currentPage ||
      totalPages - 3 === currentPage
    ) {
      for (let page = 5; page > 0; page--) {
        tempPaginationButtons.push(paginationButton(tempTotalPages - page));
      }
    } else {
      currentPage !== 0 && currentPage < tempTotalPages - 2
        ? tempPaginationButtons.push([
            paginationButton(currentPage - 1),
            paginationButton(currentPage),
          ])
        : tempPaginationButtons.push([
            paginationButton(0),
            paginationButton(1),
          ]);
      tempPaginationButtons.push(
        <div className="ellipsis-container">
          <button className="pagination-item">
            <MdsIcon
              type="ico_ellipsis"
              size={24}
              onClick={() => setShowGoToIndex(!showGoToIndex)}
            />
          </button>
        </div>,
      );
      tempPaginationButtons.push([
        paginationButton(tempTotalPages - 2),
        paginationButton(tempTotalPages - 1),
      ]);
    }

    setPaginationButtons(tempPaginationButtons);
    setCurrentPage(tempCurrentPage);
  };

  useEffect(() => {
    updatePagination(gridApi);
  }, [gridApi, currentPage, showGoToIndex, totalPages, pageSize]);

  return (
    <div className="pagination-container">
      <div className="pagination-buttons">
        <button
          className="pagination-item chevron"
          onClick={() => {
            setCurrentPage(currentPage - 1);
            gridApi?.paginationGoToPreviousPage();
          }}
        >
          <MdsIcon type="ico_chevron_left" size={16} />
        </button>
        {divider()}
        {paginationButtons}
        {divider()}
        <button
          className="pagination-item chevron"
          onClick={() => {
            setCurrentPage(currentPage - 1);
            gridApi?.paginationGoToNextPage();
          }}
        >
          <MdsIcon type="ico_chevron_right" size={16} />
        </button>
      </div>
      {showGoToIndex && (
        <div className="text-input-dropdown">
          <input
            className={`idx-input`}
            onKeyDown={(e) => onKeyPress(e)}
            min={1}
            placeholder={`${3}`}
            max={totalPages}
            type="number"
          />
        </div>
      )}
    </div>
  );
};

export default ApixAgGridPagination;
