import { gql } from '@apollo/client';

export const ticketQuery = gql`
  query {
    getAllTickets {
      ticketId
      groupId
      groupName
      ticketSeverity
      ticketState
      ticketType
      partnerTicketId
      solutionId
      ticketCorrelationId
      reporter {
        userId
        firstName
        lastName
      }
      contacts {
        firstName
        lastName
        emailAddress
      }
      lobApplicationId
      partnerId
      accountId
      solutionId
      solutionEnvironmentId
      assignedTo {
        userId
        firstName
        lastName
      }
      title
      description
      dateOfFirstIncident
      jiraUrl
      snowId
      customersImpacted
      createdAt
      lastUpdated
      contacts {
        firstName
        lastName
        emailAddress
      }
      history {
        ticketId
        ticketHistoryId
        newValue
        previousValue
        performedBy
        updateReason
        action
        historyType
        createdAt
      }

      comments {
        ticketId
        ticketCommentId
        comment
        user
        isInternal
        createdAt
      }
      attachments {
        fileId
        fileName
        fileSize
        lastUpdated
        status
        ticketCorrelationId
        ticketId
        fileSize
        isExternal
      }
    }
  }
`;
