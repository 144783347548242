import { ReactNode } from 'react';
import './description-pair.scss';

/* 
  This is a rewrite of MdsDescriptionList
  MdsDescriptionList is a bit clunky in use and doesn't allow us to:
    - Edit styles
    - Pass custom classes (for example, to adjust width to take up entire grid)
    - Easily pass in custom components for values (ex: term="Status" value={<EntityStatus/>)

 */
const DescriptionPair = ({
  term,
  value,
  boldedTerm = false,
  boldedValue = false,
  className,
  itemLayout = 'vertical',
}: {
  term: string;
  value: ReactNode | string;
  boldedTerm?: boolean;
  boldedValue?: boolean;
  itemLayout?: 'horizontal' | 'vertical';
  className?: string;
}) => (
  <div
    className={['description-pair', itemLayout, className && className]
      .join(' ')
      .trim()}
  >
    <div className="term">
      {boldedTerm ? <strong>{term}</strong> : <>{term}</>}
    </div>
    <div className="value">
      {boldedValue ? <strong>{value}</strong> : <>{value}</>}
    </div>
  </div>
);

export default DescriptionPair;
