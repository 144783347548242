import BackButton from '../../../../global-components/BackButton/BackButton';
import { MdsAccordionItem } from '@mds/react-es';
import { useContext, useState } from 'react';
import { GlobalStoreContext } from '../../../../../utils/hooks/GlobalStoreProvider';
import { convertToTicketActivityDateFormat } from '../../../../../utils/time/dateConversion';
import './single-partner-page-stylesheet.scss';
import { useGetAllLobApplications } from '../../../../../services/graphql-utils/useGraphQLMaps';
import { useParams } from 'react-router-dom';
import { ILobApplication } from '../../../Dashboard/scenes/SuperAdminLandingScreen';

function findFirstPartner(
  lobApplicationsArray: ILobApplication[],
  value: string,
) {
  for (const lobApplication of lobApplicationsArray) {
    const found = lobApplication.partners.find(
      (partner) => partner['partnerId'] === value,
    );
    if (found) {
      return found;
    }
    return null;
  }
}

const SinglePartnerPage = () => {
  const { isMobile } = useContext(GlobalStoreContext);
  const [isSuccessBannerShown, setIsSuccessBannerShown] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { partnerId } = useParams();
  const { lobApplicationsArray } = useGetAllLobApplications();

  const partner = partnerId
    ? findFirstPartner(lobApplicationsArray, partnerId)
    : null;

  const handleSuccessBanner = (isOpen: boolean) => {
    setIsSuccessBannerShown(isOpen);
  };

  return (
    <div id="single-partner-page">
      <BackButton />
      <div className="top-section">
        <div id="title">
          <h1>{partner?.name}</h1>
          {/* TODO: Implement edit functionality */}
          {/* <button className="mds-styled-button">
            <MdsIcon type="ico_pencil" size={24} />
          </button> */}
        </div>

        {/* TODO: Implement switch functionality */}
        {/* <MdsSwitch
          stateLabelOn="Deactivate partner"
          stateLabelOff="Activate partner"
        /> */}
      </div>
      <div className="accordion-container">
        <MdsAccordionItem
          id="overview-accordion"
          label="Overview"
          variant="borderless"
          size="18px"
          expanded
        >
          <div id="partner-overview" className="overviews">
            <div id="overviews-description">
              <span className="bolded">Description</span>
              <span className="description">{partner?.description}</span>
              {/* TODO: Implement edit functionality */}
              {/* <button className="mds-styled-button">
                <MdsIcon type="ico_pencil" size={16} />
              </button> */}
            </div>
            <div id="overviews-grid">
              {/* TODO: Implement added by functionality when backend implements */}
              {/* <div className="overview-group">
                <span className="bolded">Added by</span>
                <span></span>
              </div> */}
              <div className="overview-group">
                <span className="bolded">Added on</span>
                <span>
                  {partner
                    ? convertToTicketActivityDateFormat(partner.createdAt)
                    : null}
                </span>
              </div>
              <div className="overview-group">
                <span className="bolded">Updated on</span>
                <span>
                  {partner
                    ? convertToTicketActivityDateFormat(partner.lastUpdated)
                    : null}
                </span>
              </div>
            </div>
            {/* TODO: Implement partner acronym when backend implements in API */}
            {/* <div className="overview-group">
              <span className="bolded">Partner acronym</span>
              <span>{partner?.shortName}</span>
            </div> */}
          </div>
        </MdsAccordionItem>
      </div>
      {/* TODO: Implement audit history tab */}
      {/* <div className="accordion-container">
        {isMobile ? (
          <>
            <span className="details">Details</span>
            <MdsSelect
              selectedIndex={selectedTabIndex}
              onValidate={(e) => setSelectedTabIndex(e.target.selectedIndex)}
              variant="box"
            >
              <MdsSelectOption label="Audit history" value="audit_history" />
            </MdsSelect>
            {selectedTabIndex === 0 && <PartnerAuditHistoryTab />}
          </>
        ) : (
          <MdsTabBar
            selectedTab={selectedTabIndex}
            tabs={[{ text: 'Audit history' }]}
            onClickTab={(e) => setSelectedTabIndex(e.target.selectedIndex)}
          >
            <div slot="panel-0">
              <PartnerAuditHistoryTab />
            </div>
          </MdsTabBar>
        )}
      </div> */}
    </div>
  );
};

export default SinglePartnerPage;
