import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  ApixAgGridPagination,
  CustomHeader,
  IMember,
} from '../../../../../../AdminPage/AdminPageTabs/ManageLOBAssignmentTab/DesktopAndMobileViews';
import '../../../../../../../../../styles/apix-ag-grid-stylesheet.scss';
import '../../../../../../../../../styles/apix-table-stylesheet.scss';
import { MdsIcon, MdsTag } from '@mds/react-es';
import { parseDate } from '../../../../../../../../global-components/Forms/CreateTicketForm';
import './manage-lob-members-table-stylesheet.scss';
//import EditMemberSlideIn from '../EditLobMemberSlideIn';
import { toLocaleTimeStringWithSeconds } from '../../../../../../../../../utils/time/dateConversion';

const defaultColumnDefs: ColDef = {
  sortable: true,
  autoHeight: true,
  wrapText: true,
  flex: 2,
};

export const ArrayToTagGroupCellRenderer = ({ value }: { value: string[] }) => (
  <div
    className="group-of-pills"
    style={{
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      gap: '10px',
    }}
  >
    {value.map((singleItem) => (
      <MdsTag key={singleItem} text={singleItem} />
    ))}
  </div>
);

export const EntityStatusCellRenderer = ({ value }: { value: string }) => {
  const style = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
  };
  const { text, icon, color } =
    value === 'INACTIVE'
      ? { text: 'Active', icon: 'ico_checkmark_filled', color: 'success' }
      : { text: 'Deactivated', icon: 'ico_alert_filled', color: 'default' };
  return (
    <div className="member-status" style={style}>
      <MdsIcon
        size={16}
        color={(color as 'default') || 'success'}
        type={icon}
        removeHorizontalMargin
      />
      <span>{text}</span>
    </div>
  );
};

export const RolesCountAndLinkCellRenderer = (params: any) => {
  // TODO: any_typing
  // Not sure where this is supposed to link
  // I suppose it will link to a roles page but specific to this user
  const roles = params.data.roles;

  if (!roles?.length) return <span>NA</span>;
  return `${roles.length.toString()}`;
};

const InteractionColumnHeader = () => (
  <MdsIcon size={24} color="interactive" type="ico_ellipsis" />
);

export const InteractionCellRenderer = ({ value }: { value: string }) => {
  const [isEditMemberSlideOpen, setIsEditMemberSlideOpen] = useState(false);
  const handleEditMemberSlideOpen = (isOpen: boolean) => {
    setIsEditMemberSlideOpen(isOpen);
  };

  return (
    <>
      {/* <MdsButton
        iconPosition="icon_only"
        variant="tertiary"
        iconType="ico_settings"
        widthType="content"
        onClick={() => handleEditMemberSlideOpen(true)}
      />
      <EditMemberSlideIn
        key={'desktop-edit'}
        member={value}
        isOpen={isEditMemberSlideOpen}
        onOpen={handleEditMemberSlideOpen}
      /> */}
    </>
  );
};

const columnDefs: ColDef[] = [
  {
    headerName: 'Member SID',
    field: 'userId',
    headerComponentParams: { enableSorting: true },
    minWidth: 125,
  },
  {
    headerName: 'Member name',
    field: 'firstName',
    headerComponentParams: { enableSorting: true },
    cellRenderer: (params: any) =>
      `${params.data.firstName} ${params.data.lastName} `,
    minWidth: 145,
  },
  {
    headerName: 'Date added',
    field: 'createdAt',
    headerComponentParams: { enableSorting: true },
    valueFormatter: ({ value }) => parseDate(value),
    minWidth: 120,
  },
  {
    headerName: 'LOB',
    field: 'lobs',
    headerComponentParams: { enableSorting: true },
    cellRenderer: ArrayToTagGroupCellRenderer,
    minWidth: 70,
  },
  {
    headerName: 'Sub-lob',
    field: 'lobApplications',
    headerComponentParams: { enableSorting: true },
    cellRenderer: ArrayToTagGroupCellRenderer,
    minWidth: 100,
  },
  //TODO
  // {
  //   headerName: 'Last logged in as',
  //   field: 'lastLoggedInAs',
  //   headerComponentParams: { enableSorting: true },
  //   minWidth: 150,
  //   valueFormatter: ({ value }) => 'TBD',
  // },
  {
    headerName: 'Roles',
    field: 'roles',
    headerComponentParams: { enableSorting: true },
    cellRenderer: RolesCountAndLinkCellRenderer,
    minWidth: 80,
  },
  //TODO
  // {
  //   headerName: 'Status',
  //   field: 'status',
  //   headerComponentParams: { enableSorting: true },
  //   cellRenderer: EntityStatusCellRenderer,
  //   minWidth: 130,
  // },
  {
    headerName: 'Last login',
    field: 'lastLogin',
    headerComponentParams: { enableSorting: true },
    valueFormatter: ({ value }) =>
      value
        ? `${parseDate(value)} ${toLocaleTimeStringWithSeconds(value)}`
        : 'N/A',
    minWidth: 110,
  },
  // {
  //   headerName: 'Interaction',
  //   field: 'userId',
  //   headerComponent: InteractionColumnHeader,
  //   cellRenderer: InteractionCellRenderer,
  //   minWidth: 60,
  // },
];

const ManageMembersTableDesktop = ({
  members,
  searchText,
}: {
  members: IMember[];
  searchText: string;
}) => {
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const totalPages = gridApi?.paginationGetTotalPages() || 1;
  const tableRef = useRef<any>();

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  }, []);
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const evaluateGridPageSize = () => {
    if (!tableRef.current) return;
    const idealRowNum = Math.floor(
      tableRef.current.getBoundingClientRect().height / 100,
    );
    gridApi?.setGridOption('paginationPageSize', idealRowNum || 5);
  };

  useLayoutEffect(() => {
    evaluateGridPageSize();
  }, [gridApi, evaluateGridPageSize]);

  return (
    <div
      // id="manage-profiles-table"
      id="manage-lob-members-table"
      className="apix-ag-grid ag-theme-balham"
      ref={tableRef}
    >
      <AgGridReact
        containerStyle={{ width: '100%', height: '100%' }}
        columnDefs={columnDefs}
        defaultColDef={defaultColumnDefs}
        rowData={members}
        components={components}
        onGridReady={onGridReady}
        quickFilterText={searchText}
        pagination
        suppressPaginationPanel
      />
      {gridApi && totalPages > 1 && <ApixAgGridPagination gridApi={gridApi} />}
    </div>
  );
};

export default ManageMembersTableDesktop;
