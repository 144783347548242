import { gql } from '@apollo/client';
import {
  ROLE_FRAGMENT,
  ENVIRONMENT_FRAGMENT,
  GROUP_FRAGMENT,
  GROUP_RULE_FRAGMENT,
  MEMBER_FRAGMENT,
  PARTNER_FRAGMENT,
  ACCOUNT_FRAGMENT,
  SOLUTION_FRAGMENT,
  SOLUTION_ENVIRONMENT_FRAGMENT,
  API_FRAGMENT,
  FEATURE_FRAGMENT,
  FEATURE_ACTION_FRAGMENT,
} from '../graphql-fragments/fragments';

export const chaseAdminQuery = gql`
  query {
    dashboard {
      lobApplicationId
      lobName
      applicationName
      features {
        ...featureFragment
      }
      ticketTypes
      lobMembers {
        ...memberFragment
        emailAddress
        lastUpdated
        userType
      }
      roles {
        ...roleFragment
      }
      environments {
        ...environmentFragment
      }
      groups {
        ...groupFragment
        groupRules {
          ...groupRuleFragment
        }
        members {
          ...memberFragment
          createdBy {
            userId
            firstName
            lastName
          }
        }
      }
      partners {
        ...partnerFragment
        features {
          ...featureFragment
          actions {
            ...featureActionFragment
          }
        }
        accounts {
          ...accountFragment
          solutions {
            ...solutionFragment
            members {
              ...memberFragment
              emailAddress
              status
              userType
              lastLogin
              lastUpdated
              environments
              dateAdded
            }
            solutionEnvironments {
              ...solutionEnvironmentFragment
              apis {
                ...apiFragment
              }
            }
          }
        }
      }
    }
  }

  ${ROLE_FRAGMENT}
  ${ENVIRONMENT_FRAGMENT}
  ${GROUP_FRAGMENT}
  ${GROUP_RULE_FRAGMENT}
  ${MEMBER_FRAGMENT}
  ${FEATURE_FRAGMENT}
  ${FEATURE_ACTION_FRAGMENT}
  ${PARTNER_FRAGMENT}
  ${ACCOUNT_FRAGMENT}
  ${SOLUTION_FRAGMENT}
  ${SOLUTION_ENVIRONMENT_FRAGMENT}
  ${API_FRAGMENT}
`;
