import { MdsIcon } from '@mds/react-es';
import './inline-edit-buttons-styles.scss';

const InlineEditButtons = ({
  isEditing,
  onCancel,
  onClickEditButton,
  onConfirm,
}: {
  isEditing: boolean;
  onCancel: () => void;
  onClickEditButton: () => void;
  onConfirm: () => void;
}) => {
  return (
    <>
      {isEditing && (
        <div className="inline-edit-buttons">
          <button
            id="select-button"
            className="icon-button"
            onClick={onConfirm}
          >
            <MdsIcon
              type="ico_checkmark"
              color="success"
              size={16}
              removeHorizontalMargin
            />
          </button>
          <button id="close-button" className="icon-button" onClick={onCancel}>
            <MdsIcon
              type="ico_close"
              color="error"
              size={16}
              removeHorizontalMargin
            />
          </button>
        </div>
      )}
      {!isEditing && (
        <button
          className="begin-edit-button mds-styled-button"
          onClick={onClickEditButton}
        >
          <MdsIcon size={16} type="ico_pencil" removeHorizontalMargin />
        </button>
      )}
    </>
  );
};

export default InlineEditButtons;
