import RegistrationSidebar from '../RegistrationSidebar';
import emailLogo from '../../_images/email-sent.png';
import { useWindowSize } from '../../../../../utils';
import MobileSvg from '../MobileSVG';
import { useLocation } from 'react-router-dom';
import './email-verification-stylesheet.scss';

const EmailVerification = () => {
  const { state } = useLocation();
  const email = state.email;
  const isMobile = useWindowSize().width <= 1023;
  return (
    <main data-testid="email-verification-div" id="email-verification-div">
      <div className="email-verification-container">
        {isMobile ? null : <RegistrationSidebar />}
        <div className="email-verification-form">
          <div id="email-verification-content">
            <img
              className="mail-img"
              src={emailLogo}
              alt="Mail"
              data-testid="email-verification-img"
            />
            <h1 data-testid="email-verification-h1" id="email-verification-h1">
              Verification link sent
            </h1>
            <div
              className="email-verification-success-msg"
              data-testid="email-verification-success-msg"
            >
              <strong id="email-verification-strong">
                You&apos;ve successfully signed up for Chase Developer
              </strong>
            </div>
            <div
              className="email-verification-txt"
              data-testid="email-verification-txt"
            >
              We&apos;ve sent a link to {email} to confirm your email address.
              Please check your inbox and spam folder. It could take up to 10
              minutes to show up in your inbox. Once it arrives, the
              confirmation link will be valid for 15 minutes.
            </div>
          </div>
        </div>
        {isMobile ? <MobileSvg /> : null}
      </div>
    </main>
  );
};

export default EmailVerification;
