import axios from 'axios';
import { linkToDevPortal } from './text/linkTo';
import cookies from 'js-cookie';
const LOCAL_AUTH_DOMAIN = 'http://localhost:8080'; // this configuration is good if apix-auth-service is running locally.
const BASE_URL =
  window.location.hostname === 'localhost' ? LOCAL_AUTH_DOMAIN : '';
const USER_PROFILE_KEY = 'userProfile';
const USER_PROFILE_URL = `${BASE_URL}/user`;
const USER_TOKEN_URL = `${BASE_URL}/api/v1/identity-token`;

const defaultProfile = {
  firstName: 'Joe',
  lastName: 'Schmo',
  isLoggedIn: true,
  isChaseAdmin: true,
};

const fetchUserProfile = async () => {
  try {
    const timeoutCookie = cookies.get('ADFS_SESSION_INACTIVITY_TIMEOUT');
    if (timeoutCookie) {
      const response = await axios.get(USER_PROFILE_URL, {
        data: {
          credentials: 'include',
          cache: 'no-store',
        },
      });
      return response.data;
    } else {
      return localStorage.getItem(USER_PROFILE_KEY);
    }
  } catch (error: unknown) {
    console.error('Error while login', error);
    if (axios.isAxiosError(error)) {
      switch (error.response?.status) {
        case 400:
          window.location.replace(linkToDevPortal('/400'));
          return defaultProfile;
        case 401:
          window.location.replace(linkToDevPortal('/401-timeout'));
          return defaultProfile;
        case 404:
          window.location.replace(linkToDevPortal('/404'));
          break;

        default:
          console.error(error);
          // TODO: add redirect to 403 once we resolve those issues
          return {
            isLoggedIn: false,
          };
      }
    } else {
      // commenting out until issue is resolved
      // if (process.env.NODE_ENV == 'production') {
      // window.location.replace('/error/access-denied');
      // }
      console.error(error);
      return {
        isLoggedIn: false,
      };
    }
  }
};

const isUserLoggedIn = () => {
  const userProfile = JSON.parse(
    localStorage.getItem(USER_PROFILE_KEY) || '{}',
  );
  return userProfile ? userProfile.isLoggedIn : false;
};

const generatePlaygroundToken = async () => {
  return await axios.get(USER_TOKEN_URL, {
    data: {
      credentials: 'include',
      cache: 'no-store',
    },
  });
};

const getUserProfile = async () => {
  const userProfile = await fetchUserProfile();
  const {
    isLoggedIn,
    firstName = 'Returning',
    lastName = 'user',
  } = userProfile;

  if (isLoggedIn && (!firstName || !lastName)) {
    Object.assign(userProfile, { firstName: 'Returning', lastName: 'user' });
  }
  window.localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(userProfile));
  return userProfile;
};

export { isUserLoggedIn, getUserProfile, generatePlaygroundToken };
