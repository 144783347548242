import React, { useEffect, useState } from 'react';
import './custom-header-stylesheet.scss';

const CustomHeader = (props: any) => {
  const [ascSort, setAscSort] = useState('inactive');
  const [descSort, setDescSort] = useState('inactive');
  const [noSort, setNoSort] = useState('inactive');

  const onSortChanged = () => {
    setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');
    setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');
    setNoSort(
      !props.column.isSortAscending() && !props.column.isSortDescending()
        ? 'active'
        : 'inactive',
    );
  };

  const onSortRequested = (
    order: string,
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.TouchEvent<HTMLButtonElement>,
  ) => {
    props.setSort(order, event.shiftKey);
  };

  const pickNextSort = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.TouchEvent<HTMLButtonElement>,
  ) => {
    if (noSort === 'active') {
      onSortRequested('asc', event);
      return;
    }
    if (ascSort === 'active') {
      onSortRequested('desc', event);
      return;
    }
    if (descSort === 'active') {
      onSortRequested('', event);
      return;
    }
  };

  useEffect(() => {
    props.column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();
  }, []);

  let sort = null;
  if (props.enableSorting) {
    sort = (
      <div>
        <button
          onClick={(event) => pickNextSort(event)}
          onTouchEnd={(event) => pickNextSort(event)}
          className={`sort-label ${ascSort}`}
          style={{ cursor: 'pointer' }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.3315 6.19925C10.4176 6.28879 10.5322 6.33371 10.6471 6.33371C10.7562 6.33371 10.8656 6.29316 10.9504 6.2115C11.1246 6.04437 11.1301 5.76729 10.9627 5.59316L7.31773 1.80121C7.23519 1.71516 7.12115 1.66675 7.00215 1.66675C6.88344 1.66675 6.7694 1.71516 6.68686 1.80091L3.04015 5.59258C2.87273 5.767 2.87798 6.04379 3.05211 6.21121C3.22623 6.37892 3.50302 6.37337 3.67073 6.19925L7.00215 2.73541L10.3315 6.19925ZM6.68686 13.1995C6.7694 13.2853 6.88315 13.3337 7.00215 13.3337C7.12144 13.3337 7.23519 13.2853 7.31773 13.1992L10.9627 9.40729C11.1301 9.23316 11.1246 8.95608 10.9504 8.78896C10.7763 8.62154 10.4995 8.62679 10.3318 8.80121L7.00215 12.265L3.67073 8.80121C3.50302 8.62708 3.22623 8.62154 3.05211 8.78925C2.87798 8.95666 2.87273 9.23346 3.04015 9.40787L6.68686 13.1995Z"
              fill="#565A5D"
            />
          </svg>
        </button>
      </div>
    );
  }

  return (
    <div
      className="custom-header-cell"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          color: '#101820',
          fontSize: '14px',
          lineHeight: '20px' /* 142.857% */,
        }}
        className="label"
      >
        {props.displayName}
      </div>
      {sort}
    </div>
  );
};

export default CustomHeader;
