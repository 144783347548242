import staticProperties from './static_properties.json';

type RegistrationErrorResponse = {
  statusCode: number;
  message: string;
  type?: 'email' | 'password';
};

// handles all errors returned by AuthE
function isJSONString(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

const getRegistrationError = (response: any): RegistrationErrorResponse => {
  // Get the status code from the response
  const status = Number(response.data.message.split(':')[0]);
  const errorMsg = response.data.message.split(':').slice(1).join(':');

  const errorData =
    errorMsg && isJSONString(errorMsg) ? JSON.parse(errorMsg) : null;

  // Handle success state
  if ([200, 201].includes(status)) {
    return { statusCode: status, message: 'Success' };
  }

  // Handle error state messages returned from AuthE
  if (status === 400) {
    if (
      errorData &&
      errorData['urn:jpmorganchase:scim:extension:nwapi:Errors']?.length > 0
    ) {
      // if multiple errors are returned, we'll only display one
      const firstError =
        errorData['urn:jpmorganchase:scim:extension:nwapi:Errors'][0];

      // Email and Password errors
      if (firstError.attributePath.includes('email')) {
        return {
          statusCode: status,
          message:
            firstError.errorCode === 'INVALID_EMAIL_DOMAIN'
              ? staticProperties.Invalid_Domain_Error_Message
              : firstError.detail,
          type: 'email',
        };
      }

      if (firstError.attributePath.includes('password')) {
        switch (firstError.errorCode) {
          case 'PASSWORD_CONTAINS_NAME':
            return {
              statusCode: status,
              message: staticProperties.PASSWORD_CONTAINS_NAME_DETAIL,
              type: 'password',
            };
          case 'PASSWORD_NOT_ALLOWED_CHARS':
          case 'PASSWORD_TOTAL_LENGTH_OUT_OF_RANGE':
          case 'PASSWORD_NUM_OF_RULES_MATCHED_BELOW_LIMIT':
            return {
              statusCode: status,
              message: staticProperties.PWORD_POLICY,
              type: 'password',
            };
          case 'PASSWORD_DUP_SUBSTRING':
            return {
              statusCode: status,
              message: staticProperties.PASSWORD_DUP_SUBSTRING_Error_Message,
              type: 'password',
            };
          default:
            return {
              statusCode: status,
              message: firstError.detail,
              type: 'password',
            };
        }
      }
    } else {
      /*
                This block is a workaround for the inconsistent error messages returned by AuthE
                -> specifically non-json returns
    
                Currently  AuthE returns a JSON response or single string for some error messages
                Because of inconsistencies across environments, we are handling these errors in a way that is not ideal,
                with lots of fallback options, and checks for different error messages.
                Ex: in dev, email OTP triggered is a string, in prod it is a JSON response
                Another issue: 
                In dev, chase emails create a bad request -- in prod, they are a defined JSON response
            */
      if (
        response.data?.message?.includes(staticProperties.INVALID_EMAIL_DOMAIN)
      ) {
        return {
          statusCode: status,
          message: staticProperties.Invalid_Domain_Error_Message,
          type: 'email',
        };
      }

      if (
        response.data?.message?.includes(staticProperties.Email_Otp_Triggered)
      ) {
        return {
          statusCode: status,
          message: staticProperties.Account_Active_Error_Message,
          type: 'email',
        };
      }

      if (
        response.data?.message?.includes('already exists') ||
        response.data?.message?.includes('active')
      ) {
        return {
          statusCode: status,
          message: staticProperties.Account_Active_Error_Message,
          type: 'email',
        };
      }
    }
  }

  // unforeseen error state
  return { statusCode: 500, message: '' };
};

export default getRegistrationError;
