import { useContext } from 'react';
import { GlobalStoreContext, convertDateToDashboardFormat } from './';
import './welcome-message-stylesheet.scss';

const WelcomeMessage = () => {
  const { userProfile } = useContext(GlobalStoreContext);

  return (
    <div id="welcome">
      <span id="welcome-date">{convertDateToDashboardFormat()}</span>
      <span id="welcome-name">
        Welcome {userProfile.firstName} {userProfile.lastName}
      </span>
    </div>
  );
};

export default WelcomeMessage;
