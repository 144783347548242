import {
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import './manage-profiles-data-table-stylesheet.scss';
import '../../../../../../../styles/apix-ag-grid-stylesheet.scss';
import '../../../../../../../styles/apix-table-stylesheet.scss';
import {
  ApixAgGridPagination,
  convertToGroupDateFormat,
  CustomHeader,
} from '../../../../AdminPage/AdminPageTabs/ManageLOBAssignmentTab/DesktopAndMobileViews';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
// import EntityStatus from '../../../../../../global-components/EntityStatus/EntityStatus';
import { MdsLink } from '@mds/react-es';
import { useNavigate } from 'react-router-dom';
import { IProfile } from '../../../../../../../services/graphql-utils/dashtype';
import { useGetProfileById } from '../../../../../../../services/graphql-utils/useGraphQLMaps';

const defaultColumnDefs: ColDef = {
  sortable: true,
  autoHeight: true,
  wrapText: true,
  flex: 2,
};

const ProfileNameCellRenderer = (params: ICellRendererParams<IProfile>) => {
  const navigate = useNavigate();
  return (
    <MdsLink
      text={params.data?.name}
      endIcon="ico_chevron_right"
      onClick={() => navigate(`/partners/profiles/${params.data?.profileId}/`)}
    />
  );
};

const ProfileRoleCellRenderer = (props: ICellRendererParams<IProfile>) => {
  const roleId = props.data?.parentProfileId;
  const role = useGetProfileById(roleId);
  if (!role) return <>N/A</>;

  return <>{role.name}</>;
};

// const EntityStatusCellRenderer = ({ value }: { value: string }) => (
//   // TODO: fix this once status is part of GraphQL
//   // <EntityStatus status={value} entityName="profile" />
// );

const columnDefs: ColDef[] = [
  {
    headerName: 'Profile',
    field: 'name',
    headerComponentParams: { enableSorting: true },
    cellRenderer: ProfileNameCellRenderer,
  },
  {
    headerName: 'Role',
    field: 'parentProfileId',
    headerComponentParams: { enableSorting: true },
    cellRenderer: ProfileRoleCellRenderer,
  },
  {
    headerName: 'Date added',
    field: 'createdAt',

    headerComponentParams: { enableSorting: true },
    valueFormatter: ({ value }) => convertToGroupDateFormat(value),
  },
  {
    headerName: 'Modified date',
    field: 'lastUpdated',
    headerComponentParams: { enableSorting: true },
    valueFormatter: ({ value }) => convertToGroupDateFormat(value),
  },
  // TODO: fix this once added by GraphQL is ready
  // {
  //   headerName: 'Added by',
  //   field: 'addedBy',
  //   headerComponentParams: { enableSorting: true },
  // },
  // TODO: fix this once status is part of GraphQL
  // {
  //   headerName: 'Status',
  //   field: 'status',
  //   headerComponentParams: { enableSorting: true },
  //   cellRenderer: EntityStatusCellRenderer,
  // },
];

const ManageProfilesDataTable = ({
  profiles,
  searchText,
}: {
  profiles: IProfile[];
  searchText: string;
}) => {
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const tableRef = useRef<any>();

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  }, []);
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };
  const evaluateGridPageSize = () => {
    if (!tableRef.current) return;
    const idealRowNum = Math.floor(
      tableRef.current.getBoundingClientRect().height / 100,
    );
    gridApi?.setGridOption('paginationPageSize', idealRowNum || 5);
  };

  useLayoutEffect(() => {
    evaluateGridPageSize();
  }, [gridApi, evaluateGridPageSize]);

  return (
    <div
      id="manage-profiles-table"
      className="apix-ag-grid ag-theme-balham"
      ref={tableRef}
    >
      <AgGridReact
        containerStyle={{ width: '100%', height: '100%' }}
        columnDefs={columnDefs}
        defaultColDef={defaultColumnDefs}
        rowData={profiles}
        components={components}
        onGridReady={onGridReady}
        quickFilterText={searchText}
        pagination
        paginationPageSize={5}
        suppressPaginationPanel
      />
      {gridApi && gridApi.paginationGetTotalPages() > 1 && (
        <ApixAgGridPagination gridApi={gridApi} />
      )}
    </div>
  );
};
export default ManageProfilesDataTable;
